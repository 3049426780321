<template>
  <v-app>
    <v-container>
      <v-layout row wrap>
        <!--<v-flex lg12 md12 sm12 xs12>
          <center class="area_mapeadaAuditorio">
            <img 
            :src="evento.auditorio_mapeado.imagen"
            usemap="#map">
          </center>
        </v-flex>-->
        <v-flex lg12 md12 sm12 xs12>
          <div class="sub" id:="boletas">
            <BoletasxLocalidad :pagos="true" :fisico="true" v-if="idLocalidad!=''" :idlocalidad="idLocalidad" :idEvento="evento.id" :monedaEvento="monedaSelectShopEvento" :indexMonedaSelectShopEvento="indexMonedaSelectShopEvento" :simboloMoneda="simboloMoneda" :monedasEvento="evento.monedas_evento"></BoletasxLocalidad>
          </div>
        </v-flex>
        <v-flex lg12 md12 sm12 xs12>
          <div class="mb-3 mt-3">
            <h2>Creacion de cortesias</h2>
          </div>
          <div>
            <span><router-link style="color: #1976d2 !important;" :to="{path: '/mis-eventos'}" title="Ver todos mis eventos"><u>Seleccionar otro evento</u></router-link></span><span> > </span><span>Creacion de cortesias</span>
          </div>
          <div class="mt-3 mb-4">
            <h2 class="bg-white p-3">Evento <b v-text="evento.nombre"></b></h2>
          </div>
        </v-flex>
      </v-layout> 
      <v-layout row wrap>
        <v-flex mb-5 lg8 md8 sm6 xs12>
          <v-data-table
            :headers="headers"
            :items="listLocalidadesEventoAuditorio_mapeado"
            hide-default-header
            hide-default-footer
            :itemsPerPage="listLocalidadesEventoAuditorio_mapeado.length"
            items-per-page=""
            item-key="id"
            class="elevation-1"
          >
            <template v-slot:items="props">
              <tr style="cursor: pointer;" title="Ver puestos de la localidad">
                <td @click="getPuestos(props.item.id,null)" class="font-weight-bold"><h5>{{ props.item.tribuna.nombre }}</h5></td>
                <td  @click="getPuestos(props.item.id,null)" class="font-weight-bold"><h5>{{ props.item.nombre }}</h5></td>
                <td  @click="getPuestos(props.item.id,null)" class="font-weight-bold"><h5>{{ props.item.aforo }}</h5></td>
                <td  @click="getPuestos(props.item.id,null)" class="font-weight-bold"><h5>{{ props.item.localidad_evento[0].impuesto }} %</h5></td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
        <v-flex mb-5 lg4 md4 sm6 xs12 p-5> 
          <v-flex align-self-end>
            <v-chip @click="mapasientos(true)">
              <v-icon left color="#907b16">search</v-icon>
              <b>VER MAPA DE PUESTOS</b>
            </v-chip>  
            <br>
          </v-flex>
          <v-select v-if="showSelect"
            v-model="monedaSelectShop"
            item-value="codigo_moneda"
            :item-text="concatTextMoneda"
            :items="evento.monedas_evento"
            label="Cambiar moneda de compra"
            prepend-icon="payment"
            @change="changeMonedaShopEvento"
            success
            persistent-hint
          ></v-select>
          <!-- <h3>Boletas: {{ countBoletas }}</h3> <h3>Palcos: {{ countPalcos }}</h3> -->
          <v-btn @click="verResumenCarrito()" style="color: white; background-color: blue;"><v-icon>shopping_cart</v-icon>&nbsp; VER RESUMEN CARRITO</v-btn>
          <br><br>
          <v-chip @click="clearCantBoleta">
            <v-icon center color="#907b16">remove_shopping_cart</v-icon>
            <b>&nbsp;VACIAR CARRITO</b>
          </v-chip>
        </v-flex>
      </v-layout>
      <div>
        <v-divider></v-divider>
        <h4 class="mb-3 text-secondary"><v-icon center>theaters</v-icon> Entrega de boletas</h4>
        <v-layout row wrap>
        <v-flex lg12 md12 xm12 xs12 mb-3>
            <v-select v-if="showSelect"
                :items="evento.tipo_entrega_boletas"
                name="entregaBoletas"
                label="Seleccione el medio de entrega de las boletas"
                v-model="entregaBoletas"
                item-text="text"
                prepend-icon="theaters"
                required
              ></v-select>
        </v-flex>
        </v-layout>
        <!-- <v-btn color="success" @click="generarCortesia"><v-icon>card_giftcard</v-icon> GENERAR CORTESIA</v-btn> -->
        <v-divider></v-divider>
      </div>
      <v-dialog v-model="dialogmapasientos" v-if="activeModalAsientos">
        <v-card>
          <v-card-title class="headline">
            <v-alert
              :value="true"
              color="success"
            >
              Selecciona tu localidad
            </v-alert>
          </v-card-title>
          <v-card-text>
            <center class="area_mapeadaAuditorio2">
              <img 
              :src="evento.auditorio_mapeado.imagen"
              usemap="#map">
            </center>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              flat="flat"
              @click="mapasientos(false)"
            >
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="$store.state.showModalFinalizarCompra" max-width="700">
        <div class="container-modal-resumen" v-if="$store.state.resumenFinalizarCompra">
          <h2 class="text-center mb-5">Resumen de compra</h2>
          <table width="100%" border="0" cellspacing="0" cellpadding="0" class="table">
            <tr  :key="index" v-for="(product, index) in $store.state.resumenFinalizarCompra.productos">
              <td style="text-align:left; font-weight: bold;">&nbsp;{{product.nombre}}</td>
              <td  style="text-align:right; font-weight: bold;">&nbsp;{{product.total}}</td>
            </tr>
            <tr  >
              <td style="text-align:left; font-weight: bold;">&nbsp; </td>
              <td  style="text-align:right; font-weight: bold;">&nbsp; </td>
            </tr>
            <tr>
              <td style="text-align:center; font-weight: bold; background:#F3F3F3;">&nbsp; TOTAL:</td>
              <td  style="text-align:right; font-weight: bold; background:#F3F3F3;">&nbsp; {{$store.state.resumenFinalizarCompra.totalVenta   }}</td>
            </tr>

          </table>
          
          <!-- <div class="text-center mb-5 pb-5">
            <h5 class="text-center mb-5">ELIGE UN MÉTODO PARA REALIZAR TU PAGO</h5>
            

            <v-select
                    v-model="selectedmetodo" 
                    :items="metodospago"
                    label="Cambiar método de pago"
                    prepend-icon="payment"  
                    success
                    persistent-hint
                  ></v-select>
                  <p>Has seleccionado: {{ selectedmetodo }}</p>
          </div> -->
          <div class="text-center mb-5 pb-5">
            <v-btn color="success" @click="generarCortesia"><v-icon>card_giftcard</v-icon> GENERAR CORTESIA</v-btn>
          </div>
          <!-- <div class="d-flex flex-wrap">
            <img src="https://static.placetopay.com/placetopay-logo.svg" class="col-12 col-md-6 p-0 mb-4 mb-md-0" alt="Logo placetopay" style="max-height:150px;">
            <img src="@/assets/images/mediosPago.jpeg" class="col-12 col-md-6 p-0" alt="medios de pago placetopay">
          </div> -->
        </div>
      </v-dialog>
    </v-container>
  </v-app>
</template>
<script>
import BoletasxLocalidad from "./utils/BoletasxLocalidad.vue";
import moment from "moment";

moment.locale("es");

export default {
  components: {
    BoletasxLocalidad,
  },
  data() {
    return {
      idLocalidad: "",
      monedaSelectShop: null,
      dialogmapasientos: false,
      showSelect: false,
      activeModalAsientos: false,
      entregaBoletas: '',
    };
  },
  watch: {
    dialogmapasientos() {
      setTimeout(() => {
        var self = this;
        $(".area_mapeadaAuditorio2").append(
          $("<textarea />")
            .html(this.evento.auditorio_mapeado.area_mapeada_admin)
            .text()
            .replaceAll("&nbsp;", "")
        );
        $(".maparea").click(function (e) {
          e.preventDefault();
          var texthref = e.currentTarget.href;
          var arrayhref = texthref.split("/");
          self.getPuestos(arrayhref[arrayhref.length - 1], null);
          window.location.hash = "boletas";
          //$("html, body").animate({ scrollTop: $('#boletas').offset().top }, 1);
          self.dialogmapasientos = false;
          //$("html, body").animate({ scrollTop: 700 }, 1000);
        });
      }, 200);
    },
  },
  async mounted() {
    var self = this;
    setTimeout(() => {
      self.showSelect = true;
    }, 400);

    await self.$store.dispatch("evento/getEvento", this.$route.params.evento);

    if (this.monedaSelectShopEvento === null) {
      this.$store.commit(
        "evento/changeMonedaShopEvento",
        this.evento.monedas_evento[0].codigo_moneda
      );
      //console.log('cambio de moneda por defecto');
      this.monedaSelectShop = this.monedaSelectShopEvento;
    } else {
      this.monedaSelectShop = this.monedaSelectShopEvento;
    }

    await this.$store.dispatch("evento/changeMonedaShopEvento", {
      codigo_moneda: this.monedaSelectShopEvento,
      evento_id: this.$route.params.evento,
    });

    window.setInterval(() => {
      self.validateDateTimeSale();
    }, 1000);

    var self = this;
    setTimeout(() => {
      self.activeModalAsientos = true;
    }, 500);
    setTimeout(() => {
      $(".area_mapeadaAuditorio2").append(
        $("<textarea />")
          .html(
            self.$store.getters["evento/evento"].auditorio_mapeado.area_mapeada_admin
          )
          .text()
          .replace("&nbsp;", "")
      );
      $(".maparea").click(function (e) {
        e.preventDefault();
        var texthref = e.currentTarget.href;
        var arrayhref = texthref.split("/");
        self.getPuestos(arrayhref[arrayhref.length - 1], null);
        window.location.hash = "boletas";
        //$("html, body").animate({ scrollTop: $('#boletas').offset().top }, 1);
        self.dialogmapasientos = false;
        $("html, body").animate({ scrollTop: 0 }, 1000);
      });
    }, 900);
  },
  async beforeCreate() {
    document.title = "Crear cortesias para evento - CentralTickets";
    await this.$store.dispatch("evento/getEvento", this.$route.params.evento);

    this.$store.dispatch(
      "auditorio_mapeado/listLocalidadesEventoAuditorio_mapeado",
      this.$route.params.evento
    );

    this.$store.dispatch("moneda/listMonedas");

    //this.validateDateTimeSale();
  },

  computed: {
    usuario() {
      return this.$store.getters["user/dataUserLogged"];
    },
    evento() {
      return this.$store.getters["evento/evento"];
    },
    monedas() {
      return this.$store.getters["moneda/listMonedas"];
    },
    cantBoleta() {
      return this.$store.getters["evento/cantBoleta"];
    },
    cantBoletaTotal() {
      return this.$store.getters["evento/cantBoletaTotal"];
    },
    acumuladoBoleta() {
      return this.$store.getters["evento/acumuladoBoleta"];
    },
    listLocalidadesEventoAuditorio_mapeado() {
      return this.$store.getters[
        "auditorio_mapeado/listLocalidadesEventoAuditorio_mapeado"
      ];
    },
    monedaSelectShopEvento() {
      return this.$store.getters["evento/monedaSelectShop"];
    },
    indexMonedaSelectShopEvento() {
      if (this.evento.monedas_evento && this.evento.monedas_evento.length > 0) {
        var index = this.evento.monedas_evento.findIndex(
          (moneda) => moneda.codigo_moneda === this.monedaSelectShopEvento
        );
        if (index === 0) {
          return "";
        } else {
          return index + 1;
        }
      }
    },
    simboloMoneda() {
      if (
        this.indexMonedaSelectShopEvento === "" ||
        this.indexMonedaSelectShopEvento === 0
      ) {
        return this.evento.monedas_evento[0].moneda.simbolo;
      } else {
        return this.evento.monedas_evento[this.indexMonedaSelectShopEvento - 1]
          .moneda.simbolo;
      }
    },
    headers() {
      return [
        {
          text: "TRIBUNA",
          align: "left",
          sortable: true,
          value: "tribuna.nombre",
        },
        { text: "LOCALIDAD", value: "nombre" },
        { text: "AFORO ", value: "aforo" },
        { text: "IMPUESTO ", value: "impuesto" },
      ];
    },
    localidadSelectCarrito() {
      return this.$store.getters["carrito/localidadSelectCarrito"];
    },
    countBoletas() {
      return this.$store.getters["carrito/countBoletas"];
      /* var lasBoletas = [];
        var losPalcos = [];

        for (var i = 0; i < this.listLocalidadesEventoAuditorio_mapeado.length; i++) {
          if (this.cantBoleta[this.listLocalidadesEventoAuditorio_mapeado[i].id] != null) {
            if (this.listLocalidadesEventoAuditorio_mapeado[i].palco == 1) {
              losPalcos = losPalcos.concat(this.cantBoleta[this.listLocalidadesEventoAuditorio_mapeado[i].id]);
            } else {
              lasBoletas = lasBoletas.concat(this.cantBoleta[this.listLocalidadesEventoAuditorio_mapeado[i].id]);
            }
            this.cantBoleta[this.listLocalidadesEventoAuditorio_mapeado[i].id].length;
          }
        }

        return lasBoletas.length; */
    },
    countPalcos() {
      return this.$store.getters["carrito/countPalcos"];
      /* var lasBoletas = [];
        var losPalcos = [];

        for (var i = 0; i < this.listLocalidadesEventoAuditorio_mapeado.length; i++) {
          if (this.cantBoleta[this.listLocalidadesEventoAuditorio_mapeado[i].id] != null) {
            if (this.listLocalidadesEventoAuditorio_mapeado[i].palco == 1) {
              losPalcos = losPalcos.concat(this.cantBoleta[this.listLocalidadesEventoAuditorio_mapeado[i].id]);
            } else {
              lasBoletas = lasBoletas.concat(this.cantBoleta[this.listLocalidadesEventoAuditorio_mapeado[i].id]);
            }
            this.cantBoleta[this.listLocalidadesEventoAuditorio_mapeado[i].id].length;
          }
        }

        return losPalcos.length;*/
    },
    dateTimeSaleStart() {
      return this.$store.getters["carrito/dateTimeSaleStart"];
    },
    dateTimeSaleEnd() {
      return this.$store.getters["carrito/dateTimeSaleEnd"];
    },
    validateFechasInicioyFinEvento() {
      if (
        new Date(
          `${this.formatFecha(this.evento.fecha_inicio_venta_puntos)} ${
            this.evento.hora_inicio_venta_puntos
          }`
        ) <= new Date()
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    formatNumber(number) {
      return new Intl.NumberFormat("es-CO", {
        minimumFractionDigits: 0,
      }).format(number);
    },
    formatoMoneda(valor) {
      if (valor != null && valor != "") {
        return new Intl.NumberFormat("es-CO").format(valor);
      } else {
        return "";
      }
    },
    getnomtribuna(id) {
      var arrayTribunas = [];
      for (
        var i = 0;
        i < this.listLocalidadesEventoAuditorio_mapeado.length;
        i++
      ) {
        arrayTribunas.push(
          this.listLocalidadesEventoAuditorio_mapeado[i].tribuna
        );
      }
      let filter_tribuna = arrayTribunas.filter((tribuna) => tribuna.id == id);
      if (filter_tribuna[0] == "" || filter_tribuna[0] == null) {
        return "";
      } else {
        return filter_tribuna[0].nombre;
      }
    },
    getnomlocalidad(id) {
      let filter_localidad = this.listLocalidadesEventoAuditorio_mapeado.filter(
        (localidad) => localidad.id == id
      );
      if (filter_localidad[0] == "" || filter_localidad[0] == null) {
        return "";
      } else {
        return filter_localidad[0].nombre;
      }
    },
    getSimboloMoneda(codigo_moneda) {
      let filter_moneda = this.monedas.filter(
        (moneda) => moneda.codigo_moneda == codigo_moneda
      );
      if (filter_moneda[0] == "" || filter_moneda[0] == null) {
        return "";
      } else {
        return filter_moneda[0].simbolo;
      }
    },
    getPuestos(id, localidad) {
      this.idLocalidad = id;
      if (localidad === null) {
        var localidadFilter = null;
        if (
          this.listLocalidadesEventoAuditorio_mapeado &&
          this.listLocalidadesEventoAuditorio_mapeado.length > 0
        ) {
          localidadFilter = this.listLocalidadesEventoAuditorio_mapeado.filter(
            (item) => item.id == id
          );
          this.$store.dispatch(
            "carrito/localidadSelectCarrito",
            localidadFilter
          );
        }
      } else {
        this.$store.dispatch("carrito/localidadSelectCarrito", localidad);
      }
      $("html, body").animate({ scrollTop: 0 }, 1000);
    },
    async clearCantBoleta() {
      // if (this.countPalcos > 0 || this.countBoletas > 0) {
        var lasBoletas = [];
        var losPalcos = [];

        for (
          var i = 0;
          i < this.listLocalidadesEventoAuditorio_mapeado.length;
          i++
        ) {
          if (
            this.cantBoleta[
              this.listLocalidadesEventoAuditorio_mapeado[i].id
            ] != null
          ) {
            if (this.listLocalidadesEventoAuditorio_mapeado[i].palco == 1) {
              losPalcos = losPalcos.concat(
                this.cantBoleta[
                  this.listLocalidadesEventoAuditorio_mapeado[i].id
                ]
              );
            } else {
              lasBoletas = lasBoletas.concat(
                this.cantBoleta[
                  this.listLocalidadesEventoAuditorio_mapeado[i].id
                ]
              );
            }
            this.cantBoleta[this.listLocalidadesEventoAuditorio_mapeado[i].id]
              .length;
          }
        }

        await this.$store.dispatch("carrito/setNullReservas", {
          boletas: lasBoletas,
          palcos: losPalcos,
        });

        this.idLocalidad = "";
        this.$store.dispatch("evento/clearCantBoleta");
        this.$store.dispatch("carrito/setNullPreciosBoletasCarrito");
        this.$store.dispatch("carrito/dateTimeSaleStart", null);
        this.$store.dispatch("carrito/dateTimeSaleEnd", null);
        swal("Exito!", "El carrito se vacio correctamente.", "success");
      // } else {
      //   this.$store.dispatch("evento/clearCantBoleta");
      //   swal("Informacion", "El carrito esta vacio.", "info");
      // }
    },
    changeMonedaShopEvento() {
      this.$store.dispatch("evento/changeMonedaShopEvento", {
        codigo_moneda: this.monedaSelectShop,
        evento_id: this.$route.params.evento,
      });
      this.$router.go(this.$router.currentRoute);
    },
    concatTextMoneda(item) {
      return `(${item.moneda.simbolo}) ${item.moneda.descripcion}`;
    },
    async mapasientos(mostrar) {
      if (mostrar) {
        await this.$store.commit("evento/setEvento", this.evento);
      }
      this.dialogmapasientos = mostrar;
    },
    validateDateTimeSale() {
      let fecha = new Date();
      if (this.dateTimeSaleStart != null && this.dateTimeSaleEnd != null) {
        if (
          Date.parse(fecha) >= Date.parse(this.dateTimeSaleStart) &&
          Date.parse(fecha) <= Date.parse(this.dateTimeSaleEnd)
        ) {
        } else if (
          this.preciosBoletasCarrito &&
          this.preciosBoletasCarrito.length > 0 &&
          this.dateTimeSaleStart != null &&
          this.dateTimeSaleEnd != null
        ) {
          this.idLocalidad = "";
          this.$store.dispatch("carrito/dateTimeSaleStart", null);
          this.$store.dispatch("carrito/dateTimeSaleEnd", null);
          this.$store.dispatch("carrito/setNullPreciosBoletasCarrito");
          this.$store.dispatch("evento/clearCantBoleta");
          swal("Informacion", "El carrito esta vacio.", "info");
        } else {
          this.idLocalidad = "";
          this.$store.dispatch("carrito/dateTimeSaleStart", null);
          this.$store.dispatch("carrito/dateTimeSaleEnd", null);
          this.$store.dispatch("carrito/setNullPreciosBoletasCarrito");
          this.$store.dispatch("evento/clearCantBoleta");
          swal("Informacion", "El carrito esta vacio.", "info");
        }
      }
    },
    redirect() {
      this.$router.go(0);
    },
    verResumenCarrito() {
      var self = this;
      var boletas = [];
      var palcos = [];

      for (
        var i = 0;
        i < self.listLocalidadesEventoAuditorio_mapeado.length;
        i++
      ) {
        if (
          self.$store.state.carrito.cantBoleta[
            self.listLocalidadesEventoAuditorio_mapeado[i].id
          ] != null
        ) {
          if (self.listLocalidadesEventoAuditorio_mapeado[i].palco == 1) {
            palcos = palcos.concat(
              self.$store.state.carrito.cantBoleta[
                self.listLocalidadesEventoAuditorio_mapeado[i].id
              ]
            );
          } else {
            boletas = boletas.concat(
              self.$store.state.carrito.cantBoleta[
                self.listLocalidadesEventoAuditorio_mapeado[i].id
              ]
            );
          }
          self.$store.state.carrito.cantBoleta[self.listLocalidadesEventoAuditorio_mapeado[i].id]
            .length;
        }
      }

      //Eliminar Duplicidad ID
      const duplicidadPalcos = new Set(palcos);
      let resultDupliPalcos = [...duplicidadPalcos];

      const duplicidadBoletas = new Set(boletas);
      let resultDupliBoletas = [...duplicidadBoletas];

      this.$store.dispatch("detalle_Boletas_Finalizarcompra", {
        palcos: resultDupliPalcos.length > 0 ? resultDupliPalcos : '',
        boletas: resultDupliBoletas.length > 0 ? resultDupliBoletas : '',      
        cortesias: 1  
      }).then((response)=>{
        if(this.$store.state.errorDetalleCompra){                    
          swal("Parece que algo salio mal!", "Debe proporcionar boletas o palcos", "error");
          return '';
        }else{
          this.$store.state.showModalFinalizarCompra = true;
        }
      });

    },
    generarCortesia() {
      if(!this.entregaBoletas){
        swal({title: 'Seleccione el tipo de entrega de boletas', icon: 'warning', closeModal: true})
      }else {
        var self = this;
        swal({
          title: "¿Esta seguro de confirmar la cortesia?",
          text: "",
          icon: "warning",
          buttons: ["No, cancelar", "Confirmar"],
          closeModal: true,
        }).then((willDelete) => {
          if (willDelete) {
            var boletas = [];
            var palcos = [];

            for (
              var i = 0;
              i < self.listLocalidadesEventoAuditorio_mapeado.length;
              i++
            ) {
              if (
                self.$store.state.carrito.cantBoleta[
                  self.listLocalidadesEventoAuditorio_mapeado[i].id
                ] != null
              ) {
                if (self.listLocalidadesEventoAuditorio_mapeado[i].palco == 1) {
                  palcos = palcos.concat(
                    self.$store.state.carrito.cantBoleta[
                      self.listLocalidadesEventoAuditorio_mapeado[i].id
                    ]
                  );
                } else {
                  boletas = boletas.concat(
                    self.$store.state.carrito.cantBoleta[
                      self.listLocalidadesEventoAuditorio_mapeado[i].id
                    ]
                  );
                }
                self.$store.state.carrito.cantBoleta[self.listLocalidadesEventoAuditorio_mapeado[i].id]
                  .length;
              }
            }

            self.$store
              .dispatch("evento/generarCortesia", {
                moneda: self.monedaSelectShopEvento,
                boletas: boletas,
                palcos: palcos,
                id_evento: self.$route.params.evento,
                entrega_boletas: self.entregaBoletas,
              })
              .then(() => {
                self.idLocalidad = "";
                self.entregaBoletas = "";
              });
          }
        });
      }
    },
    showModalFactura() {
      this.$store.dispatch("carrito/showFactura", true);
    },
    formatFecha(fecha) {
      if (fecha != null && fecha != "") {
        return moment(fecha).format("YYYY-MM-DD");
      } else {
        return "";
      }
    },
    formatDatetimeString(datetime) {
      if (datetime != null && datetime != "") {
        return moment(datetime).format(
          "dddd Do [de] MMMM [del] YYYY [a las] h:m a"
        );
      } else {
        return "";
      }
    },
  },
};
</script>
<style>
</style>