import axios from 'axios';
import {configApi} from '../../api/configApi';
import {router} from '../../main.js';
import {headers} from '../../api/headers';
import {store} from '../index';

export default {
	namespaced: true,
	state: {
      accessToken: null,
       loading: false,
			 dataUserLogged:{
            	nombre: null,
            apellidos: null,
            	email: null,
              tipo_identificacion: null,
            	identificacion: null,
            	telefono: null,
            	direccion: null,
            	ciudad: null,
            	departamento: null,
            	id_rol: null
            },
        tipoIdentificaciones: [],
         compras: [],
         paginationCompras: {
          descending: true,
          page: 1,
          rowsPerPage: 0,
          sortBy: '',
          totalItems: 0,
          rowsPerPageItems: [1, 2, 4, 8, 16],
      },
      pagesCompras: 0,
      compra: null,
	},
	getters: {
		dataUserLogged: (state,getters,rootState) => {
			return state.dataUserLogged;
		},
    listTipoIdentificaciones: (state,getters,rootState) => {
        return state.tipoIdentificaciones;
    },
    compras: (state,getters,rootState) => {
      return state.compras;
    },
     compra: (state,getters,rootState) => {
      return state.compra;
    },
     paginationCompras(state,getters,rootState){
      return state.paginationCompras;
    },
    pagesCompras(state,getters,rootState){
      return state.pagesCompras;
    },
    totalItemCount(state,getters,rootState){
        return state.paginationCompras.totalItems;
    },
    loading(state,getters,rootState){
        return state.loading;
    },
	},
	mutations: {
    updateAccessToken: (state, token) => {
      state.accessToken = token
    },
		updateDataUserLogged: (state,dataUser) => {
	      state.dataUserLogged.nombre = dataUser.nombre;
        state.dataUserLogged.apellidos = dataUser.apellidos;
	      state.dataUserLogged.email = dataUser.email;
        state.dataUserLogged.tipo_identificacion = dataUser.tipo_identificacion;
	      state.dataUserLogged.identificacion = dataUser.identificacion;
	      state.dataUserLogged.telefono = dataUser.telefono;
	      state.dataUserLogged.direccion = dataUser.direccion;
	      state.dataUserLogged.ciudad = dataUser.ciudad;
	      state.dataUserLogged.departamento = dataUser.departamento;
	      state.dataUserLogged.id_rol = dataUser.id_rol;
	     },
       listTipoIdentificaciones: (state,tipoIdentificaciones) => {
          state.tipoIdentificaciones = tipoIdentificaciones;
       },
    compras: (state,compras) => {
      state.compras = compras.data;
      state.paginationCompras.totalItems = compras.total;
      state.paginationCompras.page = compras.current_page;
      state.paginationCompras.rowsPerPage = compras.per_page;
    },
    compra(state,data){
      state.compra = data;
    },
    pagesCompras(state){
         if (state.paginationCompras.rowsPerPage == null ||
              state.paginationCompras.totalItems == null) { 
              state.pagesCompras = 0;
          } 

        state.pagesCompras = Math.ceil(state.paginationCompras.totalItems / state.paginationCompras.rowsPerPage);
    },
	},
	actions: {

    detailsUser({state,getters,commit,rootState},token){
      /*var headersT = null;
        if(token != null){ 
          headersT = {
            "Accept": "application/json",
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + token
          };
        }else{
          headersT = headers;
        }*/
      return axios.post(`${configApi.apiUrl}detailsuser`,null,{headers: headers})
      .then(response => {
        //console.log(response.data);
        //return;
        // localStorage.setItem('nombreUserTicketClient',response.data.success.nombre);
        // localStorage.setItem('emailUserTicketClient',response.data.success.email);
        // localStorage.setItem('identificacionUserTicketClient',response.data.success.identificacion);
        // localStorage.setItem('telefonoUserTicketClient',response.data.success.telefono);
        // localStorage.setItem('direccionUserTicketClient',response.data.success.direccion);
        // localStorage.setItem('ciudadUserTicketClient',response.data.success.ciudad);
        // localStorage.setItem('departamentoUserTicketClient',response.data.success.departamento);
        // localStorage.setItem('rolUserTicketClient',response.data.success.id_rol);
        commit('updateDataUserLogged',response.data.success)
      })
      .catch(error => {
        console.error(error);
      });

    },
    // updateDataUserLogged({state,getters,commit,rootState}){
    //   let dataUser = {
    //     nombre: localStorage.getItem('nombreUserTicketClient'),
    //     email: localStorage.getItem('emailUserTicketClient'),
    //     identificacion: localStorage.getItem('identificacionUserTicketClient'),
    //     telefono: localStorage.getItem('telefonoUserTicketClient'),
    //     direccion: localStorage.getItem('direccionUserTicketClient'),
    //     ciudad: localStorage.getItem('ciudadUserTicketClient'),
    //     departamento: localStorage.getItem('departamentoUserTicketClient'),
    //     id_rol: localStorage.getItem('rolUserTicketClient'),
    //   };
    //   commit('updateDataUserLogged',dataUser);
    // },
    updateProfile({state,getters,commit,rootState},user){
      // nombre
      // identificacion
      // direccion
      // departamento
      // ciudad
      // telefono
      // tipo_identificacion
      store.commit('changeLoader', true);
      axios.put(`${configApi.apiUrl}updateprofile/${user.email}`,user,{headers: headers})
      .then(response => {
        swal("Exito!", "Se actualizo tu perfil correctamente!", "success");
        store.dispatch('user/detailsUser',null);
        store.commit('changeLoader', false);
      })
      .catch(error => {
        swal("Error!",  error.response.data.error, "error");
        console.error(error);
        store.commit('changeLoader', false);
      });
    },
    updatePassword({state,getters,commit,rootState},payload){
      // mypassword
      // password
      // c_password
      store.commit('changeLoader', true);
        axios.post(`${configApi.apiUrl}cambioclave`,payload,{headers: headers})
        .then(response => {
            swal("Exito!", "Se actualizo tu contraseña correctamente!", "success");
            store.commit('changeLoader', false);
        })
        .catch(error => {
            store.commit('changeLoader', false);
            swal("Error al actualizar tu contraseña!",
             "Verifica tu contraseña actual y que las contraseñas nuevas coincidan.",
              "error");
            //console.log(error);
        });
    },
    listTipoIdentificaciones({state,getters,commit,rootState}){
      axios.get(`${configApi.apiUrl}tipo_identificacion_all`,null,{headers: headers})
      .then(response => {
        //console.log(response.data);
        commit('listTipoIdentificaciones',response.data.data);
      })
      .catch(error => {
        swal("Error!", "Error al obtener los tipos de identificacion!", "error");
        console.error(error);
      });
    },
    historialCompras({state,getters,commit,rootState},payload){
      state.loading = true;
      //console.log(payload);
      store.commit('changeLoader', true);
      axios.get(`${configApi.apiUrl}historial_compras?page=${payload.page}&desde=${payload.desde}&hasta=${payload.hasta}`,null,{headers: headers})
      .then(response => {
        //console.log(response.data);
        commit('compras',response.data.data.compras);
        commit('pagesCompras');
        state.loading = false;
        store.commit('changeLoader', false);
      })
      .catch(error => {
        state.loading = false;
        store.commit('changeLoader', false);
        swal("Error!", "Error al obtener mis compras!", "error");
        console.error(error);
      });

    },
    searchCompra({state,getters,commit,rootState},id){
      //console.log(payload);
      store.commit('changeLoader', true);
      axios.post(`${configApi.apiUrl}search_factura`,{id},{headers: headers})
      .then(response => {
         commit('compra',response.data);
         store.commit('changeLoader', false);
      })
      .catch(error => {
        var htmlErrors = ``;
        var obj = error.response.data.errors;
        for (const prop in obj) {
          htmlErrors += `${obj[prop][0]}\n`;
        }
        swal("Error!",htmlErrors, "error");
        store.commit('changeLoader', false);
        //console.log(error.response.data.errors);
      });

    },
	}
}