<template>
  <v-app>
    <v-container>
      <h1 class="mb-3 mt-2">EVENTOS POR APROBAR</h1>
      <v-layout row wrap>
        <v-flex lg3 md3 sm3 xs6 class="pr-4">
          <v-autocomplete
            solo
            :items="itemsFiltroEventos"
            label="Filtrar por"
          ></v-autocomplete>
        </v-flex>
        <v-flex lg3 md3 sm3 xs6>
          <v-autocomplete
            solo
            :items="itemsOrdenarEventos"
            label="Ordenar por"
          ></v-autocomplete>
        </v-flex>
      </v-layout>
      <!-- <div class="cont-evento mb-3 bg-white" title="Ver detalle de evento" @click="detalleEvento(1)">
				<v-layout row wrap>
					<v-flex lg4 md4 sm12 xs12>
						<div class="cont-img">
							<div class="estado-evento bg-estado-venta">
								En Venta
							</div>
						</div>
					</v-flex>
					<v-flex lg8 md8 sm12 xs12>
						<div class="cont-desc-evento p-3">
							<v-layout row wrap>
								<v-flex lg6 md6 sm12 xs12 class="text-left">
									<h3 class="mb-3">Esto es vida de silvestre dangon - Armenia 2018</h3>
									<p class="mb-0">Fecha: 19/10/2018</p>
									<p class="mb-0">Apertura de puertas: 6pm</p>
									<p class="mb-0">Horario inicio: 8pm</p>
									<p class="mb-0">Horario de salida: 3am</p>
									<p class="mb-0">Edad minima: 18 años</p>
									<p class="mb-0">Ciudad: Armenia</p>
									<p class="mb-0">Lugar: Coliseo del cafe</p>
								</v-flex>
								<v-flex lg6 md6 sm12 xs12 class="text-center mt-3">
									<v-layout row wrap>
										<v-flex lg4 md4 sm4 xs4>
											<h1>25</h1>
											<small>DIAS</small>
										</v-flex>
										<v-flex lg4 md4 sm4 xs4>
											<h1>20</h1>
											<small>HORAS</small>
										</v-flex>
										<v-flex lg4 md4 sm4 xs4>
											<h1>40</h1>
											<small>MIN</small>
										</v-flex>
									</v-layout>
									<v-layout row wrap>
										<v-flex>
									<v-chip color="primary" text-color="white" label disabled class="p-2 mt-3"><h2>45.635</h2>&nbsp;<small> Boletas vendidas</small></v-chip>
									</v-flex>
									<v-flex>
									<v-chip color="warning" text-color="white" label disabled class="p-2 mt-3"><h2>5</h2>&nbsp;<small> Notificaciones nuevas</small></v-chip>
								</v-flex>
								</v-layout>
								</v-flex>
							</v-layout>
						</div>
					</v-flex>
				</v-layout>
			</div> -->
      <div
        v-for="evento in eventos"
        class="cont-evento mb-3 bg-white"
        title="Ver detalle de evento"
        @click="detalleEvento(evento.id)"
      >
        <v-layout row wrap>
          <v-flex lg4 md4 sm4 xs12>
            <div
              v-for="imagen in evento.imagens"
              v-if="imagen.nombre.includes('banner')"
              class="cont-img"
              :style="'background: url(' + imagen.url + ');'"
            >
              <v-btn v-if="evento.status == 0" color="info">
                solicitud inactiva
              </v-btn>
              <v-btn v-if="evento.status == 1" color="sucess">
                solicitud activa
              </v-btn>
              <v-btn v-if="evento.status == 2" color="error">
                solicitud cancelada
              </v-btn>
              <v-btn v-if="evento.status == 3" color="error">
                solicitud aplazada
              </v-btn>
              <v-btn v-if="evento.status == 4" color="warning">
                solicitud pendiente aprobacion
              </v-btn>
              <v-btn v-if="evento.status == 5" color="info">
                solicitud finalizada
              </v-btn>
              <v-btn v-if="evento.status == 6" color="info">
                solicitud pendiente cambios
              </v-btn>
            </div>
          </v-flex>
          <v-flex lg8 md8 sm8 xs12>
            <div class="cont-desc-evento p-3">
              <v-layout row wrap>
                <v-flex lg6 md6 sm12 xs12 class="text-left">
                  <h3 class="mb-3">{{ evento.nombre }}</h3>
                  <p class="mb-0">
                    Fecha: {{ dateTimeToDate(evento.fecha_evento) }}
                  </p>
                  <p class="mb-0">
                    Apertura de puertas: {{ formatTime(evento.hora_apertura) }}
                  </p>
                  <p class="mb-0">
                    Horario inicio: {{ formatTime(evento.hora_inicio) }}
                  </p>
                  <p class="mb-0">
                    Horario de salida:
                    {{ formatTime(evento.hora_finalizacion) }}
                  </p>
                  <!-- <p class="mb-0">Edad minima: 18 años</p> -->
                  <!-- <p class="mb-0">Ciudad: {{ evento.id_ciudad }}</p> -->
                  <p class="mb-0">
                    Lugar: {{ getAuditorio(evento.id_auditorio) }}
                  </p>
                </v-flex>
                <v-flex lg6 md6 sm12 xs12 class="text-center mt-3">
                  <div class="text-right pt-5">
                    <!-- <h6 class="mb-3">Solicitud enviada el 15 de noviembre de 2018</h6> -->
                    <small class="text-danger"
                      ><em>A la espera de respuesta</em></small
                    >
                    <v-btn v-if="evento.status == 4" color="warning">
                      Inspeccionar para aprobar
                    </v-btn>
                  </div>
                </v-flex>
              </v-layout>
            </div>
          </v-flex>
        </v-layout>
      </div>
    </v-container>
    <div class="text-xs-center pt-2">
      <v-pagination
        v-model="paginationEventos.page"
        :length="pagesEventos"
      ></v-pagination>
    </div>
  </v-app>
</template>
<script>
import moment from "moment";

export default {
  data() {
    return {
      itemsFiltroEventos: [],
      itemsOrdenarEventos: [],
    };
  },
  async beforeCreate() {
    document.title = "Eventos Por Aprobar - CentralTickets";
    this.$store.dispatch("evento/listEventosStatus", { page: 1, status: 4 });
    await this.$store.dispatch("auditorio/listAuditorios");
  },
  watch: {
    paginationEventos: {
      async handler() {
        this.$store.dispatch("evento/listEventosStatus", {
          page: this.paginationEventos.page,
          status: 4,
        });
      },
      deep: true,
    },
  },
  computed: {
    eventos() {
      return this.$store.getters["evento/listEventos"];
    },
    auditorios() {
      return this.$store.getters["auditorio/listAuditorios"];
    },
    paginationEventos() {
      return this.$store.getters["evento/pagination"];
    },
    pagesEventos() {
      return this.$store.getters["evento/pages"];
    },
  },
  methods: {
    detalleEvento(id) {
      this.$router.push({ name: "aprobacionEvento", params: { id } });
    },
    dateTimeToDate(datetime) {
      if (datetime != null && datetime != "") {
        return moment(datetime).format("YYYY/MM/DD");
      } else {
        return "";
      }
    },
    formatTime(time) {
      if (time != null && time != "") {
        return moment(time, "HH:mm:ss").format("h a");
      } else {
        return "";
      }
    },
    getAuditorio(id) {
      let filter_auditorio = [];
      if (this.auditorios) {
        filter_auditorio = this.auditorios.filter(
          (auditorio) => auditorio.id == id
        );
      }
      return filter_auditorio[0].nombre;
    },
  },
};
</script>
<style scoped>
.cont-evento {
  border: 1px #dedede solid;
  transition: all 0.5s ease;
}
.cont-evento:hover {
  transition: all 0.5s ease;
  box-shadow: 0px 0px 20px #cecece;
  border-color: #fafafa;
  cursor: pointer;
}
.cont-img {
  background-size: 100% 100% !important;
  background-repeat: no-repeat !important;
  height: 100%;
  width: 100%;
  /*    min-height: 200px;
    max-height: 100%;
    max-width: 100%;*/
}
.cont-img img {
  width: 100%;
  max-width: 100%;
}
.estado-evento {
  position: absolute;
  text-transform: uppercase;
  padding: 10px;
  color: white;
  margin-top: 10px;
}
</style>