<template>
  <v-carousel
    height="100%"
    cycle
    hide-delimiters
    show-arrows-on-hover
    interval="15000"
    >
    <!--<v-carousel-item
        style="aspect-ratio: 19/10; width: 100%; background: linear-gradient(0.20turn, #F8F7F4, #F8F7F499, transparent);"
        src="@/assets/images/imagenesTemp/760x400-Artistas-internacionales.jpg"
        lazy-src="@/assets/icons/logo-CentralTickets-carousel.png"
    ></v-carousel-item>-->
    <!-- <v-carousel-item
        style="aspect-ratio: 19/10; width: 100%; background: linear-gradient(0.20turn, #F8F7F4, #F8F7F499, transparent);"
        src="@/assets/images/imagenesTemp/760x400-monster-truck-ecuador.jpg"
        lazy-src="@/assets/icons/logo-CentralTickets-carousel.png"
    ></v-carousel-item> -->
    <!-- <v-carousel-item
        style="aspect-ratio: 19/10; width: 100%; background: linear-gradient(0.20turn, #F8F7F4, #F8F7F499, transparent);"
        src="@/assets/images/imagenesTemp/760x400-avion-proximamente.jpg"
        lazy-src="@/assets/icons/logo-CentralTickets-carousel.png"
    ></v-carousel-item> -->
    <v-carousel-item
        style="aspect-ratio: 19/10; width: 100%; background: linear-gradient(0.20turn, #F8F7F4, #F8F7F499, transparent);"
        :key="index"
        v-for="(item,index) in items"
        :src="item.imagen.imagens[0].url"
        lazy-src="@/assets/icons/logo-CentralTickets-carousel.png"
        @click="redirect ? verEvento(item.imagen.slug) : ''"
    ></v-carousel-item>
    <div v-if="items==null">
        <v-carousel-item
            :key="index"
            v-for="(n, index) in 7"
            src="http://placehold.it/1200x480/888/FFF"
        ></v-carousel-item>
    </div>
  </v-carousel>
</template>
<script>
export default{
    props: [ 'items','redirect' ],
    methods:{
        verEvento(id){
            this.$router.push({ name: 'evento', params: { evento: id } });
        },
    },
    mounted(){
        console.log(this.items)
    }
};
</script>
<style>
#myCarousel .list-inline {
    white-space:nowrap;
    overflow-x:auto;
}

#myCarousel .carousel-indicators {
    position: static;
    left: initial;
    width: initial;
    margin-left: initial;
}

#myCarousel .carousel-indicators > li {
    width: initial;
    height: initial;
    text-indent: initial;
}

#myCarousel .carousel-indicators > li.active img {
    opacity: 0.7;
}
.img-container{
    cursor:pointer;
    height: 100%;
}
.img-container .v-image__image--cover{
    background-size: cover;
}
</style>