<template>
  <v-app>
    <v-container>
      <v-layout row wrap>
        <v-flex lg8 md8 sm8 xs12>
          <div>
            <span
              ><router-link
                :to="{ path: '/mis-eventos' }"
                title="Ver todos mis eventos"
                ><u>Mis eventos</u></router-link
              ></span
            ><span> > </span><span>Detalle de evento</span>
          </div>
        </v-flex>
        <v-flex lg4 md4 sm4 xs12 text-right>
          <a href="#" @click="tab = 5"><u>Soporte</u></a>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex lg6 md6 sm6 mt-4>
          <h2>Esto es vida de Silvestre Dangond-Armenia 2018</h2>
        </v-flex>
        <v-flex lg6 md6 sm6 mb-4 mt-4>
          <div class="cont-fecha-contador bg-white p-2">
            <v-layout row wrap>
              <v-flex lg6 md6 sm12 xs12 mb-1 mt-1>
                <div class="bg-light p-3 text-center">
                  <h2>19/10/2018</h2>
                </div>
              </v-flex>
              <v-flex lg6 md6 sm12 xs12 text-center mb-1 mt-1>
                <v-layout row wrap>
                  <v-flex lg4 md4 sm4 xs4>
                    <h1 class="mb-0">25</h1>
                    <small>DIAS</small>
                  </v-flex>
                  <v-flex lg4 md4 sm4 xs4>
                    <h1 class="mb-0">20</h1>
                    <small>HORAS</small>
                  </v-flex>
                  <v-flex lg4 md4 sm4 xs4>
                    <h1 class="mb-0">40</h1>
                    <small>MIN</small>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </div>
        </v-flex>
      </v-layout>
      <v-tabs
        fixed-tabs
        grow
        centered
        show-arrows
        slider-color="#c7a923"
        light
        v-model="tab"
      >
        <v-tab> Resumen </v-tab>
        <v-tab> Personalizacion </v-tab>
        <v-tab> Balance General </v-tab>
        <v-tab> Precios - Promociones </v-tab>
        <v-tab> Puntos de Venta </v-tab>
        <v-tab> Soporte </v-tab>
      </v-tabs>
      <template v-if="tab == 0">
        <div class="pt-4 pr-3 pl-3 bg-white pb-3 animated fadeIn">
          <v-layout row wrap>
            <v-flex lg4 md4 sm4 xs12 mb-3>
              <div class="cont-img">
                <div class="estado-evento bg-estado-venta">En Venta</div>
              </div>
            </v-flex>
            <v-flex lg8 md8 sm8 xs12 mb-3>
              <v-layout row wrap>
                <v-flex lg8 md8 sm8 xs12 mb-3>
                  <div class="mb-4">
                    <v-layout row wrap>
                      <v-flex lg2 md2 sm2 xs2 text-right pr-2>
                        <i class="material-icons">access_time</i>
                      </v-flex>
                      <v-flex lg10 md10 sm10 xs10>
                        <p class="mb-0"><b>Apertura de puertas:</b> 18:00</p>
                        <p class="mb-0"><b>Horario inicio:</b> 20:00</p>
                        <p class="mb-0"><b>Horario de salida:</b> 3:00</p>
                      </v-flex>
                    </v-layout>
                  </div>
                  <div class="mb-4">
                    <v-layout row wrap>
                      <v-flex lg2 md2 sm2 xs2 text-right pr-2>
                        <i class="material-icons">portrait</i>
                      </v-flex>
                      <v-flex lg10 md10 sm10 xs10>
                        <p class="mb-0"><b>Edad minima:</b> 18 años</p>
                      </v-flex>
                    </v-layout>
                  </div>
                  <div class="mb-4">
                    <v-layout row wrap>
                      <v-flex lg2 md2 sm2 xs2 text-right pr-2>
                        <i class="material-icons">place</i>
                      </v-flex>
                      <v-flex lg10 md10 sm10 xs10>
                        <p class="mb-0"><b>Ciudad:</b> Armenia</p>
                        <p class="mb-0"><b>Lugar:</b> Coliseo del cafe</p>
                      </v-flex>
                    </v-layout>
                  </div>
                </v-flex>
                <v-flex lg4 md4 sm4 xs12 mb-1>
                  <div
                    class="
                      promocion-anuncio
                      bg-danger
                      p-2
                      text-white text-center
                    "
                  >
                    <v-layout row wrap>
                      <v-flex lg6 md6 sm12 xs6>
                        <h2>
                          30% <br />
                          DCTO
                        </h2>
                      </v-flex>
                      <v-flex lg6 md6 sm12 xs6 mb-2>
                        <h6 class="pt-3">Valido hasta el 15 Oct/18</h6>
                      </v-flex>
                    </v-layout>
                  </div>
                </v-flex>
              </v-layout>
              <v-container pb-0 pt-0>
                <div class="text-right mb-3 mt-3">
                  <span class="font-weight-bold h2">14.635</span
                  >&nbsp;&nbsp;<span class="h6">Boletas disponibles</span>
                </div>
                <v-progress-linear
                  value="56"
                  color="primary"
                  height="40px"
                ></v-progress-linear>
                <v-layout row wrap>
                  <v-flex lg6 md6 sm6 xs12>
                    <div>
                      <span class="font-weight-bold h2">45.635</span
                      >&nbsp;&nbsp;<span class="h6">Boletas vendidas</span>
                    </div>
                  </v-flex>
                  <v-flex lg6 md6 sm6 xs12 text-right mt-2>
                    <span class="h6">Capacidad maxima: 60.000</span>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-flex>
          </v-layout>
          <v-alert type="warning" dismissible class="mt-3 mb-2" :value="true">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed quis
            leo vitae est dictum auctor. Duis sodales vestibulum odio, sit amet
            volutpat sem volutpat at.
          </v-alert>
          <v-alert type="warning" dismissible class="mb-2" :value="true">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed quis
            leo vitae est dictum auctor. Duis sodales vestibulum odio, sit amet
            volutpat sem volutpat at.
          </v-alert>
          <v-alert type="info" dismissible class="mb-2" :value="true">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed quis
            leo vitae est dictum auctor. Duis sodales vestibulum odio, sit amet
            volutpat sem volutpat at.
          </v-alert>
          <div class="mt-5">
            <v-layout row wrap>
              <v-flex lg6 md6 sm6 xs12 mb-3>
                <h2>Imagenes del evento</h2>
              </v-flex>
              <v-flex lg6 md6 sm6 xs12 mb-3 text-right>
                <v-btn color="default" @click="tab = 1"
                  >Personalizacion del evento</v-btn
                >
              </v-flex>
            </v-layout>
            <section class="imagesEvento slider mt-4">
              <div class="slide" title="" v-for="image in imagesEvento">
                <img :src="image.src" />
              </div>
            </section>
          </div>
          <div class="mt-5"></div>
          <v-layout row wrap>
            <v-flex lg6 md6 sm12 xs12 mb-4>
              <p class="font-weight-bold">Codigo PULEP: WCFE456</p>
              <p>
                EMPRESA RESPONSABLE <br />
                <b>DG Eventos S.A.S</b>
              </p>
              <p class="mt-2">NIT: 900575452-8</p>
              <p>
                Direccion: Cra. 25 #1A Sur 155 Edificio Alto Tesoro, Condominio
                Platinium, Int 705 Medellin, Antioquia.
              </p>
              <div class="mt-5">
                <h5 class="mb-4 font-weight-bold">Términos y condiciones</h5>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </p>
              </div>
            </v-flex>
            <v-flex lg6 md6 sm12 xs12 mb-4>
              <h5 class="mb-3 font-weight-bold">Condiciones y restricciones</h5>
              <p class="mb-0">Prohibido el ingreso de bebidas y alimentos</p>
              <p class="mb-0">
                Prohibido el ingreso de armas o elementos cortopunzantes
              </p>
              <p class="mb-0">Prohibido el ingreso a mujeres embarazadas</p>
              <p class="mb-0">Edad minima 18 años</p>
              <p class="mb-0">
                Las personas discapacitadas ingresan bajo su propia
                responsabilidad. Se recomienda el acceso a palcos
              </p>
              <p class="mb-0">
                Toda persona ingresa con boleta del evento la cual debe
                presentar en el lugar
              </p>
              <div class="mt-3 text-center">
                <v-btn color="default" @click="tab = 1"
                  >Editar condiciones y restricciones</v-btn
                >
              </div>
            </v-flex>
          </v-layout>
        </div>
      </template>
      <template v-if="tab == 1">
        <div class="pt-4 pr-3 pl-3 bg-white pb-3 animated fadeIn">
          <v-layout row wrap>
            <v-flex lg4 md4 sm4 xs12 mb-3>
              <div class="cont-img">
                <div class="estado-evento bg-estado-venta">En Venta</div>
              </div>
            </v-flex>
            <v-flex lg8 md8 sm8 xs12 mb-3>
              <v-layout row wrap>
                <v-flex lg8 md8 sm8 xs12 mb-3>
                  <div class="mb-4">
                    <v-layout row wrap>
                      <v-flex lg2 md2 sm2 xs2 text-right pr-2>
                        <i class="material-icons">access_time</i>
                      </v-flex>
                      <v-flex lg10 md10 sm10 xs10>
                        <p class="mb-0"><b>Apertura de puertas:</b> 18:00</p>
                        <p class="mb-0"><b>Horario inicio:</b> 20:00</p>
                        <p class="mb-0"><b>Horario de salida:</b> 3:00</p>
                      </v-flex>
                    </v-layout>
                  </div>
                  <div class="mb-4">
                    <v-layout row wrap>
                      <v-flex lg2 md2 sm2 xs2 text-right pr-2>
                        <i class="material-icons">portrait</i>
                      </v-flex>
                      <v-flex lg10 md10 sm10 xs10>
                        <p class="mb-0"><b>Edad minima:</b> 18 años</p>
                      </v-flex>
                    </v-layout>
                  </div>
                  <div class="mb-4">
                    <v-layout row wrap>
                      <v-flex lg2 md2 sm2 xs2 text-right pr-2>
                        <i class="material-icons">place</i>
                      </v-flex>
                      <v-flex lg10 md10 sm10 xs10>
                        <p class="mb-0"><b>Ciudad:</b> Armenia</p>
                        <p class="mb-0"><b>Lugar:</b> Coliseo del cafe</p>
                      </v-flex>
                    </v-layout>
                  </div>
                </v-flex>
                <v-flex lg4 md4 sm4 xs12 mb-1>
                  <div
                    class="
                      promocion-anuncio
                      bg-danger
                      p-2
                      text-white text-center
                    "
                  >
                    <v-layout row wrap>
                      <v-flex lg6 md6 sm12 xs6>
                        <h2>
                          30% <br />
                          DCTO
                        </h2>
                      </v-flex>
                      <v-flex lg6 md6 sm12 xs6 mb-2>
                        <h6 class="pt-3">Valido hasta el 15 Oct/18</h6>
                      </v-flex>
                    </v-layout>
                  </div>
                </v-flex>
              </v-layout>
              <v-container pb-0 pt-0>
                <div class="text-right mb-3 mt-3">
                  <span class="font-weight-bold h2">14.635</span
                  >&nbsp;&nbsp;<span class="h6">Boletas disponibles</span>
                </div>
                <v-progress-linear
                  value="56"
                  color="primary"
                  height="40px"
                ></v-progress-linear>
                <v-layout row wrap>
                  <v-flex lg6 md6 sm6 xs12>
                    <div>
                      <span class="font-weight-bold h2">45.635</span
                      >&nbsp;&nbsp;<span class="h6">Boletas vendidas</span>
                    </div>
                  </v-flex>
                  <v-flex lg6 md6 sm6 xs12 text-right mt-2>
                    <span class="h6">Capacidad maxima: 60.000</span>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-flex>
          </v-layout>
          <div class="mt-5">
            <h2 class="mb-3">Imagenes del evento</h2>
            <v-layout row wrap>
              <v-flex lg3 md3 sm4 xs12 v-for="imagen in imagesEvento">
                <div
                  class="mb-3 cont-img-evento-personalizacion"
                  :style="'background: url(' + imagen.src + ');'"
                >
                  <div class="text-right">
                    <span title="Editar imagen" class="link-edit"
                      ><v-btn dark small fab
                        ><v-icon small light>edit</v-icon></v-btn
                      ></span
                    >
                  </div>
                </div>
              </v-flex>
            </v-layout>
          </div>
          <div class="mt-5"></div>
          <v-layout row wrap>
            <v-flex lg6 md6 sm12 xs12 mb-4>
              <p class="font-weight-bold">Codigo PULEP: WCFE456</p>
              <p>
                EMPRESA RESPONSABLE <br />
                <b>DG Eventos S.A.S</b>
              </p>
              <p class="mt-2">NIT: 900575452-8</p>
              <p>
                Direccion: Cra. 25 #1A Sur 155 Edificio Alto Tesoro, Condominio
                Platinium, Int 705 Medellin, Antioquia.
              </p>
              <div class="mt-5">
                <h5 class="mb-4 font-weight-bold">Términos y condiciones</h5>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </p>
              </div>
            </v-flex>
            <v-flex lg6 md6 sm12 xs12 mb-4>
              <h5 class="mb-3 font-weight-bold">Condiciones y restricciones</h5>
              <p class="mb-0">Prohibido el ingreso de bebidas y alimentos</p>
              <p class="mb-0">
                Prohibido el ingreso de armas o elementos cortopunzantes
              </p>
              <p class="mb-0">Prohibido el ingreso a mujeres embarazadas</p>
              <p class="mb-0">Edad minima 18 años</p>
              <p class="mb-0">
                Las personas discapacitadas ingresan bajo su propia
                responsabilidad. Se recomienda el acceso a palcos
              </p>
              <p class="mb-0">
                Toda persona ingresa con boleta del evento la cual debe
                presentar en el lugar
              </p>
              <div class="mt-3 text-center">
                <v-btn color="default" @click="tab = 1"
                  >Editar condiciones y restricciones</v-btn
                >
              </div>
            </v-flex>
          </v-layout>
        </div>
      </template>
      <template v-if="tab == 2">
        <div class="pt-4 pr-3 pl-3 bg-white pb-3 animated fadeIn">
          <v-layout row wrap>
            <v-flex lg8 md8 sm8 xs12 mb-3>
              <v-layout row wrap>
                <v-flex lg6 md6 sm6 xs12 mb-2>
                  <h3>VENTAS</h3>
                </v-flex>
                <v-flex lg6 md6 sm6 xs12 mb-2>
                  <h2 class="font-weight-bold">$ 56.356,58</h2>
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex lg6 md6 sm6 xs12 mb-2>
                  <h3>COSTOS/GASTOS</h3>
                </v-flex>
                <v-flex lg6 md6 sm6 xs12 mb-2>
                  <h2 class="text-secondary font-weight-bold">$ 16.356,58</h2>
                  <hr class="mb-2 mt-0" />
                  <h2 class="font-weight-bold text-success">$ 46.356,58</h2>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex lg4 md4 sm4 xs12 mb-3 text-center>
              <div class="mb-3">
                <v-btn
                  v-bind:disabled="tipoBalance == 'ventas'"
                  :color="tipoBalance == 'ventas' ? 'default' : 'primary'"
                  @click="tipoBalance = 'ventas'"
                  >Ver detalle de ventas</v-btn
                >
              </div>
              <v-btn
                v-bind:disabled="tipoBalance == 'gastosCostos'"
                :color="tipoBalance == 'gastosCostos' ? 'default' : 'primary'"
                @click="tipoBalance = 'gastosCostos'"
                >Ver detalle de Gastos/Costos</v-btn
              >
            </v-flex>
          </v-layout>
          <template v-if="tipoBalance == 'ventas'">
            <v-container pb-0 pt-0>
              <div class="text-right mb-3 mt-3">
                <span class="font-weight-bold h2">14.635</span>&nbsp;&nbsp;<span
                  class="h6"
                  >Boletas disponibles</span
                >
              </div>
              <v-progress-linear
                value="56"
                color="primary"
                height="40px"
              ></v-progress-linear>
              <v-layout row wrap>
                <v-flex lg6 md6 sm6 xs12>
                  <div>
                    <span class="font-weight-bold h2">45.635</span
                    >&nbsp;&nbsp;<span class="h6">Boletas vendidas</span>
                  </div>
                </v-flex>
                <v-flex lg6 md6 sm6 xs12 text-right mt-2>
                  <span class="h6">Capacidad maxima: 60.000</span>
                </v-flex>
              </v-layout>
            </v-container>
            <div class="mt-5"></div>
            <v-layout row wrap>
              <v-flex lg6 md6 sm12 xs12 mb-3>
                <div class="table-responsive">
                  <table class="table table-striped table-hover table-bordered">
                    <thead>
                      <tr>
                        <th>Localidad</th>
                        <th>Aforo</th>
                        <th># Boletas Vendidas</th>
                        <th>Total vendido</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Palco F.A.M.E</td>
                        <td>32</td>
                        <td>500</td>
                        <td>$ 5.356.000</td>
                      </tr>
                      <tr>
                        <td>Sillas F.A.M.E</td>
                        <td>160</td>
                        <td>500</td>
                        <td>$ 5.356.000</td>
                      </tr>
                      <tr>
                        <td>Palco oro</td>
                        <td>60</td>
                        <td>500</td>
                        <td>$ 5.356.000</td>
                      </tr>
                      <tr>
                        <td>Palco Callejon</td>
                        <td>13</td>
                        <td>500</td>
                        <td>$ 5.356.000</td>
                      </tr>
                      <tr>
                        <td>Palco presidencia (individual)</td>
                        <td>50</td>
                        <td>500</td>
                        <td>$ 5.356.000</td>
                      </tr>
                      <tr>
                        <td>Preferencial</td>
                        <td>5.000</td>
                        <td>500</td>
                        <td>$ 5.356.000</td>
                      </tr>
                      <tr>
                        <td>General</td>
                        <td>5.000</td>
                        <td>500</td>
                        <td>$ 5.356.000</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </v-flex>
              <v-flex lg6 md6 sm12 xs12 mb-3>
                <div>
                  <apexchart
                    width="100%"
                    height="350"
                    type="donut"
                    :options="optionsDonut"
                    :series="seriesDonut"
                  ></apexchart>
                </div>
              </v-flex>
            </v-layout>
            <div class="mt-5"></div>
            <v-layout row wrap>
              <v-flex lg6 md6 sm12 xs12 mb-3>
                <apexchart
                  width="100%"
                  height="350"
                  type="line"
                  :options="optionsLine"
                  :series="seriesLine"
                ></apexchart>
              </v-flex>
              <v-flex lg6 md6 sm12 xs12 mb-3>
                <apexchart
                  width="100%"
                  height="350"
                  type="bar"
                  :options="optionsBar"
                  :series="seriesBar"
                ></apexchart>
              </v-flex>
            </v-layout>
            <div class="mt-5"></div>
            <v-layout row wrap>
              <v-flex lg3 md3 sm6 xs12 mb-3>
                <div class="table-responsive mt-4 pt-3">
                  <table class="table table-striped table-hover table-bordered">
                    <thead>
                      <tr>
                        <th>Localidad</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Palco F.A.M.E</td>
                      </tr>
                      <tr>
                        <td>Sillas F.A.M.E</td>
                      </tr>
                      <tr>
                        <td>Palco oro</td>
                      </tr>
                      <tr>
                        <td>Palco Callejon</td>
                      </tr>
                      <tr>
                        <td>Palco presidencial</td>
                      </tr>
                      <tr>
                        <td>Preferencial</td>
                      </tr>
                      <tr>
                        <td>General</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </v-flex>
              <v-flex lg3 md3 sm6 xs12 mb-3 text-center>
                <div
                  class="
                    cont-title-table-balance
                    bg-info
                    p-2
                    mr-1
                    ml-1
                    text-center text-white
                  "
                >
                  <p class="mb-0">Sexo</p>
                </div>
                <div class="table-responsive pr-1 pl-1">
                  <table class="table table-striped table-hover table-bordered">
                    <thead>
                      <tr>
                        <th>Hombre</th>
                        <th>Mujer</th>
                        <th>N/R</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>50%</td>
                        <td>50%</td>
                        <td>0</td>
                      </tr>
                      <tr>
                        <td>60%</td>
                        <td>40%</td>
                        <td>0</td>
                      </tr>
                      <tr>
                        <td>63%</td>
                        <td>10%</td>
                        <td>27%</td>
                      </tr>
                      <tr>
                        <td>50%</td>
                        <td>50%</td>
                        <td>0</td>
                      </tr>
                      <tr>
                        <td>60%</td>
                        <td>40%</td>
                        <td>0</td>
                      </tr>
                      <tr>
                        <td>63%</td>
                        <td>10%</td>
                        <td>27%</td>
                      </tr>
                      <tr>
                        <td>63%</td>
                        <td>10%</td>
                        <td>27%</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </v-flex>
              <v-flex lg3 md3 sm6 xs12 mb-3 text-center>
                <div
                  class="
                    cont-title-table-balance
                    bg-info
                    p-2
                    mr-1
                    ml-1
                    text-center text-white
                  "
                >
                  <p class="mb-0">Edades</p>
                </div>
                <div class="table-responsive pr-1 pl-1">
                  <table class="table table-striped table-hover table-bordered">
                    <thead>
                      <tr>
                        <th>Entre 18 y 25</th>
                        <th>Entre 26 y 35</th>
                        <th>Mas de 35</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>20%</td>
                        <td>20%</td>
                        <td>60%</td>
                      </tr>
                      <tr>
                        <td>20%</td>
                        <td>20%</td>
                        <td>60%</td>
                      </tr>
                      <tr>
                        <td>20%</td>
                        <td>20%</td>
                        <td>60%</td>
                      </tr>
                      <tr>
                        <td>20%</td>
                        <td>20%</td>
                        <td>60%</td>
                      </tr>
                      <tr>
                        <td>20%</td>
                        <td>20%</td>
                        <td>60%</td>
                      </tr>
                      <tr>
                        <td>20%</td>
                        <td>20%</td>
                        <td>60%</td>
                      </tr>
                      <tr>
                        <td>20%</td>
                        <td>20%</td>
                        <td>60%</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </v-flex>
              <v-flex lg3 md3 sm6 xs12 mb-3 text-center>
                <div
                  class="
                    cont-title-table-balance
                    bg-info
                    p-2
                    mr-1
                    ml-1
                    text-center text-white
                  "
                >
                  <p class="mb-0">Dpto. con mayores ventas</p>
                </div>
                <div class="table-responsive pr-1 pl-1">
                  <table class="table table-striped table-hover table-bordered">
                    <thead>
                      <tr>
                        <th>Departamento</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Norte de santander (X%)</td>
                      </tr>
                      <tr>
                        <td>Santander (X%)</td>
                      </tr>
                      <tr>
                        <td>Cundinamarca (X%)</td>
                      </tr>
                      <tr>
                        <td>Nariño (X%)</td>
                      </tr>
                      <tr>
                        <td>Bolivar (X%)</td>
                      </tr>
                      <tr>
                        <td>Santander (X%)</td>
                      </tr>
                      <tr>
                        <td>Cundinamarca (X%)</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </v-flex>
            </v-layout>
          </template>
          <template v-if="tipoBalance == 'gastosCostos'">
            <h3 class="text-center mt-4">Costos asociados al evento</h3>
            <h5 class="mt-3 mb-5 text-center">
              Por favor indique los costos asociados al evento en cada uno de
              los conceptos que resulten pertinentes.
            </h5>
            <table class="table table-striped table-hover mt-4">
              <thead>
                <tr>
                  <th>Tipo</th>
                  <th>Descripcion</th>
                  <th>Monto</th>
                  <th>Total</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input type="text" class="form-control" />
                  </td>
                  <td>
                    <input type="text" class="form-control" />
                  </td>
                  <td>
                    <input type="number" class="form-control" />
                  </td>
                  <td>&nbsp;</td>
                </tr>
                <tr v-for="(costo, index) in costos" :key="index">
                  <td>
                    <input type="text" class="form-control" />
                  </td>
                  <td>
                    <input type="text" class="form-control" />
                  </td>
                  <td>
                    <input type="number" class="form-control" />
                  </td>
                  <td></td>
                  <td>
                    <v-btn
                      icon
                      title="Remover costo"
                      small
                      color="red"
                      @click="removeCosto(index)"
                    >
                      <v-icon small color="white">remove</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="mb-4">
              <v-btn color="primary" @click="newCosto">Agregar costo</v-btn>
            </div>
            <v-layout row wrap>
              <v-flex lg4 md4></v-flex>
              <v-flex lg8 md8 text-right mb-5>
                <v-text-field
                  placeholder="Indique el minimo garantizado para la realizacion del evento ($)"
                  label="Minimo garantizado ($)"
                  type="number"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </template>
        </div>
      </template>
      <template v-if="tab == 3">
        <div class="pt-4 pr-3 pl-3 bg-white pb-3 animated fadeIn">
          <div class="cont-preventa-evento">
            <v-tabs
              color="primary"
              dark
              fixed-tabs
              show-arrows
              slider-color="white"
            >
              <v-tab v-for="preventa in preventasEvento">
                {{ preventa.nombre }}
              </v-tab>
            </v-tabs>
            <div class="sub-cont-preventa-evento">
              <div class="table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th>Localidad</th>
                      <th>Aforo</th>
                      <th>Precio</th>
                      <th>Servicio</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="venta in ventas">
                      <td>{{ venta.localidad }}</td>
                      <td>{{ venta.aforo }}</td>
                      <td>{{ formatNumber(venta.precio) }}</td>
                      <td>{{ formatNumber(venta.servicio) }}</td>
                      <td>{{ formatNumber(venta.precio + venta.servicio) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="cont-mapa-asientos mt-5 mb-5">
            <div class="sub">
              <h2>Mapa de asientos</h2>
              <p>
                Se va a diagramar de forma tal que el usuario pueda hacer clic
                en cada zona y ver el precio que corresponde
              </p>
            </div>
            <v-switch color="orange darken-3">
              <template v-slot:label>
                <v-icon class="material-icons">accessible</v-icon>
              </template>
            </v-switch>
          </div>
          <v-layout row wrap>
            <v-flex lg6 md6 sm12 xs12 mb-4>
              <div class="table-responsive pr-2">
                <table class="table table-bordered table-striped table-hover">
                  <thead>
                    <tr>
                      <th>Etapa</th>
                      <th>Desde</th>
                      <th>Hasta</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Preventa</td>
                      <td>-</td>
                      <td>6 de octubre</td>
                    </tr>
                    <tr>
                      <td>Ejemplo</td>
                      <td>7 Octubre</td>
                      <td>20 Noviembre</td>
                    </tr>
                    <tr>
                      <td>Ejemplo</td>
                      <td>7 Octubre</td>
                      <td>20 Noviembre</td>
                    </tr>
                    <tr>
                      <td>Ejemplo</td>
                      <td>7 Octubre</td>
                      <td>20 Noviembre</td>
                    </tr>
                    <tr>
                      <td>Ejemplo</td>
                      <td>7 Octubre</td>
                      <td>20 Noviembre</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </v-flex>
            <v-flex lg6 md6 sm12 xs12 mb-4>
              <div class="table-responsive pl-2">
                <table class="table table-bordered table-hover table-striped">
                  <thead>
                    <tr>
                      <th>Nombre cupon</th>
                      <th>Codigo</th>
                      <th>Valido hasta</th>
                      <th>Monto / Descuento</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Compra en metro</td>
                      <td>123ABC</td>
                      <td>10/10</td>
                      <td>10%</td>
                    </tr>
                    <tr>
                      <td>Compra en metro</td>
                      <td>123ABC</td>
                      <td>10/10</td>
                      <td>10%</td>
                    </tr>
                    <tr>
                      <td>Compra en metro</td>
                      <td>123ABC</td>
                      <td>10/10</td>
                      <td>10%</td>
                    </tr>
                    <tr>
                      <td>Compra en metro</td>
                      <td>123ABC</td>
                      <td>10/10</td>
                      <td>10%</td>
                    </tr>
                    <tr>
                      <td>Compra en metro</td>
                      <td>123ABC</td>
                      <td>10/10</td>
                      <td>10%</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </v-flex>
          </v-layout>
          <div class="table-responsive">
            <table class="table table-striped table-hover table-bordered">
              <thead>
                <tr>
                  <th>Promocion</th>
                  <th>Estado</th>
                  <th>Condicion</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>30%</td>
                  <td>Activa</td>
                  <td>Desde el 01/10 hasta el 20/10</td>
                </tr>
                <tr>
                  <td>2x1</td>
                  <td>Inactiva</td>
                  <td>Compra dos entradas por el precio de una</td>
                </tr>
                <tr>
                  <td>Los chiquillos no pagan</td>
                  <td>Inactiva</td>
                  <td>Menores de 15 años entran gratis</td>
                </tr>
                <tr>
                  <td>30%</td>
                  <td>Activa</td>
                  <td>Desde el 01/10 hasta el 20/10</td>
                </tr>
                <tr>
                  <td>2x1</td>
                  <td>Inactiva</td>
                  <td>Compra dos entradas por el precio de una</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </template>
      <template v-if="tab == 4">
        <div class="pt-4 pr-3 pl-3 bg-white pb-3 animated fadeIn">
          <div class="mb-4 bg-secondary pt-5 pb-5 text-center text-white">
            <p>Mapa puntos de venta</p>
          </div>
          <div class="table-responsive">
            <table class="table table-striped table-bordered table-hover">
              <thead>
                <tr>
                  <th>Nombre/Razon social</th>
                  <th>Identificacion</th>
                  <th>Responsable</th>
                  <th>Zona</th>
                  <th>Dpto</th>
                  <th>Preimpresas</th>
                  <th>Boletas vendidas</th>
                  <th>Ranking</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </template>
      <template v-if="tab == 5">
        <div class="pt-4 pr-3 pl-3 bg-white pb-3 animated fadeIn">
          <div class="mt-4"></div>
          <v-layout row wrap>
            <v-flex lg3 md3 sm6 xs12 mb-3 pr-2 pl-2>
              <v-text-field
                solo
                label="Buscar"
                prepend-inner-icon="search"
                class="search mt-2"
              ></v-text-field>
            </v-flex>
            <v-flex lg3 md3 sm6 xs12 mb-3 pr-2 pl-2>
              <v-autocomplete
                :items="filtrarTicketsPor"
                solo
                label="Filtrar por"
              ></v-autocomplete>
            </v-flex>
            <v-flex lg3 md3 sm6 xs12 mb-3 pr-2 pl-2>
              <v-autocomplete
                :items="ordenarTicketsPor"
                solo
                label="Ordenar por"
              ></v-autocomplete>
            </v-flex>
            <v-flex lg3 md3 sm6 xs12 mb-3 pr-2 pl-2>
              <v-btn @click="" color="primary">Nuevo ticket de soporte</v-btn>
            </v-flex>
          </v-layout>
          <div class="mt-4">
            <v-data-table
              :headers="headersTableTickets"
              must-sort
              :items="ticketsSoporte"
              :itemsPerPage="ticketsSoporte.length"
              hide-default-footer
              length="1"
              class="elevation-1"
              :no-data-text="'No se han encontrado tickets...'"
            >
              <template v-slot:items="props">
                <td>{{ props.item.num }}</td>
                <td class="text-xs-center">{{ props.item.tipo }}</td>
                <td class="text-xs-center">{{ props.item.fecha }}</td>
                <td class="text-xs-center">{{ props.item.creado_por }}</td>
                <td class="text-xs-center">{{ props.item.estado }}</td>
                <td class="text-right">
                  <v-btn small fab mt-0 mb-0>
                    <v-icon> search </v-icon>
                  </v-btn>
                  <v-btn small fab mt-0 mb-0>
                    <v-icon> edit </v-icon>
                  </v-btn>
                  <v-btn
                    small
                    fab
                    color="red"
                    mt-0
                    mb-0
                    title="Eliminar ticket"
                  >
                    <v-icon color="white"> delete </v-icon>
                  </v-btn>
                </td>
              </template>
            </v-data-table>
          </div>
        </div>
      </template>
    </v-container>
  </v-app>
</template>
<script type="text/javascript">
export default {
  mounted() {
    $(document).ready(function () {
      $(".imagesEvento").slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        dots: false,
        pauseOnHover: false,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 4,
            },
          },
          {
            breakpoint: 520,
            settings: {
              slidesToShow: 3,
            },
          },
        ],
      });
    });
  },
  updated() {
    $(".imagesEvento")
      .not(".slick-initialized")
      .slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        dots: false,
        pauseOnHover: false,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 4,
            },
          },
          {
            breakpoint: 520,
            settings: {
              slidesToShow: 3,
            },
          },
        ],
      });
  },
  data() {
    return {
      tab: 0,
      imagesEvento: [
        {
          src: "/images/aliados/Acosta-Velasquez-Eventos.png",
        },
        {
          src: "/images/aliados/cenfer.png",
        },
        {
          src: "/images/aliados/cpeventoslogo.png",
        },
        {
          src: "/images/aliados/donpagologo.png",
        },
        {
          src: "/images/aliados/Herazo-Eventos.png",
        },
        {
          src: "/images/aliados/ideartes.png",
        },
        {
          src: "/images/aliados/Santa-Fe-escudo-2016.png",
        },
        {
          src: "/images/aliados/zona-f-logo.png",
        },
      ],
      seriesDonut: [44, 55, 41, 17, 15],
      optionsDonut: {
        labels: ["Uno", "Dos", "Tres", "Cuatro", "Cinco"],
      },
      seriesLine: [
        {
          name: "venta uno",
          data: [14, 22, 25, 15, 25, 28, 38, 46],
        },
        {
          name: "venta dos",
          data: [20, 29, 37, 36, 44, 45, 50, 58],
        },
      ],
      optionsLine: {
        xaxis: {
          categories: [2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016],
        },
        yaxis: [
          {
            title: {
              text: "Uno",
            },
          },
          {
            opposite: true,
            title: {
              text: "Dos",
            },
          },
        ],
        chart: {
          toolbar: {
            show: false,
          },
        },
      },
      optionsBar: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998],
        },
      },
      seriesBar: [
        {
          name: "Ventas",
          data: [30, 40, 45, 50, 49, 60, 70, 91],
        },
      ],
      tipoBalance: "ventas",
      costos: [],
      ventas: [
        {
          localidad: "palco F.A.M.E",
          aforo: 32,
          precio: 456000,
          servicio: 200000,
        },
        {
          localidad: "palco F.A.M.E",
          aforo: 32,
          precio: 456000,
          servicio: 200000,
        },
        {
          localidad: "palco F.A.M.E",
          aforo: 32,
          precio: 456000,
          servicio: 200000,
        },
        {
          localidad: "palco F.A.M.E",
          aforo: 32,
          precio: 456000,
          servicio: 200000,
        },
        {
          localidad: "palco F.A.M.E",
          aforo: 32,
          precio: 456000,
          servicio: 200000,
        },
        {
          localidad: "palco F.A.M.E",
          aforo: 32,
          precio: 456000,
          servicio: 200000,
        },
        {
          localidad: "palco F.A.M.E",
          aforo: 32,
          precio: 456000,
          servicio: 200000,
        },
      ],
      ordenarTicketsPor: [],
      filtrarTicketsPor: [],
      headersTableTickets: [
        {
          text: "Num",
          align: "left",
          sortable: false,
          value: "num",
        },
        { text: "Tipo", value: "tipo", align: "center" },
        { text: "Fecha", value: "fecha", align: "center" },
        { text: "Creado por", value: "creado_por", align: "center" },
        { text: "Estado", value: "estado", align: "center" },
        { text: "Acciones", value: "", align: "center" },
      ],
      ticketsSoporte: [
        {
          num: 1,
          tipo: "Urgente",
          fecha: "10-10-2018",
          creado_por: "usuario.apellido",
          estado: "Abierto",
        },
      ],
    };
  },
  beforeCreate() {
    document.title = "Detalle de evento - CentralTickets";
    this.$store.dispatch("evento/listPreventasEvento", this.$route.params.id);
  },
  methods: {
    newCosto() {
      this.costos.push(Math.random(0, 10000));
    },
    removeCosto(index) {
      this.costos.splice(index, 1);
    },
    formatNumber(number) {
      return new Intl.NumberFormat("es-CO", {
        style: "currency",
        currency: "COP",
        minimumFractionDigits: 0,
      }).format(number);
    },
  },
  computed: {
    preventasEvento() {
      return this.$store.getters["evento/listPreventasEvento"];
    },
  },
};
</script>
<style scoped>
.cont-fecha-contador {
  border: 1px #d6d6d6 solid;
}
.cont-img {
  background: url(/images/proximamente.jpg);
  background-size: 100% 100%;
  height: 100%;
  width: 100%;
  min-height: 200px;
  max-height: 100%;
  max-width: 100%;
}
.cont-img img {
  width: 100%;
  max-width: 100%;
}
.bg-estado-venta {
  background-color: #007bff;
}
.bg-estado-pendiente {
  background-color: #68bd6c;
}
.bg-estado-solicitud {
  background-color: #7fbf82;
}
.estado-evento {
  position: absolute;
  text-transform: uppercase;
  padding: 10px;
  color: white;
  margin-top: 10px;
}
.cont-img-evento-personalizacion {
  min-height: 200px;
  background-size: 100% 100% !important;
  background-repeat: no-repeat !important;
  border: 1px #e2e2e2 solid;
  margin: 10px;
}
.cont-img-evento-personalizacion .link-edit {
}
.cont-preventa-evento {
  background-color: white;
  box-shadow: 0px 0px 10px #dedede;
  border-radius: 5px;
}
.heading-cont-preventa-evento {
  background-color: #1976d2;
  color: white;
  padding: 10px;
  text-align: center;
  margin-bottom: 0px;
}
.sub-cont-preventa-evento {
  border: 1px #c5c5c5 solid;
}
.cont-mapa-asientos {
  background-color: white;
  text-align: center;
  box-shadow: 0px 0px 10px #dedede;
  border-radius: 5px;
  padding: 10px;
}
.cont-mapa-asientos .sub {
  padding: 80px 40px;
}
</style>