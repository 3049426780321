<template>
  <div>
    <nav class="navbar nav-public navbar-dark navbar-expand-md bg-dark-other justify-content-center">
      <router-link :to="{path: '/'}" class="navbar-brand" title="CentralTickets" tag="a">
        <img class="logo" src="../../../public/images/logo-CentralTickets.png">
      </router-link>
      <b style="color:white; width: 140px; font-size: 14px;" >
        Contáctanos <router-link to="/pqr" style="color:white; width: 140px; font-size: 14px; text-decoration-line: underline;">Aquí</router-link>       
      </b>
      <button class="navbar-toggler ml-1" type="button" data-toggle="collapse" data-target="#collapsingNavbar2">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="navbar-collapse collapse justify-content-between align-items-center w-100" id="collapsingNavbar2">
        <ul class="navbar-nav mx-auto text-center menu-public">
          <!-- <li class="nav-item">
            <router-link to="/puntosVenta" title="Puntos de venta autorizados" class="nav-link text-white item-menu-public"><i class="material-icons">location_on</i> Puntos de venta</router-link>
          </li> -->
          <li class="nav-item">
            <router-link to="/comunicados" title="Ver los comunicados" class="nav-link text-white item-menu-public"><i class="material-icons">forum</i> Comunicados</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/pqr" title="Preguntas, Quejas y Reclamos" class="nav-link text-white item-menu-public"><i class="material-icons">contact_support</i> PQR</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/contacto" title="Contactanos" class="nav-link text-white item-menu-public"><i class="material-icons">contact_mail</i> Contacto</router-link>
          </li>
        </ul>
        <ul class="nav navbar-nav flex-row justify-content-center flex-nowrap">

          <li class="nav-item pr-2" v-if="!$store.state.user.accessToken">
            <a class="nav-link" href="#" @click.prevent="showModalLogin"><i class="material-icons">person</i></a>
            <!--<router-link :to="{ name: 'login' }" class="nav-link" title="Iniciar sesion" exact><i class="material-icons">person</i></router-link>-->
          </li>
          <li class="nav-item dropdown pr-2" v-if="$store.state.user.accessToken">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
               aria-haspopup="true" aria-expanded="false" title="Mis opciones">
              <img src="/images/user.png" class="img-logged">
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <router-link class="dropdown-item" :to="{name: 'perfil'}">Mi perfil</router-link>
              <router-link class="dropdown-item" :to="{name: 'perfil', params: {optPerfil: 3}}" v-if="usuario.id_rol==2">Mis compras</router-link>
              <!-- <router-link class="dropdown-item" :to="{}">Configurar alertas</router-link> -->
              <router-link class="dropdown-item" :to="{name: 'contacto'}">Soporte</router-link>

              <router-link class="dropdown-item" :to="{ name: 'misEventos' }"
                           v-if="usuario.id_rol==3 || usuario.id_rol==4 || usuario.id_rol==8 || usuario.id_rol==9 || usuario.id_rol==5 || usuario.id_rol==7">
                Mis eventos
              </router-link>
              <router-link class="dropdown-item" :to="{ name: 'PuntosVentaJefePunto' }"
                           v-if="usuario.id_rol==7">
                Puntos de venta
              </router-link>
              <router-link class="dropdown-item" :to="{ name: 'pagosParciales' }"
                           v-if="usuario.id_rol==5">
                Pagos parciales
              </router-link>
              <router-link class="dropdown-item" :to="{ name: 'eventosAprobacion' }" v-if="usuario.id_rol==6">Eventos
                aprobacion
              </router-link>

              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#" @click.prevent="logout">Cerrar sesion</a>
            </div>
          </li>
          <li class="nav-item pr-2">
            <a class="nav-link" href="#"><i class="material-icons">shopping_cart</i></a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/contacto"><i class="material-icons">phone</i></a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>
<script>
export default {

  methods: {
    logout() {
      this.$store.dispatch('logout');
    },
    showModalLogin() {
      this.$store.commit('showModalLogin', true);
    },
  },
  computed: {
    usuario() {
      return this.$store.getters['user/dataUserLogged'];
    },
    modalLogin() {
      return this.$store.getters['modalLogin'];
    },
  },
};
</script>
<style>
.cont-input-search span {
  background-color: white;
}

.cont-input-search input {
  border-left: 0px;
}

.bg-dark-other {
  background-color: #313131 !important;
}

.nav-link {
  color: white !important;
}
.nav-link:hover{
  color: #ffffffa4 !important;
}
.search .v-input__control {
  min-height: auto !important;
}

.search .v-input__slot {
  border-radius: 100px !important;
}

/*.router-link-exact-active{
    color: #fff !important;
}*/
.img-logged {
  max-width: 30px;
  box-shadow: 0px 3px 7px 2px rgb(0 0 0 / 60%);
    border-radius: 100%;
}
.img-logged:hover{
  opacity: .9;
}
.dropdown-menu{
  position: absolute !important;
}
.dropdown-menu.show {
  top: 42px;
  left: -28px;
}

.dropdown-toggle::after {
  display: none !important;
}
.item-menu-public{
  font-size: 16px ;
  font-weight: bold;
}
.item-menu-public:hover{
  color: white !important;
  opacity: .7;
}
.nav-public{
  background-color: rgb(49 43 17) !important;
  background: url('/images/header.jpg');
  background-position-x: 50%;
  background-blend-mode: overlay;
  background-attachment: fixed;
  border-bottom: 1px black solid;
}
</style>