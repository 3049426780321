<template>
  <v-app>
    <v-layout row justify-center>
      <v-dialog v-model="$store.state.modalLogin" max-width="500px">
        <v-card>
          <v-card-title>
            <div class="text-center col-md-12 col-lg-12 col-sm-12 col-xs-12">
              <img
                src="@/assets/images/logo-CentralTickets-black.png"
                style="max-width: 150px; width: 150px"
              />
            </div>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <form @submit.prevent="doLogin" autocomplete="off">
                <v-layout wrap>
                  <v-flex xs12 sm12 md12 lg12>
                    <v-text-field
                      label="Nombre de usuario o correo electronico"
                      required
                      v-model="user.email"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md12 lg12>
                    <v-text-field
                      label="contraseña"
                      type="password"
                      required
                      v-model="user.password"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md12 lg12 class="text-right">
                    <a @click.prevent="goPasswordReset" href="#" style="color: rgba(0,0,0,.57) !important"
                      >Olvide mi contraseña</a
                    >
                  </v-flex>
                  <v-flex xs12 sm12 md12 lg12 class="text-center">
                    <v-btn color="primary" type="submit">Iniciar Sesión</v-btn>
                  </v-flex>
                </v-layout>
              </form>
            </v-container>
          </v-card-text>
          <hr />
          <div class="text-center">
            <h5>¿Aun no tienes cuenta de usuario?</h5>
            <v-btn color="success" @click="showFormRegister"
              >REGISTRARME AHORA</v-btn
            >
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary darken-1" @click="hideModalLogin"
              >Cerrar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-app>
</template>
<script>
export default {
  created() {},
  data() {
    return {
      user: {
        email: null,
        password: null,
      },
    };
  },
  methods: {
    doLogin() {
      this.$store.dispatch("doLogin", this.user);
    },
    showFormRegister() {
      this.$store.state.modalLogin = false;
      this.$store.state.modalRegister = true;
    },
    goPasswordReset() {
      this.$store.state.modalLogin = false;
      this.$router.push("/passwordReset");
    },
    hideModalLogin() {
      this.$store.commit("showModalLogin", false);
    },
  },
  computed: {
    modalLogin() {
      return this.$store.getters["modalLogin"];
    },
  },
};
</script>
<style scoped>
.btn-facebook-login {
  background-color: #3b5998 !important;
  border-color: #3b5998 !important;
}

.btn-google-plus-login {
  background-color: #dd4b39 !important;
  border-color: #dd4b39 !important;
}
</style>