<template>
  <v-app>
    <v-container>
      <h3>Misión</h3>
      <div class="row row-clearfix" style="background: #ƒff">
        <div class="col-md col-lg col-sm">
          <p>
            Ser la mejor y más importante experiencia en servicios de recaudo
            financiero, boletería, control de acceso y todo lo relacionado con
            eventos y espectáculos a nivel nacional, a través de la optimización
            constante de la tecnología y del desarrollo, así como el
            mejoramiento de productos, apoyada en la calidad humana de nuestro
            equipo, generando satisfacción a nuestros clientes y otorgando a
            nuestros consumidores un acceso ágil, seguro y cómodo a su evento,
            creando a través de sus experiencias una importante fiscalización
            que beneficie a todos nuestros clientes.
          </p>
        </div>
      </div>
    </v-container>

    <v-container>
      <h3>Visión</h3>
      <div class="row row-clearfix">
        <div class="col-md col-lg col-sm">
          <p>
            Ser la empresa con el mayor portafolio de productos de
            identificación, control y comercialización de eventos, apoyados en
            tecnología de punta, con altos niveles de calidad que nos permitan
            satisfacer las necesidades existentes en el mercado, ofreciendo los
            mejores tiempos de respuesta a un precio justo.
          </p>
        </div>
      </div>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: "mision",
};
</script>