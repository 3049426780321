<template>
  <v-app>
    <v-container>
      <v-layout row wrap>
        <v-flex lg12 md12 sm12 xs12>
          <div class="mb-3 mt-3">
            <h2>Historial de ventas</h2>
          </div>
          <div>
            <span
              ><router-link
                style="color: #1976d2 !important"
                :to="{ path: '/mis-eventos' }"
                title="Ver todos mis eventos"
                ><u>Volver a Mis Eventos</u></router-link
              ></span
            ><span> > </span><span>Historial de ventas</span>
          </div>
          <div class="mt-3 mb-4"></div>
        </v-flex>
      </v-layout>
      <v-divider></v-divider>
      <v-layout row wrap>
        <v-flex lg3 md3 sm6 xs12 mb-3>
          <v-dialog
            ref="dialogDesde"
            v-model="modalDesde"
            :return-value.sync="desde"
            width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="desde"
                label="Desde"
                prepend-icon="event"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="desde"
              scrollable
              locale="es-ES"
              :max="hoy"
            >
              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click="modalDesde = false"
                >Cancelar</v-btn
              >
              <v-btn flat color="primary" @click="$refs.dialogDesde.save(desde)"
                >OK</v-btn
              >
            </v-date-picker>
          </v-dialog>
        </v-flex>
        <v-flex lg3 md3 sm6 xs12 mb-3>
          <v-dialog
            ref="dialogHasta"
            v-model="modalHasta"
            :return-value.sync="hasta"
            width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="hasta"
                label="Hasta"
                prepend-icon="event"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="hasta"
              scrollable
              locale="es-ES"
              :max="hoy"
            >
              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click="modalHasta = false"
                >Cancelar</v-btn
              >
              <v-btn flat color="primary" @click="$refs.dialogHasta.save(hasta)"
                >OK</v-btn
              >
            </v-date-picker>
          </v-dialog>
        </v-flex>
        <v-flex lg3 md3 sm6 xs12 mb-3 v-if="show_list_eventos">
          <v-select
            v-model="evento_filter"
            item-value="id"
            item-text="nombre"
            :items="eventos_punto_venta"
            label="Seleccionar un evento"
            prepend-icon="pageview"
            required
          ></v-select>
        </v-flex>
        <v-flex lg3 md3 sm6 xs12 mb-3>
          <v-btn color="info" @click="searchHistorialVentasFisicas"
            ><v-icon>search</v-icon> BUSCAR</v-btn
          >
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex lg3 md3 sm6 xs12 mb-3>
          <v-text-field 
            label="N°  VENTA" 
            v-model="idventa"
          >
          </v-text-field>
        </v-flex>
        <v-flex lg3 md3 sm6 xs12 mb-3>
          <v-btn color="info" @click="searchFiltrarVentasFisicas"
            ><v-icon>search</v-icon>Filtrar</v-btn
          >
        </v-flex>
        <v-flex lg3 md3 sm6 xs12 mb-3 v-if="show_button_cancelar_filtrar">
          <v-btn color="info" @click="regresar"
            ><v-icon>delete</v-icon>Cancelar Filtro</v-btn
          >
        </v-flex>
      </v-layout>
      <v-layout row wrap v-if="show_button_cierre_diario">
        <v-flex lg4 md4 sm6 xs12 mb-3>
          <v-btn
            @click="modalCierreDiario = true"
            color="info"
            title="Ver el cierre diario"
            ><v-icon>history</v-icon> VER CIERRE DIARIO</v-btn
          >
        </v-flex>
        <v-flex lg4 md4 sm6 xs12>
          <export-excel
            style="width: fit-content"
            :data="exportVentas"
            :export-fields="json_fields"
            worksheet="Cierre diario"
            :name="desde + '/' + hasta + '.xls'"
            ><v-btn color="success"
              ><v-icon left>cloud_download</v-icon> EXPORT A EXCEL</v-btn
            ></export-excel
          >
        </v-flex>
      </v-layout>
      <v-divider></v-divider>
      <v-data-table
        :headers="headers"
        must-sort
        :items="facturas"
        hide-default-footer
        :itemsPerPage="facturas.length"
        :length="pages"
        :search="search"
        :total-items="totalItemCount"
        class="elevation-1 table-ventas"
        :loading="loading"
        :no-data-text="'No se han encontrado ventas...'"
      >
        <template v-slot:items="props">
          <td class="font-weight-bold">#{{ props.item.id }}</td>
          <td class="text-xs-center">
            {{ formatFechaHumans(props.item.fecha) }}<br /><small>{{
              props.item.fecha
            }}</small>
          </td>
          <td class="text-xs-center">{{ props.item.cliente }}</td>
          <td class="text-xs-center text-uppercase">
            {{ props.item.pago_parcial }}
          </td>
          <td class="text-xs-center text-uppercase">
            {{ props.item.forma_pago }}
          </td>
          <td class="text-xs-center text-uppercase">
            {{ props.item.code_voucher }}
          </td>
        </template>
        <template v-slot:item.total_venta="item">
          <td class="text-xs-center">
            {{ item.item.moneda.simbolo
            }}{{ formatoMoneda(getTotalVenta(item.item.detalle_vents, 0)) }} ({{
              item.item.moneda.codigo_moneda
            }})
          </td>
        </template>
        <template v-slot:item.total_pago="item">
          <td class="text-xs-center">
            {{ item.item.moneda.simbolo
            }}{{ formatoMoneda(getTotalAbonado(item.item.id)) }} ({{
              item.item.moneda.codigo_moneda
            }})
          </td>
        </template>
        <template v-slot:item.actions="item">
          <td>
            <v-icon
              title="Visualizar la factura"
              medium
              center
              @click="searchFactura(item.item.id)"
            >
              receipt_long
            </v-icon>
          </td>
        </template>
      </v-data-table>
      <div class="text-xs-center pt-2">
        <v-pagination v-model="pagination.page" :length="pages"></v-pagination>
      </div>
      <h2 v-if="facturasCierreDiario.length > 0" class="text-right">
        <v-icon large>attach_money</v-icon> TOTAL:<b>
          {{ facturasCierreDiario[0].moneda.simbolo
          }}{{ formatNumber(totalVentasCierreDiario.total) }}</b
        >
        ({{ facturasCierreDiario[0].codigo_moneda }})
      </h2>
      <v-dialog
        v-if="modalCierreDiario"
        v-model="modalCierreDiario"
        max-width="500px"
        persistent
      >
        <v-card>
          <div id="printFactura">
            <v-card-title>
              <div class="text-center col-md-12 col-lg-12 col-sm-12 col-xs-12">
                <img
                  src="@/assets/images/logo-CentralTickets.png"
                  style="max-width: 150px; width: 150px"
                />
              </div>
            </v-card-title>
            <v-container>
              <v-card-text>
                <h6 class="text-center"><b>CIERRE DIARIO</b></h6>
                <h6 class="text-center">
                  <b>{{ desde }} - {{ hasta }}</b>
                </h6>
                <h6 class="text-center">
                  <b>{{
                    eventos_punto_venta.filter(
                      (item) => item.id == evento_filter
                    )[0].nombre
                  }}</b>
                </h6>
                <br />
                <table class="table">
                  <thead>
                    <tr>
                      <th class="text-center">N° FACTURA</th>
                      <th class="text-center">TIPO PAGO</th>
                      <th class="text-center">FECHA</th>
                      <th class="text-center">TOTAL</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in facturasCierreDiario">
                      <td class="text-center font-weight-bold">
                        # {{ item.id }}
                      </td>
                      <td class="text-center">{{ item.pago_parcial }}</td>
                      <td class="text-center">{{ item.fecha }}</td>
                      <td class="text-center">
                        {{ item.moneda.simbolo
                        }}{{ formatNumber(item.total) }} ({{
                          item.codigo_moneda
                        }})
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <br />
                <hr />
                <div class="text-right">
                  <h5>
                    TOTAL EFECTIVO:<b>
                      {{ facturasCierreDiario[0].moneda.simbolo
                      }}{{
                        formatNumber(totalVentasCierreDiario.total_efectivo)
                      }}</b
                    >
                    ({{ facturasCierreDiario[0].codigo_moneda }})
                  </h5>
                  <h5>
                    TOTAL TARJETA:<b>
                      {{ facturasCierreDiario[0].moneda.simbolo
                      }}{{
                        formatNumber(totalVentasCierreDiario.total_tarjeta)
                      }}</b
                    >
                    ({{ facturasCierreDiario[0].codigo_moneda }})
                  </h5>
                  <h5>
                    TOTAL TARJETA DEBITO:<b>
                      {{ facturasCierreDiario[0].moneda.simbolo
                      }}{{
                        formatNumber(totalVentasCierreDiario.total_debito)
                      }}</b
                    >
                    ({{ facturasCierreDiario[0].codigo_moneda }})
                  </h5>
                  <h5>
                    TOTAL TARJETA CREDITO:<b>
                      {{ facturasCierreDiario[0].moneda.simbolo
                      }}{{
                        formatNumber(totalVentasCierreDiario.total_credito)
                      }}</b
                    >
                    ({{ facturasCierreDiario[0].codigo_moneda }})
                  </h5>
                  <hr />
                  <h4>
                    TOTAL:<b>
                      {{ facturasCierreDiario[0].moneda.simbolo
                      }}{{ formatNumber(totalVentasCierreDiario.total) }}</b
                    >
                    ({{ facturasCierreDiario[0].codigo_moneda }})
                  </h4>
                </div>
              </v-card-text>
            </v-container>
          </div>
          <hr />
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" @click="printFactura">IMPRIMIR</v-btn>
            <v-btn color="blue darken-1" flat @click="modalCierreDiario = false"
              >Cerrar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-app>
</template>
<script>
import moment from "moment";
moment.locale("es");

export default {
  data() {
    return {
      search: "",
      desde: null,
      hasta: null,
      idventa: null,
      modalDesde: false,
      modalHasta: false,
      evento_filter: null,
      show_list_eventos: false,
      show_button_cierre_diario: false,
      show_button_cancelar_filtrar: false,
      modalCierreDiario: false,
      headers: [
        {
          text: "N° VENTA",
          align: "left",
          sortable: false,
          value: "id",
        },
        { text: "FECHA", value: "fecha", align: "center", sortable: false },
        { text: "CLIENTE", value: "cliente", align: "center", sortable: false },
        {
          text: "TIPO DE PAGO",
          value: "pago_parcial",
          align: "center",
          sortable: false,
        },
        {
          text: "FORMA DE PAGO",
          value: "forma_pago",
          align: "center",
          sortable: false,
        },
        {
          text: "COD VOUCHER",
          value: "code_voucher",
          align: "center",
          sortable: false,
        },
        {
          text: "TOTAL VENTA",
          value: "total_venta",
          align: "center",
          sortable: false,
        },
        {
          text: "TOTAL PAGADO",
          value: "total_pago",
          align: "center",
          sortable: false,
        },
        { text: "ACCIONES", value: "actions", sortable: false },
      ],
      json_fields: {
        "N° VENTA": "id",
        FECHA: "fecha",
        CLIENTE: "cliente",
        "TIPO DE PAGO": "pago_parcial",
        "FORMA DE PAGO": "forma_pago",
        "COD VOUCHER": "code_voucher",
        "TOTAL PAGADO": "total",
      },
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
    };
  },
  async beforeCreate() {
    document.title = "Historial de ventas - CentralTickets";
    await this.$store.dispatch("carrito/historialVentasFisicas", {
      page: 1,
      desde: null,
      hasta: null,
      id_evento: null,
    });
    var self = this;
    setTimeout(function () {
      self.show_list_eventos = true;
    }, 250);
  },
  watch: {
    pagination: {
      async handler() {
        if(this.facturas.length > 1){
          this.$store.dispatch("carrito/historialVentasFisicas", {
            page: this.pagination.page,
            desde: this.desde,
            hasta: this.hasta,
            id_evento: this.evento_filter,
          });
        }
        
      },
      deep: true,
    },
  },
  computed: {
    usuario() {
      return this.$store.getters["user/dataUserLogged"];
    },
    facturas() {
      return this.$store.getters["carrito/facturas"];
    },
    factura() {
      return this.$store.getters["carrito/factura"];
    },
    pagination() {
      return this.$store.getters["carrito/paginationFacturas"];
    },
    pages() {
      return this.$store.getters["carrito/pagesFacturas"];
    },
    totalItemCount() {
      return this.$store.getters["carrito/totalItemCount"];
    },
    loading() {
      return this.$store.getters["carrito/loading"];
    },
    eventos_punto_venta() {
      return this.$store.getters["carrito/eventosPuntoVenta"];
    },
    facturasCierreDiario() {
      return this.$store.getters["carrito/facturasCierreDiario"];
    },
    totalVentasCierreDiario() {
      return this.$store.getters["carrito/totalVentasCierreDiario"];
    },
    exportVentas() {
      return this.$store.getters["carrito/exportVentas"];
    },
    hoy() {
      return moment().format("YYYY-MM-DD");
    },
    primer_fecha_venta() {
      return moment(
        this.$store.getters["carrito/primer_fecha_venta_pdv"]
      ).format("YYYY-MM-DD");
    },
  },
  methods: {
    formatNumber(number) {
      return new Intl.NumberFormat("es-CO", {
        minimumFractionDigits: 0,
      }).format(number);
    },
    printFactura() {
      // Pass the element id here
      this.$htmlToPaper("printFactura");
    },
    formatoMoneda(valor) {
      if (valor != null && valor != "") {
        return new Intl.NumberFormat("es-CO").format(valor);
      } else {
        return "";
      }
    },
    formatFechaHumans(fecha) {
      return moment(fecha).fromNow();
    },
    searchHistorialVentasFisicas() {
      if (
        this.desde != null &&
        this.hasta != null &&
        this.evento_filter != null
      ) {
        this.$store.dispatch("carrito/historialVentasFisicas", {
          page: 1,
          desde: this.desde,
          hasta: this.hasta,
          id_evento: this.evento_filter,
        });
        this.show_button_cancelar_filtrar = false
        this.show_button_cierre_diario = true;
      } else {
        swal(
          "Error!",
          "Completa los campos para realizar la busqueda!",
          "error"
        );
      }
    },
    searchFiltrarVentasFisicas() {
      if (
        this.idventa != null
      ) {
        this.$store.dispatch("carrito/filtrarVentasFisicas", {
          page: 1,
          id_venta: this.idventa
        });
        this.show_button_cancelar_filtrar = true
        this.idventa = null
      } else {
        swal(
          "Error!",
          "Completa los campos para realizar la busqueda!",
          "error"
        );
      }
    },
    searchFactura(id) {
      this.$store.dispatch("carrito/searchFactura", id);
    },
    getTotalVenta(detalle_vents, abonado) {
      var totalVenta = 0;
      for (var i = 0; i < detalle_vents.length; i++) {
        totalVenta +=
          detalle_vents[i].precio_servicio +
          detalle_vents[i].precio_venta +
          (detalle_vents[i].precio_servicio + detalle_vents[i].precio_venta) *
            detalle_vents[i].impuesto;
      }
      return totalVenta - abonado;
    },
    getTotalAbonado(id) {
      var abonos = this.facturas.filter((item) => item.id == id)[0];
      var totalAbonado = abonos.total;

      for (var i = 0; i < abonos.abonos_venta_parcial.length; i++) {
        totalAbonado +=
          abonos.abonos_venta_parcial[i].monto_efectivo +
          abonos.abonos_venta_parcial[i].monto_tarjeta;
      }
      return totalAbonado;
    },
    regresar(){
        this.$router.go(0)
    }
  },
};
</script>
<style>
.elevation-1.table-ventas table thead {
  background-color: #cacaca;
}
.elevation-1.table-ventas table thead th {
  color: black;
  font-weight: bold;
}
.elevation-1.table-ventas table tbody td {
  color: black;
  font-size: 16px;
}
</style>