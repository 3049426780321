import {store} from './store/';
import {router} from './main.js';
import Home from './components/Home.vue';
import Login from './components/Login.vue';
import ActiveAccount from './components/ActiveAccount.vue';
import PasswordReset from './components/PasswordReset.vue';
import PasswordResetToken from './components/PasswordResetToken.vue';
import Evento from './components/Evento.vue';
import SolicitudEvento from './components/SolicitudEvento.vue';
import SolicitudEventoDetalle from './components/SolicitudEventoDetalle.vue';
import RouterMisEventos from './components/RouterMisEventos.vue';
import MisEventos from './components/MisEventos.vue';
import EventosAprobacion from './components/EventosAprobacion.vue';
import DetalleEvento from './components/DetalleEvento.vue';
import PuntosVenta from './components/PuntosVenta';
import Vision from './components/Vision';
import Mision from './components/Mision';
import Comunicados from './components/Comunicados';
import TerminosCondiciones from './components/TerminosCondiciones';
import PQR from './components/PQR';
import PoliticaTratamiento from './components/PoliticaTratamiento';
import AprobacionEvento from './components/AprobacionEvento.vue';
import PaymentConfirm from './components/utils/PaymentConfirm.vue';
import VentasEvento from './components/VentasEvento.vue';
import HistorialVentasEvento from './components/HistorialVentasEvento.vue';
import InformeVentasJefePuntoVenta from './components/InformeVentasJefePuntoVenta.vue';
import PuntosVentaEvento from './components/PuntosVentaEvento.vue';
import Perfil from './components/Perfil.vue';
import EventoStream from './components/EventoStream.vue';
import Contacto from './components/Contacto.vue';
import MapeoEventoJefePuntoVenta from './components/MapeoEventoJefePuntoVenta.vue';
import PagosParciales from './components/PagosParciales.vue';
import InformeVentasEmpresario from './components/InformeVentasEmpresario.vue';
import PuntosVentaJefePunto from './components/PuntosVentaJefePunto.vue';
import EventosPuntoVenta from './components/EventosPuntoVenta.vue';
import CortesiasEventoJefePuntoVenta from './components/CortesiasEventoJefePuntoVenta.vue';
import HistorialCortesiasEventos from './components/HistorialCortesiasEventos.vue';
import Page404 from './components/Templates/Page404.vue';
import Checkout from './components/Templates/Checkout/Checkout.vue'
import ResumenPayment from './components/Templates/Checkout/ResumenPayment.vue'
import CheckoutSummaryPayment from './components/Templates/Checkout/CheckoutSummaryPayment.vue'
import PreguntasFrecuentes from './components/Templates/PreguntasFrecuentes/PreguntasFrecuentes.vue'

export const routes = [
  {
    path: '/pruebas-desarrollo-ticket',
    component: ()=> import('@/components/PruebaDesarrollo.vue') 
  },
  {
    path: '*',
    component: Page404,
    meta: { 
        requiresAuth: false
      },
  },
	{
		path: '/',
		component: Home,
		meta: { 
        requiresAuth: false
      },
      
	},
  {
    path: '/eventos/:evento',
    component: Evento,
      name: 'evento',
      meta: { 
        requiresAuth: false
      }
  },
  {
    path: '/evento/stream',
    component: EventoStream,
      name: 'eventoStream',
      meta: { 
        requiresAuth: false
      }
  },
  {
    path: '/perfil',
    component: Perfil, 
    name: 'perfil',
    meta: {
      requiresAuth: true,
    }
  },
    {
    path: '/mapeo-evento/:evento',
    component: MapeoEventoJefePuntoVenta, 
    name: 'MapeoEventoJefePuntoVenta',
    meta: {
      requiresAuth: true,
      requiresJefedePuntosdeVenta: true,
      /*requiresEmpresario: true,
      requiresFinanzas: true,
      requiresAnalista: true,*/
    }
  },
  {
    path: '/jefe-punto/cortesias-evento/:evento',
    component: CortesiasEventoJefePuntoVenta, 
    name: 'CortesiasEventoJefePuntoVenta',
    meta: {
      requiresAuth: true,
      requiresJefedePuntosdeVenta: true,
      /*requiresEmpresario: true,
      requiresFinanzas: true,
      requiresAnalista: true,*/
    }
  },
  {
    path: '/informe-empresario/:evento',
    component: InformeVentasEmpresario, 
    name: 'InformeVentasEmpresario',
    meta: {
      requiresAuth: true,
      /*requiresEmpresario: true,
      requiresEmpresario: true,
      requiresFinanzas: true,
      requiresAnalista: true,*/
    }
  },
  {
    path: '/ventas/:evento',
    component: VentasEvento, 
    name: 'ventasEvento',
    meta: {
      requiresAuth: true,
      requiresPuntodeVenta: true,
      /*requiresEmpresario: true,
      requiresFinanzas: true,
      requiresAnalista: true,*/
    }
  },
  {
    path: '/pagos-parciales',
    component: PagosParciales, 
    name: 'pagosParciales',
    meta: {
      requiresAuth: true,
      requiresPuntodeVenta: true,
      /*requiresEmpresario: true,
      requiresFinanzas: true,
      requiresAnalista: true,*/
    }
  },
    {
    path: '/historial-ventas',
    component: HistorialVentasEvento, 
    name: 'historialVentasEvento',
    meta: {
      requiresAuth: true,
      requiresPuntodeVenta: true,
      /*requiresEmpresario: true,
      requiresFinanzas: true,
      requiresAnalista: true,*/
    }
  },
    {
    path: '/jefe-punto/puntos-venta',
    component: PuntosVentaJefePunto, 
    name: 'PuntosVentaJefePunto',
    meta: {
      requiresAuth: true,
      requiresJefedePuntosdeVenta: true,
      /*requiresEmpresario: true,
      requiresFinanzas: true,
      requiresAnalista: true,*/
    }
  },
  {
    path: '/jefe-punto/punto-venta/:id/eventos',
    component: EventosPuntoVenta, 
    name: 'eventosPuntoVenta',
    meta: {
      requiresAuth: true,
      requiresJefedePuntosdeVenta: true,
      /*requiresEmpresario: true,
      requiresFinanzas: true,
      requiresAnalista: true,*/
    }
  },
    {
    path: '/informes/cortesias',
    component: HistorialCortesiasEventos, 
    name: 'HistorialCortesiasEventos',
    meta: {
      requiresAuth: true,
      requiresJefedePuntosdeVenta: true,
      /*requiresEmpresario: true,
      requiresFinanzas: true,
      requiresAnalista: true,*/
    }
  },
  {
    path: '/puntos-venta/informe/ventas',
    component: InformeVentasJefePuntoVenta, 
    name: 'InformeVentasJefePuntoVenta',
    meta: {
      requiresAuth: true,
      requiresJefedePuntosdeVenta: true,
      /*requiresEmpresario: true,
      requiresFinanzas: true,
      requiresAnalista: true,*/
    }
  },
  { 
    path: '/mis-eventos',
    component: RouterMisEventos,
    meta: { 
        requiresAuth: false
      },
      children: [
          {
            path: '',
            component: MisEventos, 
            name: 'misEventos',
            meta: {
              requiresEmpresario: true,
              requiresComercial: true,
              requiresFinanzas: true,
              requiresAnalista: true,
              requiresPuntodeVenta: true,
              requiresJefedePuntosdeVenta: true,
            }
          },
          {
            path: 'solicitud',
            component: SolicitudEvento,
            name: 'solicitudEvento',
            meta: {
              requiresEmpresario: true,
              requiresComercial: true,
              requiresFinanzas: true,
              requiresAnalista: true,
            }
          },
          {
            path: 'solicitud-evento/:id',
            component: SolicitudEventoDetalle,
            name: 'solicitudEventoDetalle',
            meta: {
              requiresEmpresario: true,
              requiresComercial: true,
              requiresFinanzas: true,
              requiresAnalista: true,
            }
          },
          {
            path: ':id/detalles',
            component: DetalleEvento,
            name: 'detalleEvento',
            meta: {
              requiresEmpresario: true,
              requiresComercial: true,
              requiresFinanzas: true,
              requiresAnalista: true,
            }
          },
    ]
  },
  { 
    path: '/eventos-aprobacion',
    component: RouterMisEventos,
    meta: { 
        requiresAuth: false
      },
      children: [
          {
            path: '',
            component: EventosAprobacion,
            name: 'eventosAprobacion',
            meta: {
              requiresJefeComercial: true,
            }
          },
          {
            path: 'aprobacion/:id',
            component: AprobacionEvento,
            name: 'aprobacionEvento',
            meta: {
              requiresJefeComercial: true,
            }
          },
    ]
  },
  // {
  //   path: '/puntosVenta',
  //   component: PuntosVenta,
  //     name: 'puntosVenta',
  //     meta: { 
  //       requiresAuth: false
  //     }
  // },
  {
    path: '/mision',
    component: Mision,
      name: 'mision',
      meta: { 
        requiresAuth: false
      }
  },
  {
    path: '/vision',
    component: Vision,
      name: 'vision',
      meta: { 
        requiresAuth: false
      }
  },
  {
    path: '/comunicados',
    component: Comunicados,
      name: 'comunicados',
      meta: { 
        requiresAuth: false
      }
  },
  {
    path: '/terminoscondiciones',
    component: TerminosCondiciones,
      name: 'terminoscondiciones',
      meta: { 
        requiresAuth: false
      }
  },
  {
    path: '/pqr',
    component: PQR,
      name: 'pqr',
      meta: { 
        requiresAuth: false
      }
  },
  {
    path: '/politicatratamiento',
    component: PoliticaTratamiento,
      name: 'politicatratamiento',
      meta: { 
        requiresAuth: false
      }
  },
	{
    component: ActiveAccount,
    path: '/activeAccount/:token',
    name: 'activeAccount',
    meta: { 
        requiresAuth: false
      },
  },
  {
    path: '/passwordReset',
    name: 'passwordReset',
    component: PasswordReset,
    meta: { 
        requiresAuth: false
      },
  },
  {
    component: PasswordResetToken,
    path: '/passwordReset/:token',
    name: 'passwordResetToken',
    meta: { 
        requiresAuth: false
      },
  },
  {
    component: PaymentConfirm,
    path: '/paymentconfirm',
    name: 'paymentconfirm',
    meta: {
      requiresAuth: false
    }
  },
  {
    component: Contacto,
    path: '/contacto',
    name: 'contacto',
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/checkout',
    component: Checkout, 
    name: 'checkout',
  },
  {
    path: '/pedido-detalle',
    component: ResumenPayment, 
    name: 'resumenPayment1',
  },
  {
    path: '/preguntas-frecuentes',
    component: PreguntasFrecuentes, 
    name: 'PreguntasFrecuentes',
  },
  {
    path: '/resumen-transaccion',
    component: CheckoutSummaryPayment, 
    name: 'resumenPayment',
  },
];