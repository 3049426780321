<template>
	<div>
		<ul class="list-group pl-0" v-bind:class="horizontal ? 'horizontal' : ''">
			<li @click="filterEventos(tipo.id)" v-bind:class="select === tipo.id ? 'tipo-evento-select' : ''"  class="list-group-item item" :key="index" v-for="(tipo, index) in tiposEventosDestacados" :title="tipo.nombre">
				{{ tipo.nombre | mayusculas }}
			</li>
		</ul>
	</div>
</template>
<script>
	
	export default{
		 props: [
		 	'horizontal',
		 	'select',
		 	'tiposEventosFilter',
		 	'redirect',
		 ],
		 beforeCreate(){
           this.$store.dispatch('tipoevento/listTipoEvento', 1);
        },
		data(){
			return{
		
			}
		},
		computed:{
			tiposEventos(){
        		return this.$store.getters['tipoevento/listTipoEvento'];
        	},
        	tiposEventosDestacados(){
        		return this.tiposEventos.slice(0,6);
        	},


		},
		methods:{
			filterEventos(id_tipo){
				if(this.redirect){
					this.$emit('update:tiposEventosFilter', [id_tipo])
					this.$store.dispatch('evento/filterEventos',{'page':1,'precio_inicio': 10000,'precio_fin': 15000000,'artistas': [],'tiposEvento': [id_tipo],'fecha_inicio': null,'fecha_fin': null});
					$("html, body").animate({ scrollTop: 900 }, 1000);
				}
			},
		},
	};
</script>
<style scoped>
.list-group-item{
    border-radius: 1px !important;
    font-size: 18px;
     color: white;
    cursor: pointer;
    background-color: #504300 !important;
    border: 1px #7b690b solid !important;
}
.item:hover{
	color: #e6e6e6;
    cursor: pointer;
    background-color: #796409 !important;
    text-shadow: 0px 0px 1px white;
}
.tipo-evento-select{
	color: #e6e6e6 !important;
    cursor: pointer !important;
    background-color: #796409 !important;
    text-shadow: 0px 0px 1px white !important;
}
.horizontal{
	display: -webkit-box !important;
	    overflow: auto;
}
.horizontal .list-group-item{
	display: table-cell !important;
}
</style>