<template>
  <v-app>
    <v-container>
      <h1 class="mb-3 mt-2">MIS EVENTOS</h1>
      <v-layout row wrap>
        <v-flex
          lg12
          md12
          sm12
          xs12
          class="mb-3"
          v-if="
            usuario.id_rol == 6 ||
            usuario.id_rol == 3 ||
            usuario.id_rol == 8 ||
            usuario.id_rol == 9 ||
            usuario.id_rol == 4
          "
        >
          <v-btn color="success" @click="$router.push('/mis-eventos/solicitud')"
            ><i class="material-icons">add</i> Nueva solicitud de evento</v-btn
          >
        </v-flex>
        <v-flex lg6 md6 sm6 xs12 v-if="usuario.id_rol == 5">
          <v-btn
            title="Ver historial de ventas"
            @click="$router.push({ name: 'historialVentasEvento' })"
            color="info"
            ><i class="material-icons">history</i> HISTORIAL DE VENTAS</v-btn
          >
        </v-flex>
        <v-flex lg6 md6 sm6 xs12 v-if="usuario.id_rol == 5">
          <v-btn
            title="Administrar pagos parciales"
            @click="$router.push({ name: 'pagosParciales' })"
            color="primary"
            ><i class="material-icons">update</i> PAGOS PARCIALES</v-btn
          >
        </v-flex>
        <v-flex lg4 md4 sm4 xs12 mb-2 v-if="usuario.id_rol == 7">
          <v-btn
            title="Ver informe de ventas"
            @click="$router.push({ name: 'InformeVentasJefePuntoVenta' })"
            color="info"
            ><i class="material-icons">assessment</i> &nbsp;INFORME DE VENTAS
            GENERAL</v-btn
          >
        </v-flex>
        <v-flex l4 m4 sm4 xs12 mb-2 v-if="usuario.id_rol == 7">
          <v-btn
            title="Ver los puntos de venta"
            @click="$router.push({ name: 'PuntosVentaJefePunto' })"
            color="info"
            ><i class="material-icons">store_mall_directory</i> PUNTOS DE
            VENTA</v-btn
          >
        </v-flex>
        <v-flex l4 m4 sm4 xs12 mb-2 v-if="usuario.id_rol == 7">
          <v-btn
            title="Informe de cortesias"
            @click="$router.push({ name: 'HistorialCortesiasEventos' })"
            color="info"
            ><i class="material-icons">card_giftcard</i> INFORME DE
            CORTESIAS</v-btn
          >
        </v-flex>
      </v-layout>
      <v-divider></v-divider>
      <!-- <div class="cont-evento mb-3 bg-white" title="Ver detalle de evento" @click="detalleEvento(1)">
				<v-layout row wrap>
					<v-flex lg4 md4 sm12 xs12>
						<div class="cont-img">
							<div class="estado-evento bg-estado-venta">
								En Venta
							</div>
						</div>
					</v-flex>
					<v-flex lg8 md8 sm12 xs12>
						<div class="cont-desc-evento p-3">
							<v-layout row wrap>
								<v-flex lg6 md6 sm12 xs12 class="text-left">
									<h3 class="mb-3">Esto es vida de silvestre dangon - Armenia 2018</h3>
									<p class="mb-0">Fecha: 19/10/2018</p>
									<p class="mb-0">Apertura de puertas: 6pm</p>
									<p class="mb-0">Horario inicio: 8pm</p>
									<p class="mb-0">Horario de salida: 3am</p>
									<p class="mb-0">Edad minima: 18 años</p>
									<p class="mb-0">Ciudad: Armenia</p>
									<p class="mb-0">Lugar: Coliseo del cafe</p>
								</v-flex>
								<v-flex lg6 md6 sm12 xs12 class="text-center mt-3">
									<v-layout row wrap>
										<v-flex lg4 md4 sm4 xs4>
											<h1>25</h1>
											<small>DIAS</small>
										</v-flex>
										<v-flex lg4 md4 sm4 xs4>
											<h1>20</h1>
											<small>HORAS</small>
										</v-flex>
										<v-flex lg4 md4 sm4 xs4>
											<h1>40</h1>
											<small>MIN</small>
										</v-flex>
									</v-layout>
									<v-layout row wrap>
										<v-flex>
									<v-chip color="primary" text-color="white" label disabled class="p-2 mt-3"><h2>45.635</h2>&nbsp;<small> Boletas vendidas</small></v-chip>
									</v-flex>
									<v-flex>
									<v-chip color="warning" text-color="white" label disabled class="p-2 mt-3"><h2>5</h2>&nbsp;<small> Notificaciones nuevas</small></v-chip>
								</v-flex>
								</v-layout>
								</v-flex>
							</v-layout>
						</div>
					</v-flex>
				</v-layout>
			</div> -->
      <div :key="index" v-for="(evento, index) in eventos" class="cont-evento mb-3 bg-white" style="aspect-ratio: auto;">
        <v-layout row wrap>
          <v-flex lg4 md4 sm12 xs12>
            <div
              title="Ver detalle de evento"
              @click="detalleEvento(evento.id, evento.status)"
              :key="index"
              v-for="(imagen, index) in evento.imagens"
              v-if="imagen.nombre.includes('banner')"
              class="cont-img"
              :style="'background: url(' + imagen.url + ');'"
            >
              <div
                v-if="
                  usuario.id_rol == 6 ||
                  usuario.id_rol == 3 ||
                  usuario.id_rol == 8 ||
                  usuario.id_rol == 9 ||
                  usuario.id_rol == 4
                "
              >
                <div
                  v-if="evento.status == 0"
                  class="estado-evento bg-estado-solicitud"
                >
                  solicitud inactiva
                </div>
                <div
                  v-if="evento.status == 1"
                  class="estado-evento bg-estado-solicitud"
                >
                  solicitud activa
                </div>
                <div
                  v-if="evento.status == 2"
                  class="estado-evento bg-estado-solicitud"
                >
                  solicitud cancelada
                </div>
                <div
                  v-if="evento.status == 3"
                  class="estado-evento bg-estado-solicitud"
                >
                  solicitud aplazada
                </div>
                <div
                  v-if="evento.status == 4"
                  class="estado-evento bg-estado-solicitud"
                >
                  solicitud pendiente aprobacion
                </div>
                <div
                  v-if="evento.status == 5"
                  class="estado-evento bg-estado-solicitud"
                >
                  solicitud finalizada
                </div>
                <div
                  v-if="evento.status == 6"
                  class="estado-evento bg-estado-solicitud"
                >
                  solicitud pendiente cambios
                </div>
              </div>
            </div>
          </v-flex>
          <v-flex lg8 md8 sm12 xs12>
            <div class="cont-desc-evento p-3">
              <v-layout row wrap>
                <v-flex lg6 md6 sm12 xs12 class="text-left">
                  <h3
                    class="mb-3 title-evento12"
                    title="Ver detalle de evento"
                    @click="detalleEvento(evento.id, evento.status)"
                  >
                    {{ evento.nombre }}
                  </h3>
                  <p class="mb-0">
                    Fecha: {{ dateTimeToDate(evento.fecha_evento) }}
                  </p>
                  <p class="mb-0">
                    Apertura de puertas: {{ formatTime(evento.hora_apertura) }}
                  </p>
                  <p class="mb-0">
                    Horario inicio: {{ formatTime(evento.hora_inicio) }}
                  </p>
                  <p class="mb-0">
                    Horario de salida:
                    {{ formatTime(evento.hora_finalizacion) }}
                  </p>
                  <!-- <p class="mb-0">Edad minima: 18 años</p> -->
                  <!-- <p class="mb-0">Ciudad: {{ evento.id_ciudad }}</p> -->
                  <p class="mb-0">
                    Lugar: {{ getAuditorio(evento.id_auditorio) }}
                  </p>
                </v-flex>
                <v-flex lg6 md6 sm12 xs12 class="text-center mt-3">
                  <div class="text-right pt-5">
                    <!-- <h6 class="mb-3">Solicitud enviada el 15 de noviembre de 2018</h6> -->
                    <small
                      v-if="
                        evento.status == 4 &&
                        (usuario.id_rol == 6 ||
                          usuario.id_rol == 3 ||
                          usuario.id_rol == 8 ||
                          usuario.id_rol == 9 ||
                          usuario.id_rol == 4)
                      "
                      class="text-danger"
                      ><em>A la espera de respuesta</em></small
                    >
                  </div>
                </v-flex>
              </v-layout>
              <div v-if="usuario.id_rol == 5">
                <hr />
                <v-layout row wrap>
                  <v-flex lg4 md4 sm4 xs12 mb-2>
                    <v-btn
                      v-if="evento.status == 1"
                      :title="'Realizar venta a ' + evento.nombre"
                      @click="
                        $router.push({
                          name: 'ventasEvento',
                          params: { evento: evento.id },
                        })
                      "
                      color="success"
                      ><i class="material-icons">shopping_cart</i> NUEVA
                      VENTA</v-btn
                    >
                    <small v-else class="text-danger"
                      ><v-icon small color="red">remove_shopping_cart</v-icon
                      ><em> Aun no se pueden realizar ventas.</em></small
                    >
                  </v-flex>
                </v-layout>
              </div>
              <div v-if="usuario.id_rol == 7">
                <hr />
                <v-layout row wrap>
                  <v-flex lg4 md4 sm6 xs12 mb-2>
                    <v-btn
                      :title="'Informe de ventas de ' + evento.nombre"
                      @click="
                        $router.push({
                          name: 'InformeVentasJefePuntoVenta',
                          params: { evento: evento.id },
                        })
                      "
                      color="default"
                      ><i class="material-icons">assessment</i> INFORME DE
                      VENTAS</v-btn
                    >
                  </v-flex>
                  <v-flex lg4 md4 sm6 xs12 mb-2>
                    <v-btn
                      :title="'Mapa de puestos de ' + evento.nombre"
                      @click="
                        $router.push({
                          name: 'MapeoEventoJefePuntoVenta',
                          params: { evento: evento.id },
                        })
                      "
                      color="default"
                      ><i class="material-icons">event_seat</i> MAPA DE
                      PUESTOS</v-btn
                    >
                  </v-flex>
                  <v-flex lg4 md4 sm6 xs12 mb-2>
                    <v-btn
                      :title="'Crear cortesias para ' + evento.nombre"
                      @click="
                        $router.push({
                          name: 'CortesiasEventoJefePuntoVenta',
                          params: { evento: evento.id },
                        })
                      "
                      color="default"
                      ><i class="material-icons">card_giftcard</i> CREAR
                      CORTESIAS</v-btn
                    >
                  </v-flex>
                </v-layout>
              </div>
              <div v-if="usuario.id_rol == 3">
                <hr />
                <v-layout row wrap>
                  <v-flex lg4 md4 sm6 xs12 mb-2>
                    <v-btn
                      :title="'Informe de ventas de ' + evento.nombre"
                      @click="
                        $router.push({
                          name: 'InformeVentasEmpresario',
                          params: { evento: evento.id },
                        })
                      "
                      color="default"
                      ><i class="material-icons">assessment</i> INFORME DE
                      VENTAS</v-btn
                    >
                  </v-flex>
                </v-layout>
              </div>
            </div>
          </v-flex>
        </v-layout>
      </div>
    </v-container>
  </v-app>
</template>
<script>
import moment from "moment";

export default {
  data() {
    return {
      itemsFiltroEventos: [],
      itemsOrdenarEventos: [],
    };
  },
  async beforeCreate() {
    document.title = "Mis Eventos - CentralTickets";
    this.$store.dispatch("evento/listMisEventos");
    await this.$store.dispatch("auditorio/listAuditorios");
  },
  computed: {
    eventos() {
      return this.$store.getters["evento/listEventos"];
    },
    auditorios() {
      return this.$store.getters["auditorio/listAuditorios"];
    },
    usuario() {
      return this.$store.getters["user/dataUserLogged"];
    },
  },
  methods: {
    detalleEvento(id, status) {
      if (status == 6) {
        this.$router.push({ name: "solicitudEventoDetalle", params: { id } });
      } else {
        //this.$router.push({name: 'detalleEvento', params: {id}});
        this.$router.push({ name: "evento", params: { evento: id } });
      }
    },
    dateTimeToDate(datetime) {
      if (datetime != null && datetime != "") {
        return moment(datetime).format("YYYY/MM/DD");
      } else {
        return "";
      }
    },
    formatTime(time) {
      if (time != null && time != "") {
        return moment(time, "HH:mm:ss").format("h a");
      } else {
        return "";
      }
    },
    getAuditorio(id) {
      let filter_auditorio = this.auditorios.filter(
        (auditorio) => auditorio.id == id
      );
      if (filter_auditorio[0]) {
        return filter_auditorio[0].nombre;
      } else {
        return "";
      }
    },
  },
};
</script>
<style scoped>
.cont-evento {
  border: 1px #dedede solid;
  transition: all 0.5s ease;
}
.cont-evento:hover {
  transition: all 0.5s ease;
  box-shadow: 0px 0px 20px #cecece;
  border-color: #fafafa;
  cursor: pointer;
}
.cont-img {
  background-size: 100% 100% !important;
  background-repeat: no-repeat !important;
  height: 100%;
  width: 100%;
  /*    min-height: 200px;
    max-height: 100%;
    max-width: 100%;*/
}
.cont-img img {
  width: 100%;
  max-width: 100%;
}
.bg-estado-venta {
  background-color: #007bff;
}
.bg-estado-pendiente {
  background-color: #68bd6c;
}
.bg-estado-solicitud {
  background-color: #7fbf82;
}
.estado-evento {
  position: absolute;
  text-transform: uppercase;
  padding: 10px;
  color: white;
  margin-top: 10px;
}
.title-evento12 {
}
.title-evento12:hover {
  text-decoration: underline;
}
</style>