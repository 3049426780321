import axios from 'axios';
import { configApi } from '../../api/configApi';
import { router } from '../../main.js';
import { headers } from '../../api/headers';
import { store } from '../index';

export default {
  namespaced: true,
  state: {
  	count_palcos_individuales_reservados: 0,
    tipo_palco: null,
    imprimirBoletas: '',
    errorGetBoleta: false,
    boletas: [],
    loading: false,
    loadingPagosParciales: false,
    preciosBoletasCarrito: [],
    permitircupon: [],
    arrayPreventasSelect: [],
    localidadSelectCarrito: null,
    dateTimeSaleStart: null,
    dateTimeSaleEnd: null,
    busquedaUser: false,
    /// Migración datos de evento ///
    cantBoleta: [],
    cantBoletaTotal: 0,
    getpago:'',
    ////////////////////////////////
    userSale: {
      exists: false,
      nombre: null,
      apellidos: null,
      email: null,
      tipo_identificacion: 1,
      identificacion: null,
      direccion: null,
      ciudad: null,
      departamento: null,
      telefono: null,
    },
    facturas: [],
    paginationFacturas: {
      descending: true,
      page: 1,
      rowsPerPage: 0,
      sortBy: '',
      totalItems: 0,
      rowsPerPageItems: [1, 2, 4, 8, 16],
    },
    factura: null,
    eventosPuntoVenta: [],
    totalVentasCierreDiario: [],
    facturasCierreDiario: [],
    exportVentas: [],
    exportCortesias: [],
    pagosParciales: [],
    paginationPagosParciales: {
      descending: true,
      page: 1,
      rowsPerPage: 0,
      sortBy: '',
      totalItems: 0,
      rowsPerPageItems: [1, 2, 4, 8, 16],
    },
    pagesFacturas: 0,
    paginationCortesias: {
      descending: true,
      page: 1,
      rowsPerPage: 0,
      sortBy: '',
      totalItems: 0,
      rowsPerPageItems: [1, 2, 4, 8, 16],
    },
    pagesCortesias: 0,
    primer_fecha_venta_pdv: null,
  },
  getters: {
    arrayPreventasSelect(state){
      return state.arrayPreventasSelect
    },
    getpago: (state ) => {
      return  state.getpago;
    },
    countPalcos: (state) => {
      var count = 0;
      state.preciosBoletasCarrito.forEach((element) => {
        // console.log(element.tipoBoleta)
        state.tipo_palco = element.tipoBoleta

        if (element.tipoBoleta == 1) {
          count++
        }
      });
      return count;
    },
    countBoletas: (state) => {
      var count = 0;
      // console.log(state.preciosBoletasCarrito);
      state.preciosBoletasCarrito.forEach((element) => {
        if (element.tipoBoleta <= 0) {
          count++
        }
      });
      return count;
    },
    permitircupon: (state) => {
      return  state.permitircupon;
    },
    listBoletas: (state, getters, rootState) => {
      return state.boletas;
    },
    preciosBoletasCarrito: (state, getters, rootState) => {
      return state.preciosBoletasCarrito;
    },
    localidadSelectCarrito: (state, getters, rootState) => {
      return state.localidadSelectCarrito;
    },
    dateTimeSaleStart: (state, getters, rootState) => {
      return state.dateTimeSaleStart;
    },
    dateTimeSaleEnd: (state, getters, rootState) => {
      return state.dateTimeSaleEnd;
    },
    userSale: (state, getters, rootState) => {
      return state.userSale;
    },
    busquedaUser: (state, getters, rootState) => {
      return state.busquedaUser;
    },
    facturas: (state, getters, rootState) => {
      return state.facturas;
    },
    pagosParciales: (state, getters, rootState) => {
      return state.pagosParciales;
    },
    factura: (state, getters, rootState) => {
      return state.factura;
    },
    paginationFacturas(state, getters, rootState) {
      return state.paginationFacturas;
    },
    paginationCortesias(state, getters, rootState) {
      return state.paginationCortesias;
    },
    pagesFacturas(state, getters, rootState) {
      return state.pagesFacturas;
    },
    pagesCortesias(state, getters, rootState) {
      return state.pagesCortesias;
    },
    totalItemCount(state, getters, rootState) {
      return state.paginationFacturas.totalItems;
    },
    paginationPagosParciales(state, getters, rootState) {
      return state.paginationPagosParciales;
    },
    pagesPagosParciales(state, getters, rootState) {
      return state.pagesPagosParciales;
    },
    totalItemCountPagosParciales(state, getters, rootState) {
      return state.paginationPagosParciales.totalItems;
    },
    loading(state, getters, rootState) {
      return state.loading;
    },
    loadingPagosParciales(state, getters, rootState) {
      return state.loadingPagosParciales;
    },
    eventosPuntoVenta: (state, getters, rootState) => {
      return state.eventosPuntoVenta;
    },
    totalVentasCierreDiario: (state, getters, rootState) => {
      return state.totalVentasCierreDiario;
    },
    facturasCierreDiario: (state, getters, rootState) => {
      return state.facturasCierreDiario;
    },
    exportVentas: (state, getters, rootState) => {
      return state.exportVentas;
    },
    exportCortesias: (state, getters, rootState) => {
      return state.exportCortesias;
    },
    countPalcosIndividuales: (state, getters, rootState) => {
      return state.count_palcos_individuales_reservados;   
    },
    primer_fecha_venta_pdv: (state, getters, rootState) => {
      return state.primer_fecha_venta_pdv;
    }
  },
  mutations: {
    arrayPreventasSelect(state, array){
      state.arrayPreventasSelect = array;
    },
    getpago: (state,getpago) => {
      state.getpago = getpago.data;
    },
    boletas(state, boletas) {
      state.boletas.push(boletas);
    },
    boletas2(state, boletas) {
      state.boletas.splice(boletas, 1);
    },
    clear(state) {
      state.boletas = [];
    },
    permitircupon(state, cupon) { 
      state.datoscupon.push(cupon);
    },
    preciosBoletasCarrito(state, precios) {
      state.preciosBoletasCarrito.push(precios);
    },
    localidadSelectCarrito(state, localidad) {
      state.localidadSelectCarrito = localidad;
    },
    setNullPreciosBoletasCarrito(state) {
      state.preciosBoletasCarrito = [];
    },
    dateTimeSaleStart(state, data) {
      if (data) {
        state.dateTimeSaleStart = data;
      } else {
        state.dateTimeSaleStart = null;
      }
    },
    dateTimeSaleEnd(state, data) {
      if (data) {
        state.dateTimeSaleEnd = data;
      } else {
        state.dateTimeSaleEnd = null;
      }
    },
    facturas: (state, facturas) => {
      state.facturas = facturas.data;
      state.paginationFacturas.totalItems = facturas.total;
      state.paginationFacturas.page = facturas.current_page;
      state.paginationFacturas.rowsPerPage = facturas.per_page;
    },
    pagosParciales: (state, pagosParciales) => {
      state.pagosParciales = pagosParciales.data;
      state.paginationPagosParciales.totalItems = pagosParciales.total;
      state.paginationPagosParciales.page = pagosParciales.current_page;
      state.paginationPagosParciales.rowsPerPage = pagosParciales.per_page;
    },
    eventosPuntoVenta: (state, eventos) => {
      state.eventosPuntoVenta = eventos;
    },
    totalVentasCierreDiario: (state, total) => {
      state.totalVentasCierreDiario = total;
    },
    facturasCierreDiario: (state, facturas) => {
      state.facturasCierreDiario = facturas;
    },
    exportVentas: (state, exportVentas) => {
      state.exportVentas = exportVentas;
    },
    exportCortesias: (state, exportCortesias) => {
      state.exportCortesias = exportCortesias.data;
      state.paginationCortesias.totalItems = exportCortesias.total;
      state.paginationCortesias.page = exportCortesias.current_page;
      state.paginationCortesias.rowsPerPage = exportCortesias.per_page;
    },
    countPalcosIndividuales: (state, cant_individuales) => {
      state.count_palcos_individuales_reservados = cant_individuales;    
    },
    factura(state, data) {
      state.factura = data;
    },
    busquedaUser(state, status) {
      state.busquedaUser = status;
    },
    userSale(state, data) {
      if (data && data != null) {
        state.userSale.exists = true;
        state.userSale.nombre = data.nombre;
        state.userSale.apellidos = data.apellidos;
        state.userSale.email = data.email;
        state.userSale.tipo_identificacion = data.tipo_identificacion;
        //state.userSale.identificacion = data.identificacion;
        state.userSale.direccion = data.direccion;
        state.userSale.ciudad = data.ciudad;
        state.userSale.departamento = data.departamento;
        state.userSale.telefono = data.telefono;
      } else {
        state.userSale.exists = false;
        state.userSale.nombre = null;
        state.userSale.apellidos = null;
        state.userSale.email = null;
        state.userSale.tipo_identificacion = 1;
        //state.userSale.identificacion = null;
        state.userSale.direccion = null;
        state.userSale.ciudad = null;
        state.userSale.departamento = null;
        state.userSale.telefono = null;
      }

    },
    restartBoletaCarrito(state, payload) {
      for (var i = payload.monedasEvento.length - 1; i >= 0; i--) {
        for (var j = state.preciosBoletasCarrito.length - 1; j >= 0; j--) {
          if (state.preciosBoletasCarrito[j].codigo_moneda == payload.monedasEvento[i].codigo_moneda &&
            payload.localidad_id == state.preciosBoletasCarrito[j].localidad_id) {
            var indice = 0;
            if (indice < payload.monedasEvento.length) {
              state.preciosBoletasCarrito.splice(j, 1);
              return;
            }
            indice++;
          }
        }
      }
    },
    pagesFacturas(state) {
      if (state.paginationFacturas.rowsPerPage == null ||
        state.paginationFacturas.totalItems == null) {
        state.pagesFacturas = 0;
      }

      state.pagesFacturas = Math.ceil(state.paginationFacturas.totalItems / state.paginationFacturas.rowsPerPage);
    },
    pagesPagosParciales(state) {
      if (state.paginationPagosParciales.rowsPerPage == null ||
        state.paginationPagosParciales.totalItems == null) {
        state.pagesPagosParciales = 0;
      }

      state.pagesPagosParciales = Math.ceil(state.paginationPagosParciales.totalItems / state.paginationPagosParciales.rowsPerPage);
    },
    primer_fecha_venta_pdv(state, primer_fecha_venta_pdv) {
      state.primer_fecha_venta_pdv = primer_fecha_venta_pdv;
    },
    pagesCortesias(state) {
      if (state.paginationCortesias.rowsPerPage == null ||
        state.paginationCortesias.totalItems == null) {
        state.pagesCortesias = 0;
      }

      state.pagesCortesias = Math.ceil(state.paginationCortesias.totalItems / state.paginationCortesias.rowsPerPage);
    },
  },
  actions: {
    listBoletas({ state, getters, commit, rootState }, payload) {
      // id_evento
      // id_puesto
      // precio_venta
      // precio_servicio
      // impuesto
      // status
      // codigo_moneda
      return axios.put(`${configApi.apiUrl}boletaevento/${payload.id}`, payload, { headers: headers })
        .then(response => {
          commit('boletas', response.data.data);
          swal("Exito!", "Se reservo la boleta correctamente!", "success");
        })
        .catch(error => {
          swal("Error!", "Error al reservar la boleta!", "error");
          console.error(error);
        });
    },
    getpago({state,getters,commit,rootState}, article ){
      state.loading = true;
       store.dispatch('validateToken');
       axios.post(`${configApi.apiPagosUrl}kushki/ecuador/getCargos`,article,{headers: headers})
      .then(response => {
           swal("Info!",  response.data.message, "info");
           commit('getpago',response.data);
           commit('pages');
          this.$router.push('/perfil')
      })
      .catch(error => {
        swal("Error!", error.response.message, "error");
      });

    },
    boletas2({ state, getters, commit, rootState }, boletas) {
      commit('boletas2', boletas);
    },
    clear({ state, getters, commit, rootState }) {
      commit('clear');
    },
    permitircupon({ state, getters, commit, rootState }, cupon) {
      commit('permitircupon', cupon);
    },
    preciosBoletasCarrito({ state, getters, commit, rootState }, precios) {
      commit('preciosBoletasCarrito', precios);
    },
    localidadSelectCarrito({ state, getters, commit, rootState }, localidad) {
      commit('localidadSelectCarrito', localidad);
    },
    setNullPreciosBoletasCarrito({ state, getters, commit, rootState }) {
      commit('setNullPreciosBoletasCarrito');
    },
    setNullReservas({ state, getters, commit, rootState }, payload) {
      store.commit('changeLoader', true);
      axios.post(`${configApi.apiUrl}set_null_reservas`, payload, { headers: headers })
        .then(response => {
          commit('setNullPreciosBoletasCarrito');
          store.commit('changeLoader', false);
          swal("Exito!", "El carrito se vació correctamente!", "success");
        })
        .catch(error => {
          store.commit('changeLoader', false);
          swal("Error!", "Error al vaciar el carrito!", "error");
          //console.error(error);
        });

    },
    restartBoletaCarrito({ state, getters, commit, rootState }, payload) {
      commit('restartBoletaCarrito', payload);
    },
    dateTimeSaleStart({ state, getters, commit, rootState }, payload) {
      commit('dateTimeSaleStart', payload);
    },
    dateTimeSaleEnd({ state, getters, commit, rootState }, payload) {
      commit('dateTimeSaleEnd', payload);
    },

    limpiarUserSale({ state, getters, commit, rootState }, status) {
      commit('busquedaUser', status);
      commit('userSale', null);
      state.userSale.identificacion = null;
    },
    searchUserSale({ state, getters, commit, rootState }, identificacion) {
      store.commit('changeLoader', true);
      axios.post(`${configApi.apiUrl}search_user`, { identificacion }, { headers: headers })
        .then(response => {
          commit('userSale', response.data.data);
          commit('busquedaUser', true);
          store.commit('changeLoader', false);
        })
        .catch(error => {
          commit('userSale', null);
          commit('busquedaUser', true);
          store.commit('changeLoader', false);
          swal("Usuario no encontrado!", "No existe un usuario con esa identificacion. Debes registrarlo!", "info");
        });

    },
    newSaleFisica({ state, getters, commit, rootState }, payload) {
      store.commit('changeLoader', true);
      axios.post(`${configApi.apiUrl}venta_fisica`, { payload }, { headers: headers })
        .then(response => {
          commit('factura', response.data);
          commit('userSale', null);
          commit('limpiarUserSale', false);
          commit('setNullPreciosBoletasCarrito');
          commit('dateTimeSaleStart', null);
          commit('dateTimeSaleEnd', null);
          store.dispatch('evento/clearCantBoleta');
          swal("Exito!", "La venta se registro satisfactoriamente!", "success");
          store.commit('changeLoader', false);
          store.commit('showFactura', true);
        })
        .catch(error => {
          store.commit('changeLoader', false);
          if (error.response.status == 402) {
            swal("Error!", error.response.data.message, "error");
            store.dispatch("evento/clearCantBoleta");
            store.dispatch("carrito/setNullPreciosBoletasCarrito");
            store.dispatch("carrito/dateTimeSaleStart", null);
            store.dispatch("carrito/dateTimeSaleEnd", null);
          } else {
            var htmlErrors = ``;
            var obj = error.response.data.errors;
            for (const prop in obj) {
              htmlErrors += `${obj[prop][0]}\n`;
            }
            swal("Error!", htmlErrors, "error");
          }
        });

    },
    showFactura({ state, getters, commit, rootState }, option) {
      store.commit('showFactura', option);
    },
    historialVentasFisicas({ state, getters, commit, rootState }, payload) {
      state.loading = true;
      store.commit('changeLoader', true);
      axios.get(`${configApi.apiUrl}historial_ventas_fisica?page=${payload.page}&desde=${payload.desde}&hasta=${payload.hasta}&id_evento=${payload.id_evento}`, null, { headers: headers })
        .then(response => {
          commit('eventosPuntoVenta', response.data.data.eventos);
          commit('facturas', response.data.data.ventas);
          commit('totalVentasCierreDiario', response.data.data.total_ventas_cierre_diario);
          commit('facturasCierreDiario', response.data.data.facturas_cierre_diario);
          commit('exportVentas', response.data.data.exportVentas);
          commit('primer_fecha_venta_pdv', response.data.data.primer_fecha);
          commit('pagesFacturas');
          state.loading = false;
          store.commit('changeLoader', false);
        })
        .catch(error => {
          state.loading = false;
          store.commit('changeLoader', false);
          swal("Error!", "Error al obtener el historial de ventas!", "error");
          console.error(error);
        });

    },
    filtrarVentasFisicas({ state, getters, commit, rootState }, payload) {
      state.loading = true;
      store.commit('changeLoader', true);
      axios.get(`${configApi.apiUrl}filtrar_ventas_fisica?idventa=${payload.id_venta}`, null, { headers: headers })
        .then(response => {
          commit('facturas', response.data.data.ventas);
          commit('pagesFacturas', response.data.data.ventas.total);
          state.loading = false;
          store.commit('changeLoader', false);
        })
        .catch(error => {
          state.loading = false;
          store.commit('changeLoader', false);
          swal("Error!", "Error al obtener el historial de ventas!", "error");
          console.error(error);
        });

    },
    informeVentasJefePuntoVenta({ state, getters, commit, rootState }, payload) {
      state.loading = true;
      store.commit('changeLoader', true);
      axios.get(`${configApi.apiUrl}informe_ventas_jefe_punto_venta?page=${payload.page}&desde=${payload.desde}&hasta=${payload.hasta}&id_evento=${payload.id_evento}&punto_venta=${payload.punto_venta}`, null, { headers: headers })
        .then(response => {
          commit('facturas', response.data.data.ventas);
          commit('totalVentasCierreDiario', response.data.data.total_ventas_cierre_diario);
          commit('facturasCierreDiario', response.data.data.facturas_cierre_diario);
          commit('exportVentas', response.data.data.exportVentas);
          commit('primer_fecha_venta_pdv', response.data.data.primer_fecha);
          commit('pagesFacturas');
          state.loading = false;
          store.commit('changeLoader', false);
        })
        .catch(error => {
          state.loading = false;
          store.commit('changeLoader', false);
          swal("Error!", "Error al obtener el informe de ventas de ventas!", "error");
          console.error(error);
        });

    },
    searchFactura({ state, getters, commit, rootState }, id) {
      store.commit('changeLoader', true);
      axios.post(`${configApi.apiUrl}search_factura`, { id }, { headers: headers })
        .then(response => {
          commit('factura', response.data);
          store.commit('changeLoader', false);
          store.commit('showFactura', true);
        })
        .catch(error => {
          var htmlErrors = ``;
          var obj = error.response.data.errors;
          for (const prop in obj) {
            htmlErrors += `${obj[prop][0]}\n`;
          }

          swal("Error!", htmlErrors, "error");
          store.commit('changeLoader', false);
        });

    },
    searchPagosParciales({ state, getters, commit, rootState }, payload) {
      state.loadingPagosParciales = false;
      store.commit('changeLoader', true);
      axios.get(`${configApi.apiUrl}search_pagos_parciales?page=${payload.page}&tipo_identificacion=${payload.tipo_identificacion}&identificacion=${payload.identificacion}`, null, { headers: headers })
        .then(response => {
          commit('pagosParciales', response.data.data.ventas);
          commit('pagesPagosParciales');
          commit('userSale', response.data.data.user);
          commit('busquedaUser', true);
          state.loadingPagosParciales = false;
          store.commit('changeLoader', false);
        })
        .catch(error => {
          state.loadingPagosParciales = false;
          store.commit('changeLoader', false);
          swal("Error!", "Error al obtener los pagos parciales!", "error");
          console.error(error);
        });

    },
    updateVentaPagoParcial({ state, getters, commit, rootState }, payload) {
      store.commit('changeLoader', true);
      axios.post(`${configApi.apiUrl}update_venta_parcial`, { payload }, { headers: headers })
        .then(response => {
          commit('factura', response.data);
          swal("Exito!", "El abono a la venta se registro satisfactoriamente!", "success");
          store.commit('changeLoader', false);
          store.commit('showFactura', true);
        })
        .catch(error => {
          var htmlErrors = ``;
          var obj = error.response.data.errors;
          for (const prop in obj) {
            htmlErrors += `${obj[prop][0]}\n`;
          }

          swal("Error!", htmlErrors, "error");
          store.commit('changeLoader', false);
        });

    },
    informeCortesias({ state, getters, commit, rootState }, payload) {
      state.loading = true;
      store.commit('changeLoader', true);
      axios.get(`${configApi.apiUrl}informe_cortesias?page=${payload.page}&desde=${payload.desde}&hasta=${payload.hasta}&id_evento=${payload.id_evento}`, null, { headers: headers })
        .then(response => {
          commit('exportCortesias', response.data.data.exportCortesias);
          commit('primer_fecha_venta_pdv', response.data.data.primer_fecha);
          commit('pagesCortesias');
          state.loading = false;
          store.commit('changeLoader', false);
        })
        .catch(error => {
          state.loading = false;
          store.commit('changeLoader', false);
          swal("Error!", "Error al obtener el informe de cortesias!", "error");
          console.error(error);
        });

    },
    async getInfoBoletaImpresion({ state }, payload) {
      state.errorGetBoleta = false;
      let tempData = {
        localidad_id: payload.localidad_id,
        ids_detalle_venta: payload.ids_detalle_venta
      }
      await axios.post(`${configApi.apiUrl}imprimir_boletas`, tempData, { headers: headers })
        .then(response => {
          state.imprimirBoletas = response.data
        })
        .catch(error => {
          state.errorGetBoleta = true;
          let message = ""
          for (const property in error.response.data.data) {
            message = message + error.response.data.data[property] + " "
          }
          swal(error.response.data.message, message, "warning");
        });
   	},
  async countPalcosIndividuales({state}, payload) {      
    state.loading = true;
    //console.log(payload);
    store.commit('changeLoader', true);
    await axios.get(`${configApi.apiUrl}count_palcos_individuales_reservados`, { headers: headers })
    .then(response => {
        console.log(response)
        commit('countPalcosIndividuales', response)
        state.loading = false;
        store.commit('changeLoader', false);
    })

    },
  },
}