<template>
  <v-app>
    <v-container>
      <h1><v-icon large>location_on</v-icon> Puntos de venta</h1>
      <div class="p-3 bg-white">
        <v-text-field
          solo
          label="Buscar"
          prepend-inner-icon="search"
          class="search"
          v-model="keyPuntoventas"
        ></v-text-field>
      </div>
      <div class="pa-2 bg-white">
        <v-data-table
          :headers="headersPuntoventa"
          :items="puntosventa"
          :search="keyPuntoventas"
          :items-per-page="5"
          class="elevation-1"
          rows-per-page-text="Filas por Pag."
          :rows-per-page-items="rowperpageitems"
        >
          <template v-slot:items="puntoventa">
            <td>{{ puntoventa.item.nombre_razon }}</td>
            <td>{{ puntoventa.item.direccion }}</td>
            <td>{{ getCiudad(puntoventa.item.id_ciudad) }}</td>
            <td>{{ puntoventa.item.zona }}</td>
            <td>{{ puntoventa.item.telefono }}</td>
            <td>{{ puntoventa.item.responsable }}</td>
          </template>
        </v-data-table>
      </div>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: "puntosVenta",
  created() {
    this.$store.dispatch("puntoventa/listPuntoventas");
    this.$store.dispatch("ciudad/listCiudades");
  },
  data() {
    return {
      keyPuntoventas: "",
      headersPuntoventa: [
        {
          text: "Nombre Comercial",
          align: "left",
          value: "nombre_razon",
        },
        {
          text: "Dirección",
          value: ".direccion",
        },
        {
          text: "Ciudad",
          value: "id_ciudad",
        },
        {
          text: "Zona/Region",
          value: "zona",
        },
        {
          text: "Telefono",
          value: "telefono",
        },
        {
          text: "Responsable",
          value: "responsable",
        },
      ],
      rowperpageitems: [5, 10, 25, { text: "Todos", value: -1 }],
    };
  },
  methods: {
    getCiudad(id) {
      let filter_ciudad = this.ciudades.filter((ciudad) => ciudad.id == id);
      if (filter_ciudad[0] == "" || filter_ciudad[0] == null) {
        return "";
      } else {
        return filter_ciudad[0] ? filter_ciudad[0].descripcion : [];
      }
    },
  },
  computed: {
    ciudades() {
      return this.$store.getters["ciudad/listCiudades"];
    },
    puntosventa() {
      return this.$store.getters["puntoventa/listPuntoventas"];
    },
  },
};
</script>