<template>
  <v-app>
    <v-container>
      <v-layout row wrap>
        <v-flex lg12 md12 sm12 xs12>
          <div class="mb-3 mt-3">
            <h2>
              Puntos de venta asignados a: <b>{{ evento.nombre }}</b>
            </h2>
          </div>
          <div>
            <span
              ><router-link
                :to="{ path: '/mis-eventos' }"
                title="Ver todos mis eventos"
                ><u>Volver a Mis Eventos</u></router-link
              ></span
            ><span> > </span><span>Puntos de venta por evento</span>
          </div>
          <div class="mt-3 mb-4"></div>
        </v-flex>
      </v-layout>
      <v-divider></v-divider>
      <v-layout row wrap>
        <v-flex lg6 md6 sm4 xs12 mb-3>
          <v-select
            v-model="puntosVentaParaAsignar"
            :items="puntosVentaSinAsignar"
            item-value="id"
            item-text="nombre_razon"
            attach
            chips
            label="Asignar puntos de venta a este evento"
            multiple
          ></v-select>
        </v-flex>
        <v-flex lg3 md3 sm4 xs12 mb-3>
          <v-btn
            :disabled="puntosVentaParaAsignar.length <= 0"
            class="mt-4"
            color="success"
            @click="asignarPuntosVenta"
            ><i class="material-icons">save</i> GUARDAR ASIGNACION</v-btn
          >
        </v-flex>
        <v-flex lg3 md3 sm4 xs12 mb-3 text-right>
          <v-btn class="mt-4" color="info" @click="modalNewPuntoVenta = true"
            ><i class="material-icons">add</i> CREAR PUNTO DE VENTA</v-btn
          >
        </v-flex>
      </v-layout>
      <v-divider></v-divider>
      <v-card>
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Buscar..."
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="evento.puntoventa_eventos"
          :itemsPerPage="evento.puntoventa_eventos.length"
          :search="search"
          class="elevation-1 table-ventas"
          :no-data-text="'No se han encontrado puntos de venta...'"
          must-sort
          rows-per-page-text="Resultados por pagina"
          hide-default-footer
        >
          <template v-slot:items="props">
            <td class="text-xs-center">
              {{ props.item.punto_ventum.nombre_razon }}
            </td>
            <td class="text-xs-center">
              {{ getDepartamento(props.item.punto_ventum.id_ciudad) }}
            </td>
            <td class="text-xs-center">
              {{
                ciudades.filter(
                	(item) => item.id == props.item.punto_ventum.id_ciudad)
                [0] ? ciudades.filter((item) => item.id == props.item.punto_ventum.id_ciudad)[0].descripcion : []
              }}
            </td>
            <td class="text-xs-center">
              {{ props.item.punto_ventum.direccion }}
            </td>
            <td class="text-xs-center">
              {{ props.item.punto_ventum.responsable }}
            </td>
            <td class="text-xs-center">
              {{ props.item.punto_ventum.identificacion }}
            </td>
            <td class="text-xs-center">
              {{ props.item.punto_ventum.telefono }}
            </td>
            <td class="text-xs-center">{{ props.item.punto_ventum.email }}</td>
          </template>
          <template v-slot:item.acciones="props">
            <td class="text-xs-center">
              <v-icon
                medium
                title="Remover de este evento"
                @click="deletePuntoVenta(props.item.punto_ventum.id)"
              >
                delete
              </v-icon>
            </td>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              No se encontraron puntos de venta con "{{ search }}".
            </v-alert>
          </template>
        </v-data-table>
      </v-card>
      <v-dialog
        v-if="modalNewPuntoVenta"
        v-model="modalNewPuntoVenta"
        max-width="750px"
        persistent
      >
        <form @submit.prevent="nuevoPuntoVenta" action="#" autocomplete="off">
          <v-card>
            <v-card-title>
              <div class="text-center col-md-12 col-lg-12 col-sm-12 col-xs-12">
                <img
                  src="@/assets/images/logo-CentralTickets.png"
                  style="max-width: 150px; width: 150px"
                />
              </div>
            </v-card-title>
            <v-container>
              <v-card-text>
                <h5 class="text-center"><b>NUEVO PUNTO DE VENTA</b></h5>
                <v-divider></v-divider>
                <p class="mb-0 mt-0">
                  <v-icon small color="warning">info</v-icon> Los campos
                  marcados con <b>*</b> son obligatorios.
                </p>
                <p class="mb-0 mt-0">
                  <v-icon small color="warning">info</v-icon> La contraseña del
                  usuario sera enviada por correo electronico.
                </p>
                <p class="mb-0 mt-0">
                  <v-icon small color="warning">info</v-icon> Se enviara un
                  correo electronico al usuario informando la creacion de su
                  cuenta en CentralTickets.
                </p>
                <hr />
                <v-layout wrap row>
                  <v-flex xs12 sm12 md12 lg12 mb-3>
                    <v-text-field
                      label="Nombres del responsable (*)"
                      required
                      v-model="user.nombre"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md12 lg12 mb-3>
                    <v-text-field
                      label="Nombres o razon social (*)"
                      required
                      v-model="user.puntoventa.nombre_razon"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 lg4 md4 sm4 pr-3 mb-3>
                    <v-select
                      v-model="user.tipo_identificacion"
                      item-value="id"
                      item-text="code"
                      :items="tipoIdentificaciones"
                      label="Tipo de identificacion (*)"
                      prepend-icon="person"
                      persistent-hint
                      :hint="descripcionTipoIdentificacion"
                      required
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 lg8 md8 sm8 mb-3>
                    <v-text-field
                      label="Numero de identificacion (*)"
                      required
                      v-model="user.identificacion"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md12 lg12 mb-3>
                    <v-text-field
                      label="Correo electronico (*)"
                      type="email"
                      required
                      v-model="user.email"
                      :rules="emailRules"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6 lg6 pr-3 mb-3>
                    <v-select
                      v-model="user.departamento"
                      item-value="id"
                      item-text="descripcion"
                      :items="departamentos"
                      label="Departamento (*)"
                      prepend-icon="location_on"
                      persistent-hint
                      required
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 sm6 md6 lg6 mb-3>
                    <v-select
                      v-model="user.ciudad"
                      item-value="id"
                      item-text="descripcion"
                      :items="ciudadesSelect"
                      label="Ciudad (*)"
                      prepend-icon="location_on"
                      persistent-hint
                      required
                    ></v-select>
                  </v-flex>
                  <v-flex xs12 sm6 md6 lg6 pr-3 mb-3>
                    <v-text-field
                      label="Direccion (*)"
                      v-model="user.direccion"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6 lg6 mb-3>
                    <v-text-field
                      label="Telefono"
                      type="tel"
                      v-model="user.telefono"
                      autocomplete="nope"
                    ></v-text-field>
                  </v-flex>
                  <v-flex lg12 md12 xs12 sm12>
                    <v-checkbox
                      v-model="user.puntoventa.checkAsignarNewPuntoVenta"
                      class="check_asignar_pv"
                      :label="
                        'Asignar este punto de venta al evento actual (' +
                        evento.nombre +
                        ')'
                      "
                    ></v-checkbox>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-container>
            <hr />
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn type="submit" color="success">GUARDAR</v-btn>
              <v-btn
                color="blue darken-1"
                flat
                @click="modalNewPuntoVenta = false"
                >Cerrar</v-btn
              >
            </v-card-actions>
          </v-card>
        </form>
      </v-dialog>
    </v-container>
  </v-app>
</template>
<script>
import moment from "moment";
moment.locale("es");

export default {
  data() {
    return {
      search: "",
      puntosVentaParaAsignar: [],
      modalNewPuntoVenta: false,
      headers: [
        { text: "NOMBRE RAZON", value: "punto_ventum.nombre_razon" },
        { text: "DEPARTAMENTO", value: "punto_ventum.id_ciudad" },
        { text: "CIUDAD", value: "punto_ventum.id_ciudad" },
        { text: "DIRECCION", value: "punto_ventum.direccion" },
        { text: "RESPONSABLE", value: "punto_ventum.responsable" },
        { text: "IDENTIFICACION", value: "punto_ventum.identificacion" },
        { text: "TELEFONO", value: "punto_ventum.telefono" },
        { text: "EMAIL", value: "punto_ventum.email" },
        { text: "ACCIONES", value: "acciones", sortable: false },
      ],
      user: {
        nombre: null,
        email: null,
        identificacion: null,
        tipo_identificacion: 1,
        direccion: null,
        ciudad: null,
        departamento: null,
        telefono: null,
        id_rol: 5,
        puntoventa: {
          nombre_razon: null,
          checkAsignarNewPuntoVenta: true,
          id_evento: null,
        },
      },
      emailRules: [
        (v) => /.+@.+/.test(v) || "Direccion de correo electronico incorrecta",
      ],
    };
  },
  async beforeCreate() {
    document.title = `Puntos de venta por evento - CentralTickets`;
    this.$store.dispatch("departamento/listDepartamentos");
    this.$store.dispatch("ciudad/listCiudades");
    await this.$store.dispatch("user/listTipoIdentificaciones");
    await this.$store.dispatch(
      "puntoventa/puntosVentaSinAsignar",
      this.$route.params.evento
    );
    await this.$store.dispatch("evento/getEvento", this.$route.params.evento);
  },
  computed: {
    ciudades() {
      return this.$store.getters["ciudad/listCiudades"];
    },
    ciudadesSelect() {
      return this.ciudades.filter(
        (item) => item.id_departamento == this.user.departamento
      );
    },
    departamentos() {
      return this.$store.getters["departamento/listDepartamentos"];
    },
    evento() {
      return this.$store.getters["evento/evento"];
    },
    puntosVentaSinAsignar() {
      return this.$store.getters["puntoventa/puntosVentaSinAsignar"];
    },
    tipoIdentificaciones() {
      return this.$store.getters["user/listTipoIdentificaciones"];
    },
    descripcionTipoIdentificacion() {
      return this.tipoIdentificaciones.filter(
      	(item) => item.id === this.user.tipo_identificacion
      )[0] ? this.tipoIdentificaciones.filter((item) => item.id === this.user.tipo_identificacion)[0].descripcion : [];
    },
  },
  methods: {
    deletePuntoVenta(id) {
      var self = this;
      swal({
        title: "¿Estas seguro de remover este punto de venta del evento?",
        text: "",
        icon: "warning",
        buttons: ["No, cancelar", "Confirmar"],
        closeModal: true,
      }).then((willDelete) => {
        if (willDelete) {
          self.$store.dispatch("puntoventa/deletePuntoVentaPorEvento", {
            id_evento: self.evento.id,
            punto_venta: id,
          });
          self.$store.dispatch(
            "puntoventa/puntosVentaSinAsignar",
            self.$route.params.evento
          );
          self.$store.dispatch("evento/getEvento", self.$route.params.evento);
        }
      });
    },
    async asignarPuntosVenta() {
      if (this.puntosVentaParaAsignar.length > 0) {
        await this.$store.dispatch("puntoventa/puntosVentaParaAsignar", {
          id_evento: this.$route.params.evento,
          puntos_venta: this.puntosVentaParaAsignar,
        });
        await this.$store.dispatch(
          "puntoventa/puntosVentaSinAsignar",
          this.$route.params.evento
        );
        this.$store.dispatch("evento/getEvento", this.$route.params.evento);
        this.puntosVentaParaAsignar = [];
      } else {
        swal(
          "Error!",
          "Debes seleccionar por lo menos un punto de venta!",
          "error"
        );
      }
    },
    async nuevoPuntoVenta() {
      if (
        this.user.nombre != null &&
        this.user.puntoventa.nombre_razon != null &&
        this.user.tipo_identificacion != null &&
        this.user.identificacion != null &&
        this.user.email != null &&
        this.user.direccion != null &&
        this.user.departamento != null &&
        this.user.ciudad != null
      ) {
        this.user.puntoventa.id_evento = this.$route.params.evento;

        await this.$store.dispatch(
          "puntoventa/newUsuarioPuntoVenta",
          this.user
        );
        await this.$store.dispatch(
          "puntoventa/puntosVentaSinAsignar",
          this.$route.params.evento
        );
        this.$store.dispatch("evento/getEvento", this.$route.params.evento);
        this.puntosVentaParaAsignar = [];
        this.modalNewPuntoVenta = false;
        this.user.nombre = null;
        this.user.email = null;
        this.user.identificacion = null;
        this.user.tipo_identificacion = 1;
        this.user.direccion = null;
        this.user.ciudad = null;
        this.user.departamento = null;
        this.user.telefono = null;
        this.user.puntoventa.nombre_razon = null;
      } else {
        swal(
          "Error!",
          "Debe completar todos los campos obligatorios!",
          "error"
        );
      }
    },
    getDepartamento(id_ciudad) {
      var id_departamento = this.ciudades.filter(
        (item) => item.id == id_ciudad
      )[0].id_departamento;
      return this.departamentos.filter((item) => item.id == id_departamento)[0]
        .descripcion;
    },
  },
};
</script>
<style>
.elevation-1.table-ventas table thead {
  background-color: #cacaca;
}
.elevation-1.table-ventas table thead th {
  color: black;
  font-weight: bold;
}
.elevation-1.table-ventas table tbody td {
  color: black;
  font-size: 16px;
}
.check_asignar_pv .accent--text {
  color: #1969ed !important;
  caret-color: #1969ed !important;
}
</style>
