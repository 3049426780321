<template>
    <v-app>
        <div class="container-payment">
            <div class="col-12">
                <h2 class="my-4 w-100 text-center">Resumen transacción</h2>
            </div>
            <div class="w-100 px-5 d-flex mb-5">
                <button class="ml-auto btn btn-secondary" @click="$router.push({path: '/'})">Volver al inicio</button>
            </div>
            <div class="col-12 col-lg-8 order-2 order-md-1">
                <div class="card-resumen">
                    <div class="table-responsive">
                        <table class="tabla_resumen">
                            <tr>
                                <td><b>Orden</b></td>
                                <td>{{$route.query.order}}</td>
                            </tr>
                            <tr>
                                <td><b>Respuesta banco</b></td>
                                <td>{{$store.state.responseDataPayment.response_transaction.respuesta_banco}}</td>
                            </tr>
                            <tr>
                                <td><b>Referencia pasarela</b></td>
                                <td>{{$store.state.responseDataPayment.response_transaction.referencia_pasarela}}</td>
                            </tr>
                            <tr>
                                <td><b>Id de venta</b></td>
                                <td>{{$store.state.responseDataPayment.response_transaction.venta_id}}</td>
                            </tr>
                            <tr>
                                <td><b>Fecha transacción</b></td>
                                <td>{{$store.state.responseDataPayment.response_transaction.fecha_transaccion}}</td>
                            </tr>
                            <tr>
                                <td><b>Entidad bancaria</b></td>
                                <td>{{$store.state.responseDataPayment.response_transaction.tipo_tarjeta}}</td>
                            </tr>
                            <tr>
                                <td><b>Nº de cuotas</b></td>
                                <td>{{$store.state.responseDataPayment.response_transaction.cuotas}}</td>
                            </tr>
                            <tr>
                                <td><b>Tipo de tarjeta</b></td>
                                <td>{{$store.state.responseDataPayment.response_transaction.entidad_bancaria}}</td>
                            </tr>
                            <tr>
                                <td><b>Referencia orden</b></td>
                                <td>{{$store.state.responseDataPayment.response_transaction.referencia_orden}}</td>
                            </tr>
                            <tr>
                                <td><b>Estado transacción</b></td>
                                <td>
                                    <v-icon v-if="$store.state.responseDataPayment.response_transaction.status == 1" left color="green">check</v-icon>
                                    <v-icon v-else left color="red">close</v-icon>
                                    {{$store.state.responseDataPayment.response_transaction.status == 1 ? 'Aceptada' : 'Rechazada'}} <span v-if="$store.state.responseDataPayment.response_transaction.error_descripcion">- <b> {{$store.state.responseDataPayment.response_transaction.error_descripcion}}</b></span>
                                </td>
                            </tr>
                            <tr>
                                <td><b>impuestos</b></td>
                                <td>{{$store.state.responseDataPayment.response_transaction.impuesto}}</td>
                            </tr>
                            <tr>
                                <td><b>Subtotal compra</b></td>
                                <td>{{$store.state.responseDataPayment.response_transaction.subtotal_compra}}</td>
                            </tr>
                            <tr>
                                <td><b>Total compra</b></td>
                                <td>{{$store.state.responseDataPayment.response_transaction.total_compra}}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-4 px-3 px-md-4 order-1 order-md-2">
                <div class="card-resumen mb-3">
                    <h3 class="text-center w-100">Resumen de compra</h3>
                    <div class="divider-resumen"/>
                    <div style="border-bottom: 1px solid #9797973a" class="pb-2 d-flex align-items-center justify-content-between w-100 px-4 px-lg-2" :key="index" v-for="(product, index) in $store.state.responseDataPayment.response_transaction.productos">
                        <div>
                            <p class="mb-0">{{product.descripcion}}</p>
                            <small style="color: gray">Cantidad: {{product.cantidad}}</small> <br>
                            <small style="color: gray">Id: {{product.id}}</small>
                        </div>
                        <p class="mb-0 px-2"><b>{{product.precio}}</b></p>
                    </div>
                    <div class="d-flex justify-content-between ms-auto my-4 pe-3 w-100">
                        <p style="font-size: 18px">TOTAL: </p>
                        <p style="font-size: 18px"><b>{{$store.state.responseDataPayment.response_transaction.total_compra}}</b></p>
                    </div>
                </div>
            </div>
        </div>
    </v-app>
</template>
<script>
export default {
    created(){
        document.title = "Resumen de pago"
    },
    async mounted() {
        await this.$store.dispatch('getSumarryOrderPayment', {'order': this.$route.query.order})
    },
}
</script>
<style>
.container-payment{
    width: 100vw;
    height: 100vh;
    max-width: 1600px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
}
.tabla_resumen{
    widows: 100%;
    margin-right: auto;
    margin-left: auto;
}
.tabla_resumen tr td{
    border: 1px solid gray;
    padding: .5rem 1rem;
}
</style>