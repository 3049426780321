import axios from 'axios';
import {configApi} from '../../api/configApi';
import {router} from '../../main.js';
import {headers} from '../../api/headers';
import {store} from '../index';

export default {
  namespaced: true,
  state: {
       localidades: [],
       localidad: null,
       localidadesauditorio: [],
       boletaslocalidad: [],
       disponibilidadboletaslocalidad: null,
       palcoslocalidad: [],
       pagination: {
          descending: true,
          page: 1,
          rowsPerPage: 0,
          sortBy: '',
          totalItems: 0,
          rowsPerPageItems: [1, 2, 4, 8, 16],
    },
    pages: 0,
    loading: false,
    listTiposPapel: [],
  },
  getters: {
    listLocalidades: (state,getters,rootState) => {
          return state.localidades;
    },
    listLocalidadesAuditorio: (state,getters,rootState) => {
          return state.localidadesauditorio;
    },
    listBoletasLocalidad: (state,getters,rootState) => {
          return state.boletaslocalidad;
    },
    listBoletasLocalidadDisponibilidad: (state,getters,rootState) => {
          return state.disponibilidadboletaslocalidad;
    },
    listPalcosLocalidad: (state,getters,rootState) => {
          return state.palcoslocalidad;
    },
    localidad: (state,getters,rootState) => {
        return state.localidad;
    },
    pagination(state,getters,rootState){
      return state.pagination;
    },
    pages(state,getters,rootState){
      return state.pages;
    },
    totalItemCount(state,getters,rootState){
        return state.pagination.totalItems;
    },
    loading(state,getters,rootState){
        return state.loading;
    },
    listTiposPapel(state,getters,rootState){
      return state.listTiposPapel;
    },
  },
  mutations: {
        listLocalidades: (state,localidades) => {
          state.localidades = localidades;
          state.pagination.totalItems = localidades.total;
          state.pagination.page = localidades.current_page;
          state.pagination.rowsPerPage = localidades.per_page;
       },
        listLocalidadesAuditorio: (state,localidadesauditorio) => {
          state.localidadesauditorio = localidadesauditorio;
       },
        listBoletasLocalidad: (state,boletaslocalidad) => {
          state.boletaslocalidad = boletaslocalidad;
       },
       listBoletasLocalidadDisponibilidad: (state,disponibilidadboletaslocalidad) => {
          state.disponibilidadboletaslocalidad = disponibilidadboletaslocalidad;
       },
        listPalcosLocalidad: (state,palcoslocalidad) => {
          state.palcoslocalidad = palcoslocalidad;
       },
      listLocalidadesSearch: (state,localidades) => {
            if(localidades != null){ 
                state.localidades = localidades;
            }else{
              state.localidades = null;
            }
       },
      viewLocalidad(state,id){
        let auxLocalidad = state.localidades.filter((item) => item.id == id);
        state.localidad = auxLocalidad[0];
      },
      setLocalidad(state,localidad){
        state.localidad = localidad;
      },
    pages(state){
         if (state.pagination.rowsPerPage == null ||
              state.pagination.totalItems == null) { 
              state.pages = 0;
          } 

        state.pages = Math.ceil(state.pagination.totalItems / state.pagination.rowsPerPage);
    },
    listTiposPapel(state,tiposPapel){
      state.listTiposPapel = tiposPapel;
    },
  },
  actions: {
    listLocalidades({state,getters,commit,rootState}){
      state.loading = true;
      axios.get(`${configApi.apiUrl}localidad_all`,null,{headers: headers})
      .then(response => {
        //console.log(response.data.data.data);
        commit('listLocalidades',response.data.data);
        commit('pages');
        state.loading = false;
      })
      .catch(error => {
        state.loading = false;
        swal("Error!", "Error al obtener las localidades!", "error");
        console.error(error);
      });

    },
      listLocalidadesAuditorio({state,getters,commit,rootState},key){
      axios.get(`${configApi.apiUrl}localidades_auditorio/${key}`,null,{headers: headers})
      .then(response => {
        commit('listLocalidadesAuditorio',response.data.data[0]["localidades"]);
      })
      .catch(error => {
        if (error.message!="Request failed with status code 404") {
          swal("Error!", "Error al obtener las localidades por evento!", "error");
          //console.log(error)
        }
      });

    },
      listBoletasLocalidad({state,getters,commit,rootState},key){
      store.commit('changeLoader', true);
      return axios.get(`${configApi.apiUrl}listado_boletas_localidad/${key.id_localidad}/${key.id_evento}/${key.codigo_moneda}`,null,{headers: headers})
      .then(response => {
        commit('listBoletasLocalidad',response.data.data[0]);
        commit('listBoletasLocalidadDisponibilidad',response.data.data['puestosdisponibles']);
        store.commit('changeLoader', false);
      })
      .catch(error => {
        store.commit('changeLoader', false);
        if (error.message!="Request failed with status code 404") {
          swal("Error!", "Error al obtener las boletas por localidad!", "error");
          //console.log(error)
        }
      });
    },
      listPalcosLocalidad({state,getters,commit,rootState},key){
      store.commit('changeLoader', true);
      axios.get(`${configApi.apiUrl}listado_palcos_localidad/${key.id_localidad}/${key.id_evento}`,null,{headers: headers})
      .then(response => {
        commit('listPalcosLocalidad',response.data.data[0]);
        store.commit('changeLoader', false);
      })
      .catch(error => {
        store.commit('changeLoader', false);
        if (error.message!="Request failed with status code 404") {
          swal("Error!", "Error al obtener las boletas por localidad!", "error");
          //console.log(error)
        }
      });
    },
    getLocalidad({state,getters,commit,rootState},key){
      store.commit('changeLoader', true);
      return axios.get(`${configApi.apiUrl}localidad/${key}`,null,{headers: headers})
      .then(response => {
        commit('setLocalidad',response.data.data);
        store.commit('changeLoader', false);
      })
      .catch(error => {
        store.commit('changeLoader', false);
        swal("Error!", "Error al obtener la localidad!", "error");
        console.error(error);
      });

    },
  newLocalidad({state,getters,commit,rootState},payload){
      // nombre
      // id_tribuna
      // puerta_acceso
      // ruta
      // url_imagen
      // aforo
      // silleteria
      // palco
      // puestosxpalco
      // imagen
      store.dispatch('validateToken');
      return axios.post(`${configApi.apiUrl}localidad`,payload,{headers: headers})
      .then(response => {
        /*swal("Exito!", "Se creo la localidad correctamente!", "success");*/
        /*store.dispatch('localidad/listLocalidades',state.pagination.page);*/
        commit('setLocalidad',response.data.data);
      })
      .catch(error => {
        swal("Error!", "Error al crear la localidad!", "error");
        console.error(error);
      });
  },
  updateLocalidad({state,getters,commit,rootState},payload){
      // nombre
      // id_tribuna
      // puerta_acceso
      // ruta
      // url_imagen
      // aforo
      // silleteria
      // palco
      // puestosxpalco
      // imagen
      store.dispatch('validateToken');
      axios.put(`${configApi.apiUrl}localidad/${payload.id}`,payload,{headers: headers})
      .then(response => {
        swal("Exito!", "Se actualizo la localidad correctamente!", "success");
        store.dispatch('localidad/listLocalidades',state.pagination.page);
      })
      .catch(error => {
        swal("Error!", "Error al actualizar la localidad!", "error");
        console.error(error);
      });
  },
  deleteLocalidad({state,getters,commit,rootState},localidad){
    store.dispatch('validateToken');
    return axios.delete(`${configApi.apiUrl}localidad/${localidad}`,null,{headers: headers})
     .then(response => {
        swal("Exito!", "Se elimino la localidad correctamente!", "success");
        //store.dispatch('localidad/listLocalidades',state.pagination.page);
     })
     .catch(error => {
        swal("Error!","Localidad ya tiene puestos asignados!","error");
        //console.log(error);
        return error;
     });
  },
    searchLocalidades({state,getters,commit,rootState},key){
      store.dispatch('validateToken');
      store.commit('changeLoader', true);
      axios.get(`${configApi.apiUrl}buscarLocalidad?nombre=${key}`,null,{headers: headers})
      .then(response => {
        commit('listLocalidadesSearch',response.data.data);
        store.commit('changeLoader', false);
      })
      .catch(error => {
        store.commit('changeLoader', false);
        swal("Error!", "Error al obtener las localidades!", "error");
        console.error(error);
      });

    },
    listTiposPapel({state,getters,commit,rootState}){
      axios.get(`${configApi.apiUrl}all_tipo_papel`,null,{headers: headers})
      .then(response => {
        //console.log(response.data);
        commit('listTiposPapel',response.data.data);
      })
      .catch(error => {
        swal("Error!", "Error al obtener los tipos de papel!", "error");
        console.error(error);
      });

    },
    disenoPapelXTipo({state,getters,commit,rootState},id_tipo_papel){
      store.commit('changeLoader', true);
      return axios.post(`${configApi.apiUrl}diseno_papel_x_tipo`,{id_tipo_papel},{headers: headers})
      .then(response => {
        //console.log(response.data);
        store.commit('changeLoader', false);
        return response.data.data;
      })
      .catch(error => {
        store.commit('changeLoader', false);
        swal("Error!", "Error al obtener los diseños de papel!", "error");
        console.error(error);
      });

    },
  },
}