<template>
  <v-app>
    <v-container>
      <v-layout>
        <v-flex xs12 md6 class="mx-auto">
          <v-card>
            <v-card-title>
              <h3>Peticiones, Quejas y Reclamos</h3>
              <!-- <small align="left">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam.
              </small> -->
            </v-card-title>
            <v-card-text>
              <v-form
                id="form-pqr"
                ref="form_pqr"
                enctype="multipart/form-data"
                @submit.prevent="sendPQR"
              >
                <v-text-field
                  name="nombres"
                  v-model="pqr.nombres"
                  :counter="150"
                  label="Nombres*"
                  :rules="rules.nombres"
                  prepend-icon="perm_identity"
                  autofocus
                  required
                ></v-text-field>

                <v-text-field
                  name="apellidos"
                  v-model="pqr.apellidos"
                  :counter="150"
                  label="Apellidos*"
                  :rules="rules.nombres"
                  prepend-icon="perm_identity"
                  required
                ></v-text-field>

                <v-text-field
                  name="cedula"
                  v-model="pqr.cedula"
                  :counter="15"
                  label="Cédula*"
                  :rules="rules.cedula"
                  prepend-icon="assignment_ind"
                  required
                ></v-text-field>

                <v-text-field
                  name="email"
                  v-model="pqr.email"
                  label="E-mail*"
                  :rules="rules.email"
                  prepend-icon="email"
                  required
                ></v-text-field>

                <v-text-field
                  name="telefonos"
                  v-model="pqr.telefono"
                  label="Telefono*"
                  :rules="rules.telefono"
                  prepend-icon="phone"
                  required
                ></v-text-field>

                <v-text-field
                  name="celular"
                  v-model="pqr.celular"
                  label="Celular"
                  prepend-icon="smartphone"
                  required
                ></v-text-field>

                <v-text-field
                  name="nombre_evento"
                  v-model="pqr.nombre_evento"
                  label="Nombre del evento*"
                  :rules="rules.nombre_evento"
                  prepend-icon="not_listed_location"
                  required
                ></v-text-field>

                <div class="d-flex justify-space-around">
                  <v-checkbox
                    class="d-flex align-items-center"
                    name="tipo"
                    v-model="pqr.tipo"
                    value="Petición"
                    label="Petición"
                    required
                  ></v-checkbox>
                  <v-checkbox
                    class="d-flex align-items-center"
                    name="tipo"
                    v-model="pqr.tipo"
                    value="Queja"
                    label="Queja"
                    required
                  ></v-checkbox>
                  <v-checkbox
                    class="d-flex align-items-center"
                    name="tipo"
                    v-model="pqr.tipo"
                    value="Reclamo"
                    label="Reclamo"
                    required
                  ></v-checkbox>
                </div>

                <div class="row">
                  <v-textarea
                    background-color="white"
                    filled
                    rows="2"
                    label="Documento"
                    prepend-icon="attach_file"
                    v-model="pqr.documento"
                    hint="El archivo seleccionado deben tener el formato de imagen o pdf"
                    @click="$refs.inputUpload.click()"
                    @keypress="$event.preventDefault()"
                  >
                  </v-textarea>
                  <div>
                    <v-btn
                      key="share"
                      color="primary"
                      fab
                      large
                      dark
                      bottom
                      left
                      class="ml-0 mt-2 mr-4"
                      @click="$refs.inputUpload.click()"
                    >
                      <v-icon>cloud_upload</v-icon>
                    </v-btn>
                  </div>
                  <input
                    v-show="false"
                    name="documento"
                    ref="inputUpload"
                    type="file"
                    accept="image/*,.pdf"
                    @change="loadDocument($event)"
                  />
                </div>

                <v-textarea
                  background-color="white"
                  filled
                  rows="3"
                  name="comentarios"
                  label="Comentarios*"
                  :rules="rules.comentarios"
                  prepend-icon="speaker_notes"
                  v-model="pqr.comentarios"
                ></v-textarea>

                <div>
                  <v-btn
                    type="submit"
                    class="ma-2 ml-btn"
                    color="success"
                    large
                  >
                    <v-icon left class="mt-0 mr-2 rotate-340">send</v-icon>
                    <p class="mt-4">Enviar</p>
                  </v-btn>
                </div>
              </v-form>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: "pqr",
  data() {
    return {
      pqr: {
        nombres: "",
        apellidos: "",
        cedula: "",
        email: "",
        telefono: "",
        celular: "",
        nombre_evento: "",
        tipo: "",
        documento: "",
        comentarios: "",
      },
      rules: {
        nombres: [
          (v) => !!v || "El campo nombres es requerido",
          (v) =>
            /\w{3}/.test(v) ||
            "El campo nombre debe tener al menos 3 caracteres",
        ],
        apellidos: [
          (v) => !!v || "El campo apellidos es requerido",
          (v) =>
            /\w{3}/.test(v) || "El apellido debe tener al menos 3 caracteres",
        ],
        cedula: [
          (v) => !!v || "El campo cédula es requerido",
          (v) => /\d{8,12}/.test(v) || "La cédula debe tener un formato valido",
        ],
        email: [
          (v) => !!v || "E-mail es requerido",
          (v) => /.+@.+\..+/.test(v) || "E-mail debe tener un formato valido",
        ],
        telefono: [
          (v) => !!v || "El campo telefono es requerido",
          (v) =>
            /\d{5,12}/.test(v) ||
            "El campo telefono debe tener un formato valido",
        ],
        nombre_evento: [
          (v) => !!v || "El campo nombre del evento es requerido",
        ],
        comentarios: [
          (v) => !!v || "El campo comentarios es requerido",
          (v) =>
            /[\w\s]{10}/.test(v) ||
            "El campo comentarios debe tener al menos 10 caracteres",
        ],
      },
    };
  },
  methods: {
    sendPQR() {
      if (!this.$refs.form_pqr.validate()) {
        swal(
          "Errores de validación",
          "Debe diligenciar todos los campos",
          "error"
        );
        return false;
      }

      let formData = new FormData(document.getElementById("form-pqr"));

      this.$store.dispatch("pqr/sendPQR", formData);
    },

    loadDocument(e) {
      if (
        Array.from(e.target.files).find(
          (file) => !/\.(png|jpg|jpeg|pdf)$/.test(file.name)
        )
      ) {
        this.$refs.inputUpload.values = "";
        this.pqr.documento = "";
      } else {
        this.pqr.documento = [...e.target.files]
          .map((file) => file.name)
          .join("\r\n");
      }
    },
  },
};
</script>

<style scoped>
.ml-btn {
  margin-left: 2.5rem !important;
}

small {
  font-size: 0.875rem;
  line-height: 1.5rem;
}
.rotate-340 {
  transform: rotate(340deg);
}
</style>