<template>
  <v-app>
    <v-container>
      <v-layout row wrap>
        <v-flex lg12 md12 sm12 xs12>
          <div class="mb-3 mt-3">
            <h2>Pagos parciales</h2>
          </div>
          <div>
            <span
              ><router-link
                :to="{ path: '/mis-eventos' }"
                title="Ver todos mis eventos"
                ><u>Volver a Mis Eventos</u></router-link
              ></span
            ><span> > </span><span>Pagos parciales</span>
          </div>
          <div class="mt-3 mb-4"></div>
        </v-flex>
      </v-layout>
      <v-divider></v-divider>
      <v-layout row wrap>
        <v-flex lg4 md4 sm4 xs12 mb-3>
          <v-select
            v-if="show_select"
            v-model="userSale.tipo_identificacion"
            item-value="id"
            item-text="code"
            :items="tipoIdentificaciones"
            label="Tipo de identificacion (*)"
            prepend-icon="account_box"
            persistent-hint
            :hint="descripcionTipoIdentificacion"
            required
            :readonly="busquedaUser"
          ></v-select>
        </v-flex>
        <v-flex lg4 md4 sm4 xs12 mb-3>
          <v-text-field
            prepend-icon="account_box"
            label="Identificacion usuario"
            v-model="userSale.identificacion"
            @keyup.enter="searchPagosParcialesUser"
            :readonly="busquedaUser"
            required
          ></v-text-field>
        </v-flex>
        <v-flex lg4 md4 sm4 xs12 mb-3>
          <v-btn
            color="primary"
            @click="searchPagosParcialesUser"
            v-if="!busquedaUser"
            ><v-icon>search</v-icon> BUSCAR VENTAS</v-btn
          >
          <v-btn color="error" @click="limpiarUser(false)" v-if="busquedaUser"
            ><v-icon>delete</v-icon> LIMPIAR BUSQUEDA</v-btn
          >
        </v-flex>
      </v-layout>
      <v-divider></v-divider>
      <v-data-table
        :headers="headers"
        v-if="busquedaUser"
        must-sort
        :items="pagosParciales"
        hide-default-footer
        :length="pages"
        :search="search"
        :itemsPerPage="pagosParciales.length"
        :total-items="totalItemCount"
        class="elevation-1 table-ventas"
        :loading="loading"
        :no-data-text="'No se han encontrado ventas...'"
      >
        <template v-slot:items="props">
          <td class="font-weight-bold">#{{ props.item.id }}</td>
          <td class="text-xs-center">
            {{ formatFechaHumans(props.item.fecha) }}<br /><small>{{
              props.item.fecha
            }}</small>
          </td>
          <td class="text-xs-center">{{ props.item.cliente }}</td>
          <td class="text-xs-center text-uppercase">
            {{ props.item.forma_pago }}
          </td>
          <td class="text-xs-center text-uppercase">
            {{ props.item.code_voucher }}
          </td>
          <td class="text-xs-center">
            {{ props.item.moneda.simbolo
            }}{{
              formatoMoneda(getTotalVenta(props.item.detalle_vents, 0))
            }}
            ({{ props.item.moneda.codigo_moneda }})
          </td>
          <td class="text-xs-center">
            {{ props.item.moneda.simbolo
            }}{{ formatoMoneda(getTotalAbonado(props.item.id)) }} ({{
              props.item.moneda.codigo_moneda
            }})
          </td>
          <td class="text-xs-center">
            {{ props.item.moneda.simbolo
            }}{{
              formatoMoneda(
                getTotalVenta(
                  props.item.detalle_vents,
                  getTotalAbonado(props.item.id)
                )
              )
            }}
            ({{ props.item.moneda.codigo_moneda }})
          </td>
          <td>
            <v-layout row wrap text-center>
              <v-flex lg6 md6 sm6 xs6 mb-3>
                <v-icon
                  title="Abonar a la venta"
                  medium
                  center
                  @click="abonarVenta(props.item.id)"
                  color="success"
                >
                  update
                </v-icon>
              </v-flex>
              <v-flex lg6 md6 sm6 xs6 mb-3>
                <v-icon
                  title="Visualizar la factura"
                  medium
                  center
                  @click="searchFactura(props.item.id)"
                >
                  receipt_long
                </v-icon>
              </v-flex>
            </v-layout>
          </td>
        </template>
      </v-data-table>
      <div class="text-xs-center pt-2" v-if="busquedaUser">
        <v-pagination v-model="pagination.page" :length="pages"></v-pagination>
      </div>
      <v-divider></v-divider>
      <form v-if="abonar_venta" action="#" @submit.prevent="updateVenta">
        <h2>
          <v-icon center large>update</v-icon> Abonar a la venta
          <b>#{{ venta.id }}</b
          >:
        </h2>
        <br />
        <h6 class="text-dark" v-if="venta.abonos_venta_parcial.length > 0">
          ABONOS REALIZADOS:
        </h6>
        <table class="table" v-if="venta.abonos_venta_parcial.length > 0">
          <thead>
            <tr>
              <th class="text-center">FORMA DE PAGO</th>
              <th class="text-center">TIPO TARJETA</th>
              <th class="text-center">MONTO EFECTIVO</th>
              <th class="text-center">MONTO TARJETA</th>
              <th class="text-center">COD VOUCHER</th>
              <th class="text-center">FECHA</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in venta.abonos_venta_parcial">
              <td class="text-center text-uppercase text-dark font-weight-bold">
                {{ item.forma_pago }}
              </td>
              <td class="text-center text-uppercase text-dark font-weight-bold">
                {{ item.forma_pago != "efectivo" ? item.tipo_tarjeta : "" }}
              </td>
              <td class="text-center text-dark font-weight-bold">
                {{ venta.moneda.simbolo
                }}{{ formatNumber(item.monto_efectivo) }} ({{
                  venta.moneda.codigo_moneda
                }})
              </td>
              <td class="text-center text-dark font-weight-bold">
                <span v-if="item.forma_pago != 'efectivo'"
                  >{{ venta.moneda.simbolo
                  }}{{ formatNumber(item.monto_tarjeta) }} ({{
                    venta.moneda.codigo_moneda
                  }})</span
                >
              </td>
              <td class="text-center text-dark font-weight-bold">
                {{ item.code_voucher }}
              </td>
              <td class="text-center text-dark font-weight-bold">
                {{ item.created_at }}
              </td>
            </tr>
          </tbody>
        </table>
        <h3>
          <b>Total venta: </b> {{ venta.moneda.simbolo
          }}{{ formatoMoneda(getTotalVenta(venta.detalle_vents, 0)) }} ({{
            venta.moneda.codigo_moneda
          }})
        </h3>
        <h3>
          <b>Total abonado:</b> {{ venta.moneda.simbolo
          }}{{ formatoMoneda(getTotalAbonado(venta.id)) }} ({{
            venta.moneda.codigo_moneda
          }})
        </h3>
        <h3>
          <b>Total restante:</b> {{ venta.moneda.simbolo
          }}{{
            formatoMoneda(
              getTotalVenta(venta.detalle_vents, getTotalAbonado(venta.id))
            )
          }}
          ({{ venta.moneda.codigo_moneda }})
        </h3>
        <v-divider></v-divider>
        <h4 class="mb-3 text-secondary">
          <v-icon center>person</v-icon> Datos del comprador
        </h4>
        <v-layout row wrap pl-4>
          <v-flex xs12 sm6 lg6 md6 pr-2 mb-3>
            <v-select
              v-if="show_select"
              v-model="userSale.tipo_identificacion"
              item-value="id"
              item-text="code"
              :items="tipoIdentificaciones"
              label="Tipo de identificacion (*)"
              prepend-icon="account_box"
              persistent-hint
              :hint="descripcionTipoIdentificacion"
              required
              :readonly="busquedaUser"
            ></v-select>
          </v-flex>
          <v-flex xs12 sm6 lg6 m6 pr-2 mb-3>
            <v-text-field
              prepend-icon="account_box"
              label="Identificacion usuario"
              v-model="userSale.identificacion"
              :readonly="busquedaUser"
              required
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout row wrap pl-4>
          <v-flex xs12 sm4 lg4 md4 pr-2 mb-3>
            <v-text-field
              prepend-icon="person"
              label="Nombre completo"
              v-model="userSale.nombre"
              :readonly="userSale.exists"
              required
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm4 lg4 md4 pr-2 mb-3>
            <v-text-field
              prepend-icon="alternate_email"
              label="Correo electronico"
              v-model="userSale.email"
              :readonly="userSale.exists"
              type="email"
              required
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm4 lg4 md4 pr-2 mb-3>
            <v-text-field
              prepend-icon="call"
              label="Telefono"
              v-model="userSale.telefono"
              :readonly="userSale.exists"
              type="tel"
              required
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout row wrap pl-4>
          <v-flex xs12 sm4 lg4 md4 pr-2 mb-3>
            <v-text-field
              v-if="userSale.departamento && userSale.exists"
              prepend-icon="location_on"
              label="Departamento"
              readonly
              :value="
                departamentos.filter(
                  (item) => item.id == userSale.departamento
                )[0].descripcion
              "
            ></v-text-field>
            <v-select
              v-if="!userSale.exists && show_select"
              v-model="userSale.departamento"
              item-value="id"
              item-text="descripcion"
              :items="departamentos"
              label="Departamento de residencia"
              prepend-icon="location_on"
              persistent-hint
              required
              :readonly="userSale.exists"
            ></v-select>
          </v-flex>
          <v-flex xs12 sm4 lg4 md4 pr-2 mb-3>
            <v-text-field
              v-if="userSale.ciudad && userSale.exists"
              prepend-icon="location_on"
              label="Ciudad"
              readonly
              :value="
                ciudades.filter((item) => item.id == userSale.ciudad)[0]
                  .descripcion
              "
            ></v-text-field>
            <v-select
              v-if="!userSale.exists && show_select"
              v-model="userSale.ciudad"
              item-value="id"
              item-text="descripcion"
              :items="ciudadesSelect"
              label="Ciudad de residencia"
              prepend-icon="location_on"
              persistent-hint
              required
              :disabled="userSale.departamento == null"
              no-data-text="Selecciona un departamento"
              :readonly="userSale.exists"
            ></v-select>
          </v-flex>
          <v-flex xs12 sm4 lg4 md4 pr-2 mb-3>
            <v-text-field
              prepend-icon="home"
              label="Direccion de residencia"
              v-model="userSale.direccion"
              :readonly="userSale.exists"
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-divider></v-divider>
        <h4 class="mb-3 text-secondary">
          <v-icon center>theaters</v-icon> Entrega de boletas
        </h4>
        <v-layout row wrap>
          <v-flex lg12 md12 xm12 xs12 mb-3>
            <v-select
              v-if="show_select"
              :items="tiposEntregaBoletas"
              label="Medio de entrega de las boletas"
              v-model="venta.entrega_boletas"
              item-text="text"
              prepend-icon="theaters"
              readonly
            ></v-select>
          </v-flex>
        </v-layout>
        <v-divider></v-divider>
        <h4 class="mb-3 text-secondary">
          <v-icon center>payment</v-icon> Forma de pago ({{
            venta.moneda.codigo_moneda
          }})
        </h4>
        <v-layout row wrap pl-4>
          <v-flex xs12 sm6 lg6 md6 mb-3>
            <v-select
              v-if="show_select"
              :items="formasPago"
              name="formaPago"
              :label="
                'Seleccione un metodo de pago (' +
                venta.moneda.codigo_moneda +
                ')'
              "
              v-model="formaPago"
              item-text="text"
              prepend-icon="payment"
              @change="changeFormaPago"
              required
            ></v-select>
          </v-flex>
          <v-flex xs12 sm6 lg6 md6 mb-3 v-if="formaPago != 'efectivo'">
            <v-select
              v-if="show_select"
              :items="formasPagoTarjeta"
              name="formaPagoTarjeta"
              label="Seleccione tipo de tarjeta"
              v-model="formaPagoTarjeta"
              item-text="text"
              prepend-icon="payment"
              required
            ></v-select>
          </v-flex>
        </v-layout>
        <v-layout row wrap pl-4 v-if="formaPago == 'mixto'">
          <v-flex xs12 sm6 lg6 md6 mb-3>
            <v-currency-field
              prepend-icon="payment"
              :label="'Monto con tarjeta (' + venta.moneda.codigo_moneda + ')'"
              :prefix="venta.moneda.simbolo"
              filled
              v-model="montoTarjeta"
              @keydown="validateSumaPagoMixto"
              required
            ></v-currency-field>
          </v-flex>
          <v-flex xs12 sm6 lg6 md6 mb-3>
            <v-currency-field
              prepend-icon="attach_money"
              :label="'Monto en efectivo (' + venta.moneda.codigo_moneda + ')'"
              :prefix="venta.moneda.simbolo"
              filled
              v-model="montoEfectivo"
              @keydown="validateSumaPagoMixto"
              required
            ></v-currency-field>
          </v-flex>
        </v-layout>
        <v-layout row wrap>
          <v-flex xs12 sm12 lg12 md12 mb-3 v-if="formaPago != 'efectivo'" pl-4>
            <v-text-field
              prepend-icon="payment"
              label="Codigo del voucher"
              v-model="codeVoucher"
              required
            ></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout row wrap v-if="formaPago != 'mixto'">
          <v-flex lg12 md12 sm12 xs12 mb-5>
            <v-currency-field
              prepend-icon="payment"
              :label="
                formaPago == 'efectivo'
                  ? 'Abono en efectivo para el pago parcial'
                  : 'Abono con tarjeta para el pago parcial'
              "
              :prefix="venta.moneda.simbolo"
              filled
              required
              v-model="montoAbonoPagoParcial"
              @keydown="validateMontoPagoParcial"
            ></v-currency-field>
          </v-flex>
        </v-layout>
        <v-divider></v-divider>
        <div class="text-right">
          <v-layout row wrap>
            <v-flex lg8 md8 sm8 xs12> </v-flex>
            <v-flex lg4 md4 sm4 xs12 mb-3>
              <v-currency-field
                prepend-icon="payment"
                label="Efectivo recibido"
                :prefix="venta.moneda.simbolo"
                filled
                v-model="efectivoRecibido"
              ></v-currency-field>
            </v-flex>
            <v-flex lg8 md8 sm8 xs12> </v-flex>
            <v-flex lg4 md4 sm4 xs12 mb-3>
              <v-text-field
                readonly
                prepend-icon="payment"
                label="Vueltos para entregar"
                :value="
                  montoEfectivo > 0 &&
                  efectivoRecibido > 0 &&
                  efectivoRecibido > montoEfectivo
                    ? formatNumber(efectivoRecibido - montoEfectivo)
                    : formaPago == 'efectivo' &&
                      efectivoRecibido > 0 &&
                      efectivoRecibido >
                        getTotalVenta(
                          venta.detalle_vents,
                          getTotalAbonado(venta.id)
                        )
                    ? venta.moneda.simbolo +
                      formatNumber(
                        efectivoRecibido -
                          getTotalVenta(
                            venta.detalle_vents,
                            getTotalAbonado(venta.id)
                          )
                      )
                    : 0
                "
              ></v-text-field>
            </v-flex>
          </v-layout>
          <h3>
            <b>Total venta: </b> {{ venta.moneda.simbolo
            }}{{ formatoMoneda(getTotalVenta(venta.detalle_vents, 0)) }} ({{
              venta.moneda.codigo_moneda
            }})
          </h3>
          <h3>
            <b>Total abonado:</b> {{ venta.moneda.simbolo
            }}{{ formatoMoneda(getTotalAbonado(venta.id)) }} ({{
              venta.moneda.codigo_moneda
            }})
          </h3>
          <h3>
            <b>Total restante:</b> {{ venta.moneda.simbolo
            }}{{
              formatoMoneda(
                getTotalVenta(venta.detalle_vents, getTotalAbonado(venta.id))
              )
            }}
            ({{ venta.moneda.codigo_moneda }})
          </h3>
        </div>
        <v-divider></v-divider>
        <div class="text-right">
          <v-btn type="submit" color="success"
            ><v-icon>shopping_cart</v-icon>&nbsp; ABONAR A LA VENTA</v-btn
          >
        </div>
        <v-divider></v-divider>
      </form>
    </v-container>
  </v-app>
</template>
<script>
import moment from "moment";
moment.locale("es");

export default {
  data() {
    return {
      search: "",
      show_select: false,
      abonar_venta: false,
      venta: null,
      headers: [
        {
          text: "N° VENTA",
          align: "left",
          sortable: false,
          value: "id",
        },
        { text: "FECHA", value: "fecha", align: "center", sortable: false },
        { text: "CLIENTE", value: "cliente", align: "center", sortable: false },
        {
          text: "FORMA DE PAGO",
          value: "forma_pago",
          align: "center",
          sortable: false,
        },
        {
          text: "COD VOUCHER",
          value: "code_voucher",
          align: "center",
          sortable: false,
        },
        { text: "TOTAL VENTA", value: "", align: "center", sortable: false },
        {
          text: "TOTAL ABONADO",
          value: "total",
          align: "center",
          sortable: false,
        },
        { text: "RESTANTE", value: "", align: "center", sortable: false },
        { text: "ACCIONES", value: "", sortable: false, align: "center" },
      ],
      codeVoucher: null,
      formaPago: "efectivo",
      montoTarjeta: 0,
      montoEfectivo: 0,
      formaPagoTarjeta: "debito",
      formasPago: [
        {
          text: "Efectivo",
          value: "efectivo",
        },
        {
          text: "Tarjeta",
          value: "tarjeta",
        },
        {
          text: "Mixto",
          value: "mixto",
        },
      ],
      formasPagoTarjeta: [
        {
          text: "Credito",
          value: "credito",
        },
        {
          text: "Debito",
          value: "debito",
        },
      ],
      efectivoRecibido: 0,
      tiposEntregaBoletas: [
        {
          text: "DIGITAL",
          value: "digital",
        },
        {
          text: "FISICA",
          value: "fisica",
        },
        {
          text: "TAQUILLA",
          value: "taquilla",
        },
        {
          text: "DOMICILIO",
          value: "domicilio",
        },
      ],
      montoAbonoPagoParcial: 0,
    };
  },
  async beforeCreate() {
    document.title = "Pagos parciales - CentralTickets";
    //await this.$store.dispatch('carrito/pagosParciales',{'page':1,'iden':null,'hasta':null,'id_evento':null});
    await this.$store.dispatch("user/listTipoIdentificaciones");
    this.$store.dispatch("departamento/listDepartamentos");
    this.$store.dispatch("ciudad/listCiudades");
    var self = this;
    setTimeout(function () {
      self.show_select = true;
    }, 250);
  },
  watch: {
    pagination: {
      async handler() {
        this.$store.dispatch("carrito/searchPagosParciales", {
          page: this.pagination.page,
          tipo_identificacion: this.userSale.tipo_identificacion,
          identificacion: this.userSale.identificacion,
        });
      },
      deep: true,
    },
  },
  computed: {
    usuario() {
      return this.$store.getters["user/dataUserLogged"];
    },
    pagosParciales() {
      return this.$store.getters["carrito/pagosParciales"];
    },
    factura() {
      return this.$store.getters["carrito/factura"];
    },
    pagination() {
      return this.$store.getters["carrito/paginationPagosParciales"];
    },
    pages() {
      return this.$store.getters["carrito/pagesParciales"];
    },
    totalItemCount() {
      return this.$store.getters["carrito/totalItemCountPagosParciales"];
    },
    loading() {
      return this.$store.getters["carrito/loadingPagosParciales"];
    },
    busquedaUser() {
      return this.$store.getters["carrito/busquedaUser"];
    },
    tipoIdentificaciones() {
      return this.$store.getters["user/listTipoIdentificaciones"];
    },
    descripcionTipoIdentificacion() {
      return this.tipoIdentificaciones.filter(
        (item) => item.id === this.userSale.tipo_identificacion
      )[0].descripcion;
    },
    busquedaUser() {
      return this.$store.getters["carrito/busquedaUser"];
    },
    userSale() {
      return this.$store.getters["carrito/userSale"];
    },
    ciudades() {
      return this.$store.getters["ciudad/listCiudades"];
    },
    ciudadesSelect() {
      return this.ciudades.filter(
        (item) => item.id_departamento == this.userSale.departamento
      );
    },
    departamentos() {
      return this.$store.getters["departamento/listDepartamentos"];
    },
  },
  methods: {
    formatNumber(number) {
      return new Intl.NumberFormat("es-CO", {
        minimumFractionDigits: 0,
      }).format(number);
    },
    printFactura() {
      // Pass the element id here
      this.$htmlToPaper("printFactura");
    },
    formatoMoneda(valor) {
      if (valor != null && valor != "") {
        return new Intl.NumberFormat("es-CO").format(valor);
      } else {
        return "";
      }
    },
    formatFechaHumans(fecha) {
      return moment(fecha).fromNow();
    },
    searchPagosParcialesUser() {
      if (
        this.userSale.tipo_identificacion != null &&
        this.userSale.identificacion
      ) {
        this.$store.dispatch("carrito/searchPagosParciales", {
          page: 1,
          tipo_identificacion: this.userSale.tipo_identificacion,
          identificacion: this.userSale.identificacion,
        });
      } else {
        swal(
          "Error!",
          "Completa los campos para realizar la busqueda!",
          "error"
        );
      }
    },
    searchFactura(id) {
      this.$store.dispatch("carrito/searchFactura", id);
    },
    limpiarUser() {
      this.$store.dispatch("carrito/limpiarUserSale", false);
    },
    abonarVenta(id) {
      this.abonar_venta = false;
      this.venta = null;
      this.formaPago = "efectivo";
      this.abonar_venta = true;
      this.montoAbonoPagoParcial = 0;
      this.venta = this.pagosParciales.filter((item) => item.id == id)[0];
      //$("html, body").animate({ scrollTop: 700 }, 1000);
    },
    getTotalVenta(detalle_vents, abonado) {
      var totalVenta = 0;
      for (var i = 0; i < detalle_vents.length; i++) {
        totalVenta +=
          detalle_vents[i].precio_servicio +
          detalle_vents[i].precio_venta +
          (detalle_vents[i].precio_servicio + detalle_vents[i].precio_venta) *
            detalle_vents[i].impuesto;
      }
      return totalVenta - abonado;
    },
    changeFormaPago() {
      if (this.formaPago == "mixto") {
        this.montoTarjeta =
          this.getTotalVenta(
            this.venta.detalle_vents,
            this.getTotalAbonado(this.venta.id)
          ) / 2;
        this.montoEfectivo =
          this.getTotalVenta(
            this.venta.detalle_vents,
            this.getTotalAbonado(this.venta.id)
          ) / 2;
      }
    },
    validateSumaPagoMixto() {
      var self = this;
      setTimeout(function () {
        //console.log('monto efectivo: '+self.montoEfectivo);
        //console.log('monto tarjeta: '+self.montoTarjeta);
        if (
          self.montoEfectivo + self.montoTarjeta >
          self.getTotalVenta(
            this.venta.detalle_vents,
            self.getTotalAbonado(this.venta.id)
          )
        ) {
          self.montoTarjeta =
            self.getTotalVenta(
              this.venta.detalle_vents,
              self.getTotalAbonado(this.venta.id)
            ) / 2;
          self.montoEfectivo =
            self.getTotalVenta(
              this.venta.detalle_vents,
              self.getTotalAbonado(this.venta.id)
            ) / 2;
        }
      }, 1);
    },

    validateMontoPagoParcial() {
      var self = this;
      setTimeout(function () {
        //console.log('monto efectivo: '+self.montoEfectivo);
        //console.log('monto tarjeta: '+self.montoTarjeta);
        if (
          self.montoAbonoPagoParcial >
          self.getTotalVenta(
            self.venta.detalle_vents,
            self.getTotalAbonado(self.venta.id)
          )
        ) {
          self.montoAbonoPagoParcial = 0;
        }
      }, 1);
    },
    getTotalAbonado(id) {
      var abonos = this.pagosParciales.filter((item) => item.id == id)[0];
      var totalAbonado = abonos.total;

      for (var i = 0; i < abonos.abonos_venta_parcial.length; i++) {
        totalAbonado +=
          abonos.abonos_venta_parcial[i].monto_efectivo +
          abonos.abonos_venta_parcial[i].monto_tarjeta;
      }
      return totalAbonado;
    },
    updateVenta() {
      var self = this;
      swal({
        title: "¿Esta seguro de confirmar el abono a la venta?",
        text: "",
        icon: "warning",
        buttons: ["No, cancelar", "Confirmar"],
        closeModal: true,
      }).then((willDelete) => {
        if (willDelete) {
          self.$store
            .dispatch("carrito/updateVentaPagoParcial", {
              venta_id: self.venta.id,
              monto_abono_pago_parcial: self.montoAbonoPagoParcial,
              datosPago: {
                formaPago: self.formaPago,
                formaPagoTarjeta: self.formaPagoTarjeta,
                montoTarjeta: self.montoTarjeta,
                montoEfectivo: self.montoEfectivo,
                codeVoucher: self.codeVoucher,
              },
            })
            .then(() => {
              self.limpiarUser();
              self.montoAbonoPagoParcial = 0;
              self.codeVoucher = null;
              self.abonar_venta = false;
              self.venta = null;
              self.formaPago = "efectivo";
              self.abonar_venta = false;
              self.montoAbonoPagoParcial = 0;
              $("html, body").animate({ scrollTop: 0 }, 1000);
            });
        }
      });
    },
  },
};
</script>
<style>
.elevation-1.table-ventas table thead {
  background-color: #cacaca;
}
.elevation-1.table-ventas table thead th {
  color: black;
  font-weight: bold;
}
.elevation-1.table-ventas table tbody td {
  color: black;
  font-size: 16px;
}
</style>