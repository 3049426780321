<template>
  <v-app>
    <v-container>
      <v-layout>
        <v-flex xs12 md6 class="mx-auto">
          <v-card>
            <v-card-title>
              <h3>Contacto</h3>
              <!-- <small align="left">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam.
              </small> -->
            </v-card-title>
            <v-card-text>
              <v-form
                id="form-contacto"
                ref="form_contacto"
                @submit.prevent="sendContact"
              >
                <v-text-field
                  name="nombres"
                  v-model="contacto.nombres"
                  :counter="150"
                  label="Nombres*"
                  :rules="rules.nombres"
                  prepend-icon="perm_identity"
                  autofocus
                  required
                ></v-text-field>

                <v-text-field
                  name="apellidos"
                  v-model="contacto.apellidos"
                  :counter="150"
                  label="Apellidos*"
                  :rules="rules.nombres"
                  prepend-icon="perm_identity"
                  required
                ></v-text-field>

                <v-text-field
                  name="email"
                  v-model="contacto.email"
                  label="E-mail*"
                  :rules="rules.email"
                  prepend-icon="email"
                  required
                ></v-text-field>

                <v-textarea
                  background-color="white"
                  filled
                  rows="2"
                  name="asunto"
                  v-model="contacto.asunto"
                  label="Asunto*"
                  :rules="rules.asunto"
                  prepend-icon="message"
                  required
                ></v-textarea>

                <div class="row">
                  <v-textarea
                    background-color="white"
                    filled
                    rows="2"
                    label="Documento"
                    prepend-icon="attach_file"
                    v-model="contacto.documento"
                    hint="El archivo seleccionado deben tener el formato de imagen o pdf"
                    @click="$refs.inputUpload.click()"
                    @keypress="$event.preventDefault()"
                  >
                  </v-textarea>
                  <div>
                    <v-btn
                      key="share"
                      color="primary"
                      fab
                      large
                      dark
                      bottom
                      left
                      class="ml-0 mt-2 mr-4"
                      @click="$refs.inputUpload.click()"
                    >
                      <v-icon>cloud_upload</v-icon>
                    </v-btn>
                  </div>
                  <input
                    v-show="false"
                    name="documento"
                    ref="inputUpload"
                    type="file"
                    accept="image/*,.pdf"
                    @change="loadDocument($event)"
                  />
                </div>

                <v-textarea
                  background-color="white"
                  filled
                  rows="3"
                  name="comentarios"
                  label="Comentarios*"
                  :rules="rules.comentarios"
                  v-model="contacto.comentarios"
                  prepend-icon="speaker_notes"
                ></v-textarea>

                <div>
                  <v-btn
                    type="submit"
                    class="ma-2 ml-btn"
                    color="success"
                    large
                  >
                    <v-icon left class="mt-0 mr-2 rotate-340">send</v-icon>
                    <p class="mt-4">Enviar</p>
                  </v-btn>
                </div>
              </v-form>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: "contacto",
  data() {
    return {
      contacto: {
        nombres: "",
        apellidos: "",
        email: "",
        asunto: "",
        documento: "",
        comentarios: "",
      },
      rules: {
        nombres: [
          (v) => !!v || "El campo nombres es requerido",
          (v) =>
            /\w{3}/.test(v) ||
            "El campo nombre debe tener al menos 3 caracteres",
        ],
        apellidos: [
          (v) => !!v || "El campo apellidos es requerido",
          (v) =>
            /\w{3}/.test(v) || "El apellido debe tener al menos 3 caracteres",
        ],
        email: [
          (v) => !!v || "E-mail es requerido",
          (v) => /.+@.+\..+/.test(v) || "E-mail debe tener un formato valido",
        ],
        asunto: [
          (v) => !!v || "El campo asunto es requerido",
          (v) =>
            /[\w\s]{10}/.test(v) ||
            "El campo asunto debe tener al menos 10 caracteres",
        ],
        comentarios: [
          (v) => !!v || "El campo comentarios es requerido",
          (v) =>
            /[\w\s]{10}/.test(v) ||
            "El campo comentarios debe tener al menos 10 caracteres",
        ],
      },
    };
  },
  methods: {
    sendContact() {
      if (!this.$refs.form_contacto.validate()) {
        swal(
          "Errores de validación",
          "Debe diligenciar todos los campos",
          "error"
        );
        return false;
      }

      let formData = new FormData(document.getElementById("form-contacto"));

      this.$store.dispatch("contacto/sendContact", formData);
    },

    loadDocument(e) {
      if (
        Array.from(e.target.files).find(
          (file) => !/\.(png|jpg|jpeg|pdf)$/.test(file.name)
        )
      ) {
        this.$refs.inputUpload.values = "";
        this.contacto.documento = "";
      } else {
        this.contacto.documento = [...e.target.files]
          .map((file) => file.name)
          .join("\r\n");
      }
    },
  },
};
</script>

<style scoped>
.ml-btn {
  margin-left: 2.5rem !important;
}
.rotate-340 {
  transform: rotate(340deg);
}
small {
  font-size: 0.875rem;
  line-height: 1.5rem;
}
</style>