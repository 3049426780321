import axios from 'axios';
import { configApi } from '../api/configApi';
import { router } from '../main.js';
import { store } from '@/store';
import { headers } from '../api/headers';
import $storeUser from '../store/modules/user'
import $router from '../routes'

export const actions = {

  async doLogin({ commit }, loginData) {
    // email
    // password
    localStorage.clear();
    commit('changeLoader', true);
    commit('loginStart');
    await axios.post(`${configApi.apiUrl}login`, loginData)
      .then(response => {
        //localStorage.setItem('accessTokenTicketClient', response.data.success.token);
        axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.success.token}`;
        commit('loginStop', null);
        commit('updateAccessToken', response.data.success.token);
        commit('showModalLogin', false);
        commit('user/updateDataUserLogged', response.data.success.usuario);
        commit('user/updateAccessToken', response.data.success.token);
        //store.dispatch('user/detailsUser',response.data.success.token);
        commit('changeLoader', false);
      })
      .catch(error => {
        commit('loginStop', error.response.data.error);
        commit('updateAccessToken', null);
        commit('changeLoader', false);
        swal("Datos incorrectos!", "Por favor verifique su usuario y contraseña!", "error");
      });
    if (store.state.loggingIn) {
      window.location.href = "/";
    }
  },
  doRegister({ commit }, user) {
    // nombre
    // email
    // password
    // c_password
    //return;
    commit('changeLoader', true);
    axios.post(`${configApi.apiUrl}register`, user)
      .then(response => {
        commit('changeLoader', false);
        if (response.data.success) {
          store.state.modalRegister = false;
          swal(`Bienvenido ${user.nombre +' '+user.apellidos}!`, "Hemos enviado un enlace a su correo para que active su cuenta!", "success");
          //router.push('/');
        }
      })
      .catch(error => {
        commit('changeLoader', false);
        swal("Ocurrio un error!", error.response.data.error, "error");
      });
  },
  async getOrderPayment({ commit, state }, payload) {
    commit('changeLoader', true);
    await axios.post(`${configApi.apiUrl}payment/get_transaccion_venta`, payload)
      .then(response => {
        state.dataPayment = response.data
      })
      .catch(error => {
        swal("Ocurrio un error!", error.response.data.error, "error");
      })
      .finally(()=>{
        commit('changeLoader', false);
      });
  },
  async getSumarryOrderPayment({ commit, state }, payload) {
    commit('changeLoader', true);
    await axios.post(`${configApi.apiUrl}payment/finalizar_pedido`, payload)
      .then(response => {
        state.responseDataPayment = response.data
        // console.log(response)
      })
      .catch(error => {
        swal("Ocurrio un error!", error.response.data.error, "error");
      })
      .finally(()=>{
        commit('changeLoader', false);
      });
  },
  async getSumarryOrderPaymentV2({ commit, state }, payload) {
    commit('changeLoader', true);
    await axios.post(`${configApi.apiUrl}placetopay/get_transaction`, payload)
      .then(response => {
        state.responseDataPaymentV2 = response.data.transaccion
      })
      .catch(error => {
        if(error.response.data.alert){
          swal("Atención!", error.response.data.alert, "info").then(() => {
            router.replace({path: '/'})
          });
        }else{
          var message = ""
          var obj = error.response.data.errors;
          for (const prop in obj) {
              message += `${obj[prop][0]} \n`;
          }
          swal("Ocurrio un error!", message, "error").then(() => {
            router.replace({path: '/'})
          });
        }
      })
      .finally(()=>{
        commit('changeLoader', false);
      });
  },
  activeAccount({ commit }, token) {
    commit('changeLoader', true);
    axios.get(`${configApi.apiUrl}auth/signup/activate/${token}`, null)
      .then(response => {
        if (response.data.success) {
          commit('tokenActiveAccount', true);
          commit('showModalLogin', false);
          commit('changeLoader', false);
        }
      })
      .catch(error => {
        commit('tokenActiveAccount', false);
        commit('changeLoader', false);
        swal("Token incorrecto!", "El token ya caducó o es incorrecto!", "warning");
        //console.error(error);
      });
  },
  // fetchAccessToken({ commit }) {
  //   commit('updateAccessToken', localStorage.getItem('accessTokenTicketClient'));
  // },
  logout({ commit }) {
    commit('changeLoader', true);
    axios.post(`${configApi.apiUrl}logout`, null, { headers: headers })
      .then(response => {
        //localStorage.removeItem('accessTokenTicketClient');
        commit('user/updateDataUserLogged', {
          nombre: null,
          email: null,
          identificacion: null,
          telefono: null,
          direccion: null,
          ciudad: null,
          departamento: null,
          id_rol: null
        });
        store.dispatch('evento/clearCantBoleta');
        store.dispatch('carrito/setNullPreciosBoletasCarrito');
        store.dispatch('carrito/dateTimeSaleStart', null);
        store.dispatch('carrito/dateTimeSaleEnd', null);
        commit('showModalLogin', false);
        commit('logout');
        window.localStorage.clear();
        wndow.location.href = "/";
        commit('changeLoader', false);
      })
      .catch(error => {
        //console.error(error);
        localStorage.removeItem('accessTokenTicketClient');
        commit('user/updateDataUserLogged', {
          nombre: null,
          email: null,
          identificacion: null,
          telefono: null,
          direccion: null,
          ciudad: null,
          departamento: null,
          id_rol: null
        });
        commit('logout');
        localStorage.clear();
        router.push('/');
        commit('changeLoader', false);
      });
  },
  logoutLocal({ commit }) {
    localStorage.removeItem('accessTokenTicketClient');
    commit('user/updateDataUserLogged', {
      nombre: null,
      email: null,
      identificacion: null,
      telefono: null,
      direccion: null,
      ciudad: null,
      departamento: null,
      id_rol: null
    });
    commit('logout');
    localStorage.clear();
    router.push('/');
  },
  async validateToken({ commit }) {
    await axios.get(`${configApi.apiUrl}validateToken`, null, { headers: headers })
      .then(response => {
        if (!response.data.status) {
          store.dispatch('logoutLocal');
          store.dispatch("logout");
          router.push({ path: '/' });
        }else{
          commit('user/updateDataUserLogged', response.data.user);
        }
      })
      .catch(error => {
        // console.log('ocurrio un eror');
        store.dispatch('logoutLocal');
      });
  },
  resetPassword({ commit }, email) {
    commit('changeLoader', true);
    axios.post(`${configApi.apiUrl}creatreset`, email)
      .then(response => {
        commit('changeLoader', false);
        swal("Exito!", "Hemos enviado por correo electrónico su enlace de restablecimiento de contraseña!", "success");
        //router.push('/');
      })
      .catch(error => {
        commit('changeLoader', false);
        swal("Datos incorrectos!", "Por favor verifique su email!", "error");
      });
  },
  findTokenPasswordReset({ commit }, token) {
    commit('changeLoader', true);
    axios.get(`${configApi.apiUrl}password/find/${token}`, null)
      .then(response => {
        if (response.data.success) {
          commit('tokenResetPassword', true);
          commit('changeLoader', false);
        }
      })
      .catch(error => {
        commit('tokenResetPassword', false);
        commit('changeLoader', false);
        swal("Token incorrecto!", "El token ya caducó o es incorrecto!", "warning");
        console.error(error);
      });
  },
  resetPasswordToken({ commit }, payload) {
    // email
    // password
    // password_confirmation
    // token
    commit('changeLoader', true);
    axios.post(`${configApi.apiUrl}reset`, payload)
      .then(response => {
        commit('changeLoader', false);
        swal("Exito!", "La contraseña se restablecio correctamente!", "success");
        router.push('/');
      })
      .catch(error => {
        commit('changeLoader', false);
        swal("Error!", "Ocurrio un error al restablecer su contraseña!", "error");
      });
  },
  generarsha({ commit }, payload) {
    // Moneda
    // refventa
    commit('changeLoader', true);
    return axios.post(`${configApi.apiUrl}generarsha`, payload)
      .then(response => {
        commit('sha', response.data.data);
        commit('changeLoader', false);
        //swal("Exito!","La contraseña se restablecio correctamente!","success");
      })
      .catch(error => {
        commit('changeLoader', false);
        swal("Error!", "Ocurrio un error al obtener firma!", "error");
      });
  },
  obtener_refventa({ commit }, payload) {
    commit('changeLoader', true);
    return axios.post(`${configApi.apiUrl}obtener_refventa`, payload)
      .then(response => {
        //console.log(response.data);
        commit('get_init_point', response.data.init_point);
        if (!response.data.init_point || response.data.init_point === null ||
          response.data.init_point === undefined) {
          commit('changeLoader', false);
        }
        //swal("Exito!","La contraseña se restablecio correctamente!","success");
      })
      .catch(error => {
        commit('changeLoader', false);
        if (error.response.status == 402) {
          swal("Error!", error.response.data.message, "error");
          store.dispatch("evento/clearCantBoleta");
          store.dispatch("carrito/setNullPreciosBoletasCarrito");
          store.dispatch("carrito/dateTimeSaleStart", null);
          store.dispatch("carrito/dateTimeSaleEnd", null);
        }else if(error.response.status == 405){
          swal("Información", error.response.data.message, "info");
        } else {
          swal("Error!", "Ocurrio un error al obtener referencia de venta!", "error");
        }
      });
  },
  detalle_Boletas_Finalizarcompra({ state, commit }, payload) {
    state.resumenFinalizarCompra = null;
    state.errorDetalleCompra = false;
    commit('changeLoader', true);
    return axios.post(`${configApi.apiUrl}detalle_boletas_compra`, payload)
      .then(response => {
        commit('changeLoader', false);
        state.resumenFinalizarCompra = response.data
      })
      .catch(error => {
        commit('changeLoader', false);
        state.errorDetalleCompra = true;
        if (error.response.status == 402) {
          swal("Error!", error.response.data.message, "error");
        } else {
          swal("Error!", "Ocurrio un error al obtener referencia de venta!", "error");
        }
      });
  },

};