<template>
    <v-app>
        <div v-if="$store.state.responseDataPaymentV2" class="container-payment">
            <div class="col-12">
                <h2 class="my-4 w-100 text-center">Resumen transacción</h2>
            </div>
            <div class="w-100 px-5 d-flex mb-5">
                <button class="ml-auto btn btn-secondary" @click="$router.push({path: '/'})">Volver al inicio</button>
            </div>
            <div class="col-12 col-lg-8 order-2 order-md-1">
                <div class="card-resumen">
                    <div class="table-responsive">
                        <table class="tabla_resumen">
                            <tr>
                                <td><b>Orden</b></td>
                                <td>{{$route.query.order}}</td>
                            </tr>
                            <tr>
                                <td><b>Emisor</b></td>
                                <td>{{$store.state.responseDataPaymentV2.emisor}}</td>
                            </tr>
                            <tr>
                                <td><b>Código de autorización</b></td>
                                <td>{{$store.state.responseDataPaymentV2.authorization}}</td>
                            </tr>
                            <tr>
                                <td><b>Número de recibo</b></td>
                                <td>{{$store.state.responseDataPaymentV2.receipt}}</td>
                            </tr>
                            <tr>
                                <td><b>Descripción</b></td>
                                <td>{{$store.state.responseDataPaymentV2.description}}</td>
                            </tr>
                            <tr>
                                <td><b>Método de pago</b></td>
                                <td>{{$store.state.responseDataPaymentV2.payment_method}}</td>
                            </tr>
                            <tr>
                                <td><b>Franquicia</b></td>
                                <td>{{$store.state.responseDataPaymentV2.franchise}}</td>
                            </tr>
                            <tr>
                                <td><b>Estado</b></td>
                                <td>
                                    <v-icon v-if="$store.state.responseDataPaymentV2.estado_id == 1" left color="green">check</v-icon>
                                    <v-icon v-else left color="red">close</v-icon>
                                    {{$store.state.responseDataPaymentV2.status}}
                                </td>
                            </tr>
                            <tr>
                                <td><b>Código estado</b></td>
                                <td>{{$store.state.responseDataPaymentV2.reason_status}}</td>
                            </tr>
                            <tr>
                                <td><b>Descripción estado</b></td>
                                <td>{{$store.state.responseDataPaymentV2.message_status}}</td>
                            </tr>
                            <tr>
                                <td><b>Fecha transacción</b></td>
                                <td>{{$store.state.responseDataPaymentV2.date_status}}</td>
                            </tr>
                            <tr>
                                <td><b>Total</b></td>
                                <td>{{$store.state.responseDataPaymentV2.total}}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-4 px-3 px-md-4 order-1 order-md-2">
                <div class="card-resumen mb-3">
                    <h3 class="text-center w-100">Resumen de compra</h3>
                    <div class="divider-resumen"/>
                    <div style="border-bottom: 1px solid #9797973a" class="pb-2 d-flex align-items-center justify-content-between w-100 px-4 px-lg-2" :key="index" v-for="(product, index) in $store.state.responseDataPaymentV2.items">
                        <div>
                            <p class="mb-0">{{product.name}}</p>
                            <small style="color: gray">Cantidad: {{product.qty}}</small> <br>
                            <small style="color: gray">Id: {{product.sku}}</small>
                        </div>
                        <p class="mb-0 px-2"><b>{{product.price}}</b></p>
                    </div>
                    <div class="d-flex justify-content-between ms-auto my-4 pe-3 w-100">
                        <p style="font-size: 18px">TOTAL: </p>
                        <p style="font-size: 18px"><b>{{$store.state.responseDataPaymentV2.total}}</b></p>
                    </div>
                </div>
            </div>
        </div>
    </v-app>
</template>
<script>
export default {
    created() {
        document.title = 'Resumen de transacción | CENTRALTICKETS'
    },
    async mounted() {
        let query = this.$route.query.order
        if(query){
            await this.$store.dispatch('getSumarryOrderPaymentV2', {'order': query})
        }else{
            swal("Atención!", "No se ha podido validar la orden de compra", "warning");
            this.$router.replace({path: '/'})
        }
    },
}
</script>
<style>
.container-payment{
    width: 100vw;
    height: 100vh;
    max-width: 1600px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
}
.tabla_resumen{
    widows: 100%;
    margin-right: auto;
    margin-left: auto;
}
.tabla_resumen tr td{
    border: 1px solid gray;
    padding: .5rem 1rem;
}
</style>