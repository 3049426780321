<template>
  <v-app>
    <br />
    <br />
    <v-container grid-list-md class="col-md-4 col-md-offset-6">
      <div class="text-center col-md-12 col-lg-12 col-sm-12 col-xs-12">
        <img
          src="@/assets/images/logo-CentralTickets-black.png"
          style="max-width: 150px; width: 150px"
        />
      </div>
      <br />
      <br />
      <div class="text-center">
        <h4>
          <i class="material-icons">mail_outline</i> Ingrese el correo
          electronico de su cuenta.
        </h4>
      </div>
      <form @submit.prevent="resetPassword" autocomplete="off">
        <v-layout wrap>
          <v-flex xs12 sm12 md12 lg12>
            <v-text-field
              label="Correo electronico*"
              required
              v-model="email"
              :rules="emailRules"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm12 md12 lg12 class="text-center">
            <v-btn color="primary" type="submit">Enviar</v-btn>
          </v-flex>
        </v-layout>
      </form>
      <br />
      <hr />
      <div class="text-center">
        <v-btn color="success" @click="$store.state.modalLogin = true"
          >Iniciar Sesión</v-btn
        >
      </div>
    </v-container>
  </v-app>
</template>
<script>
export default {
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      //vm.$store.dispatch('fetchAccessToken');
      if (vm.$store.state.user.accessToken != null) {
        next("/");
      }
    });
  },
  created() {
    document.title = "Olvide mi contraseña - CentralTickets";
  },
  data() {
    return {
      email: null,
      emailRules: [
        (v) => /.+@.+/.test(v) || "Direccion de correo electronico incorrecta",
      ],
    };
  },
  methods: {
    resetPassword() {
      this.$store.dispatch("resetPassword", { email: this.email }).then(() => {
        this.email = null;
        this.$router.push("/");
      });
    },
  },
};
</script>
<style scoped>
</style>