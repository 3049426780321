<template>
  <v-app>
    <v-layout row justify-center>
      <v-dialog v-model="modalFactura" max-width="600px" persistent>
        <v-card>
          <div id="printFactura">
            <v-card-title>
              <div class="text-center col-md-12 col-lg-12 col-sm-12 col-xs-12">
                <img
                  src="@/assets/images/logo-CentralTickets.png"
                  style="max-width: 150px; width: 150px"
                />
                <h6 class="mt-1"><b>CENTRALTICKETS</b></h6>
                <h6 class="mt-1"><b>0993369710001</b></h6>
                <h6 class="mt-1"><b>ECUADOR</b></h6>
              </div>
            </v-card-title>
            <v-container>
              <v-card-text>
                <h6><b>FACTURA DE VENTA</b></h6>
                <h6>
                  <b>N°</b> {{ factura.n_factura }}&nbsp;&nbsp;<b>FECHA:</b>
                  {{ factura.fecha }}
                </h6>
                <h6><b>CLIENTE:</b> {{ factura.user.nombre }}</h6>
                <h6>
                  <b>TIPO IDENTIFICACION: </b
                  >{{ factura.user.tipo_identificacion_code }}
                  <small
                    >({{ factura.user.tipo_identificacion_descripcion }})</small
                  >
                </h6>
                <h6>
                  <b># IDENTIFICACION: </b>{{ factura.user.identificacion }}
                </h6>
                <h6><b>EMAIL:</b> {{ factura.user.email }}</h6>
                <h6><b>TELEFONO:</b> {{ factura.user.telefono }}</h6>
                <h6 class="text-uppercase">
                  <b>TIPO DE PAGO: </b>
                  <span v-if="factura.pago_parcial == 1">Parcial</span
                  ><span v-else>Completo</span>
                </h6>
                <h6 class="text-uppercase">
                  <b>FORMA PAGO:</b> {{ factura.datos_pago.formaPago }} ({{
                    factura.impuesto_venta.codigo_moneda
                  }})
                </h6>
                <h6
                  v-if="factura.datos_pago.formaPago != 'efectivo'"
                  class="text-uppercase"
                >
                  <b>TIPO TARJETA:</b> {{ factura.datos_pago.formaPagoTarjeta }}
                </h6>
                <h6 v-if="factura.datos_pago.formaPago != 'efectivo'">
                  <b>MONTO TARJETA:</b> {{ factura.impuesto_venta.simboloMoneda
                  }}{{ formatNumber(factura.datos_pago.montoTarjeta) }} ({{
                    factura.impuesto_venta.codigo_moneda
                  }})
                </h6>
                <h6 v-if="factura.datos_pago.formaPago != 'tarjeta'">
                  <b>MONTO EFECTIVO:</b>
                  {{ factura.impuesto_venta.simboloMoneda
                  }}{{ formatNumber(factura.datos_pago.montoEfectivo) }} ({{
                    factura.impuesto_venta.codigo_moneda
                  }})
                </h6>
                <h6
                  v-if="factura.datos_pago.formaPago != 'efectivo'"
                  class="text-uppercase"
                >
                  <b>COD. VOUCHER:</b> {{ factura.datos_pago.codeVoucher }}
                </h6>
                <h6><b>PUNTO DE VENTA:</b> {{ factura.punto_venta.nombre }}</h6>
                <h6>
                  <b>DIR. PUNTO VENTA:</b> {{ factura.punto_venta.direccion }}
                </h6>
                <h6
                  class="text-secondary mt-5"
                  v-if="factura.abonos_venta_parcial.length > 0"
                >
                  ABONOS REALIZADOS:
                </h6>
                <div class="table-responsive">
                  <table
                    class="table"
                    v-if="factura.abonos_venta_parcial.length > 0"
                  >
                    <thead>
                      <tr>
                        <th class="text-center">FORMA DE PAGO</th>
                        <th class="text-center">TIPO TARJETA</th>
                        <th class="text-center">MONTO EFECTIVO</th>
                        <th class="text-center">MONTO TARJETA</th>
                        <th class="text-center">COD VOUCHER</th>
                        <th class="text-center">FECHA</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        :key="index"
                        v-for="(item, index) in factura.abonos_venta_parcial"
                      >
                        <td class="text-center text-uppercase">
                          {{ item.forma_pago }}
                        </td>
                        <td class="text-center text-uppercase">
                          {{
                            item.forma_pago != "efectivo"
                              ? item.tipo_tarjeta
                              : ""
                          }}
                        </td>
                        <td class="text-center">
                          {{ factura.impuesto_venta.simboloMoneda
                          }}{{ formatNumber(item.monto_efectivo) }} ({{
                            factura.impuesto_venta.codigo_moneda
                          }})
                        </td>
                        <td class="text-center">
                          <span v-if="item.forma_pago != 'efectivo'"
                            >{{ factura.impuesto_venta.simboloMoneda
                            }}{{ formatNumber(item.monto_tarjeta) }} ({{
                              factura.impuesto_venta.codigo_moneda
                            }})</span
                          >
                        </td>
                        <td class="text-center">{{ item.code_voucher }}</td>
                        <td class="text-center">{{ item.created_at }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <h6 class="text-secondary mt-5">RESUMEN DE COMPRA:</h6>
                  <table class="table">
                    <thead>
                      <tr>
                        <th class="text-center">DESCRIPCION</th>
                        <th class="text-center">VALOR</th>
                        <th class="text-center">CANTIDAD</th>
                        <th class="text-center">TOTAL</th>
                        <th class="text-center">IMPRIMIR</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        :key="index2"
                        v-for="(item, index2) in factura.productosFactura"
                      >
                        <td class="text-center">{{ item.descripcion }}</td>
                        <td class="text-center">
                          {{ factura.impuesto_venta.simboloMoneda
                          }}<span
                            v-if="factura.datos_pago.formaPago != 'cortesia'"
                            >{{ formatNumber(item.valor) }}</span
                          ><span v-else>0</span> ({{
                            factura.impuesto_venta.codigo_moneda
                          }})
                        </td>
                        <td class="text-center">{{ item.cantidad }} un</td>
                        <td class="text-center">
                          {{ factura.impuesto_venta.simboloMoneda
                          }}<span
                            v-if="factura.datos_pago.formaPago != 'cortesia'"
                            >{{
                              formatNumber(item.cantidad * item.valor)
                            }}</span
                          ><span v-else>0</span> ({{
                            factura.impuesto_venta.codigo_moneda
                          }})
                        </td>
                        <td class="text-center">
                          <div
                            :id="'container_imprimir_Boleta_' + index2"
                            class="d-none"
                          />
                          <v-icon
                            v-if="factura.impresion_boletas == 1"
                            @click="
                              factura.impresion_boletas == 1
                                ? imprimirBoletas(factura, index2)
                                : ''
                            "
                            class="icon-imprimir-boleta"
                            color="ligth darken-2"
                          >
                            mdi-printer
                          </v-icon>
                          <v-icon
                            v-else
                            @click="showAlertImpresion()"
                            class="icon-imprimir-boleta"
                            color="ligth darken-2"
                          >
                            mdi-printer-off
                          </v-icon>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <br />
                <br />
                <hr />
                <div class="text-right">
                  <h5>
                    <b
                      >SUB - TOTAL: {{ factura.impuesto_venta.simboloMoneda
                      }}<span
                        v-if="factura.datos_pago.formaPago != 'cortesia'"
                        >{{ formatNumber(factura.subtotal) }}</span
                      ><span v-else>0</span></b
                    >
                    ({{ factura.impuesto_venta.codigo_moneda }})
                  </h5>
                  <h5>
                    <b
                      >+{{ factura.impuesto_venta.descripcion }} ({{
                        factura.impuesto_venta.porcentaje * 100
                      }}%):
                    </b>
                    {{ factura.impuesto_venta.simboloMoneda
                    }}{{
                      formatNumber(factura.impuesto_venta.valorImpuesto)
                    }}
                    ({{ factura.impuesto_venta.codigo_moneda }})
                  </h5>
                  <hr />
                  <h5>
                    <b
                      >TOTAL: {{ factura.impuesto_venta.simboloMoneda
                      }}<span
                        v-if="factura.datos_pago.formaPago != 'cortesia'"
                        >{{ formatNumber(factura.total) }}</span
                      ><span v-else>0</span>
                    </b>
                    ({{ factura.impuesto_venta.codigo_moneda }})
                  </h5>
                </div>
                <br />
                <br />
                <br />
                <!--<div class="text-center text-secondary">
          	<h6>AUTORIZACION NUMERICA DE FACTURACION</h6>
          	<h6>1877732109678</h6>
          	<h6>2019-09-05</h6>
          	<h6>DEL 1 AL 10000</h6>
          	<h6>www.centraltickets-ecuador.com</h6>
          </div>-->
              </v-card-text>
            </v-container>
          </div>
          <hr />
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" @click="printFactura"
              >IMPRIMIR FACTURA</v-btn
            >
            <v-btn color="darken-1" @click="hideModalFactura">Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-app>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    printFactura() {
      // Pass the element id here
      this.$htmlToPaper("printFactura");
    },
    formatNumber(number) {
      return new Intl.NumberFormat("es-CO", {
        minimumFractionDigits: 0,
      }).format(number);
    },
    hideModalFactura() {
      this.$store.dispatch("carrito/showFactura", false);
    },
    async imprimirBoletas(boletasImprimir, index) {
      document.getElementById("container_imprimir_Boleta_" + index).innerHTML =
        "";
      await this.$store
        .dispatch(
          "carrito/getInfoBoletaImpresion",
          boletasImprimir.productosFactura[index]
        )
        .then(() => {
          if (!this.$store.state.carrito.errorGetBoleta) {
            document.getElementById(
              "container_imprimir_Boleta_" + index
            ).innerHTML = this.$store.state.carrito.imprimirBoletas;
            this.$htmlToPaper("container_imprimir_Boleta_" + index);
            setTimeout(() => {
              document.getElementById(
                "container_imprimir_Boleta_" + index
              ).innerHTML = "";
            }, 2000);
          }
        });
    },
    showAlertImpresion() {
      swal(
        "Información!",
        "Esta venta no tiene permitido realizar impresiones físicas de boletas.",
        "info"
      );
    },
  },
  computed: {
    factura() {
      return this.$store.getters["carrito/factura"];
    },
    modalFactura() {
      return this.$store.getters["modalFactura"];
    },
  },
};
</script>
<style>
.font-weight-bold {
  font-weight: bold;
}
.icon-imprimir-boleta {
  cursor: pointer;
  font-size: 1.8rem !important;
}
.icon-imprimir-boleta:hover {
  color: rgb(75, 75, 75) !important;
}
</style>