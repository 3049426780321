<template>
    <v-app>
        <div class="container-payment">
            <h2 class="my-4 w-100 text-center">Finalizar compra</h2>
            <div class="w-100 d-flex flex-wrap">
                <div class="w-100 px-5 d-flex mb-5"  v-if="$store.state.formulariopago">
                    <button class="ml-auto btn btn-danger" @click="$router.replace({path: '/'})">Cancelar transacción</button>
                </div>
                <div class="col-12 col-lg-8">
                    <div class="card-resumen">
                        <iframe v-if="$store.state.dataPayment.url_pago" class="iframe-payment" :src="$store.state.dataPayment.url_pago"></iframe>
                     </div> 
               </div> 
               <div class="col-12 col-lg-8"  v-if="$store.state.dataPayment.metodo_pago=='Pagar con Kushki'"  >
                <div  >
             <div class="row">
                <div class="col-12 col-lg-2" >


                    
</div>
      



<div     v-if="$store.state.formulariopago"    class="col-12 col-lg-8" style=" border: 1px solid #E1E1E1;  border-radius: 10px; -webkit-box-shadow: 11px 11px 5px -8px rgba(224,224,224,1);
-moz-box-shadow: 11px 11px 5px -8px rgba(224,224,224,1);box-shadow: 11px 11px 5px -8px rgba(224,224,224,1); " >

            <div style="   margin:15px 15px 15px 15px;">
                <v-form   id="form-pqr"
               ref="form_pqr"
               enctype="multipart/form-data"
               @submit.prevent="sendCompra"
             >
               
                
                <div class="row">  
                 <div class="col-12 col-lg-12" >
                <label class="tarjetacred" >   
                    <input   class="tarjetacredinput" type="text"  
                            v-model="checkoutkushki.numerotarjeta"
                            :rules="rules.tarjeta"
                            ref="mytarjeta"
                            maxlength="19" autocomplete="off" 
                            placeholder="Número de tarjeta"
                            v-on:keyup="onKeyPress"
                    />
                    <img  class="logo" src="../../../../public/images/logotarjeta.png"  >
                </label>
            </div>
            <div class="col-12 col-lg-12" >
                <label class="tarjetacred" >   
                    <input   class="nombreinput" type="text"  
                     v-model="checkoutkushki.nombres"  :rules="rules.nombres"
                     ref="mynombre"
                     v-on:keyup="onKeymayuscula"
                    maxlength="50" autocomplete="off" placeholder="Nombre"/>
                </label>

            </div>
                <div class="col-6 col-lg-6" >
                <label class="tarjetacred" >   
                    <input   
                    class="nombreinput" type="text"  
                    v-model="checkoutkushki.mesanno" 
                     :rules="rules.anno"
                     ref="myfecha"
                    maxlength="5" 
                    v-on:keyup="onKeyPressfecha"
                    autocomplete="off" placeholder="MM/YY"/>
                </label> 
            </div>
            <div class="col-6 col-lg-6" >
                <label class="tarjetacred" >   
                    <input   class="nombreinput" 
                    v-model="checkoutkushki.cvc"
                     :rules="rules.cvc"
                     ref="mycvc"
                    type="text"  maxlength="50" 
                    autocomplete="off" 
                    placeholder="CVC"/>
                </label>
            </div>
        </div>

                <v-btn
                    block
                    type="submit"
                    class="ma-2 ml-btn "
                    color="primary"
                    large
                  
                    :disabled='checkoutkushki.isDisabled' 
                  >
                    
                    <p class="mt-4"><strong> Pagar  {{$store.state.dataPayment.total}}</strong> </p>
                  </v-btn>
                  
                  
                 
                <div style="text-align: center;"> Funciona con <img class="logo" src="../../../../public/images/kushki_logo.png"  ></div> 
            </v-form>  </div></div>
           

              
<div     v-if="$store.state.validarotp"    class="col-12 col-lg-8" style=" border: 1px solid #E1E1E1;   border-radius: 10px; -webkit-box-shadow: 11px 11px 5px -8px rgba(224,224,224,1);
-moz-box-shadow: 11px 11px 5px -8px rgba(224,224,224,1);box-shadow: 11px 11px 5px -8px rgba(224,224,224,1);margin:0px 0px 0px 0px;  padding:0px 0px 0px 0px;"   >

<div style="background:#F1F4F7; font-weight:bold; border-radius: 10px 10px 0px 0px; padding:0px 0px 0px 0px; ">
<h5 style=" padding:10px 10px 10px 10px; "> <strong>Código de verificación</strong></h5>
</div>
            <div style="   margin:15px 15px 15px 15px;">
                
                <div class="row">  
                 <div class="col-12 col-lg-12" >

                    <p  style="   font-size:16px;     font-family:Verdana;" >  Por favor ingresa el código de seguridad de 3 dígitos correspondiente al monto que acabamos de cobrar en tu tarjeta.</p>
 
                    </div>
                    <div class="col-12 col-lg-12" >
                <label class="tarjetacred"   style="   font-size:16px;     font-family:Verdana;" >   
                    <input   class="tarjetacredinput" type="text" 
                            id="inputNumero" name="inputNumero" 
                            value="Pago con Kushki" readonly
                              autocomplete="off"  
                    />
                    {{$store.state.dataPayment.total}}
                </label>
            </div>
                
            <div class="col-3 col-lg-3" ></div>
            <div class="col-2 col-lg-2" >
                <label class="tarjetacredotp" >   
                    <input   
                    class="optinput" type="text" 
                    id="inputopt1" 
                    name="inputopt1"  
                    ref="myotp1"
                    maxlength="1"  
                    v-model="checkoutkushki.otp1" 
                    v-on:keyup="onKeyPressotp1"
                    :disabled='checkoutkushki.isDisabledotp' 
                    autocomplete="off" placeholder=""/>
                </label> 

            </div>
            <div class="col-2 col-lg-2" >
                <label class="tarjetacredotp" >   
                    <input   
                    class="optinput" type="text" 
                    id="inputopt2" 
                    name="inputopt2" 
                    ref="myotp2" 
                    maxlength="1" 
                    v-model="checkoutkushki.otp2" 
                    v-on:keyup="onKeyPressotp2"
                    :disabled='checkoutkushki.isDisabledotp' 
                    autocomplete="off" placeholder=""/>
                </label> 
            </div>
            <div class="col-2 col-lg-2" >
                <label class="tarjetacredotp" >   
                    <input   
                    class="optinput" type="text" 
                    id="inputopt3" 
                    name="inputopt3"  
                    ref="myotp3"
                    maxlength="1"  
                    v-model="checkoutkushki.otp3" 
                    v-on:keyup="onKeyPressotp3"
                    :disabled='checkoutkushki.isDisabledotp' 
                    autocomplete="off" placeholder=""/>
                </label> 
            </div>
            <div class="col-3 col-lg-3" ></div>
                
                
                </div>
        </div>
        </div>

                  
               


            <div   v-if="$store.state.resultadopago"   class="col-12 col-lg-8" style=" border: 1px solid #E1E1E1;  border-radius: 10px; -webkit-box-shadow: 11px 11px 5px -8px rgba(224,224,224,1);
-moz-box-shadow: 11px 11px 5px -8px rgba(224,224,224,1);box-shadow: 11px 11px 5px -8px rgba(224,224,224,1); " >
            <div style="   margin:15px 15px 15px 15px;">
              <h2 class="text-center mb-5">RESULTADO TRANSACCIÓN</h2>
               
              
              <table width="100%" border="0" cellspacing="0" cellpadding="0">
   
  <tr>
    <td><strong>Estado De la Operacion </strong></td>
    <td>&nbsp;{{ checkoutkushki.operacoinestado }}</td>
  </tr>
  <tr>
    <td><strong>Valor de la Operacion</strong></td>
    <td>&nbsp;$ {{ checkoutkushki.operacoinvalor }} USD</td>
  </tr>
  <tr>
    <td><strong> </strong></td>
    <td>&nbsp;</td>
  </tr>
  
</table>


               </div>
            </div> 

            

            <div class="col-12 col-lg-2" ></div>
 </div> 


</div>
                   
                </div>
                <div class="col-12 col-lg-4 px-3 px-md-4">
                    <div class="card-resumen">

                        <v-alert  v-if="$store.state.formulariopago"
                        dense
                        border="left"
                        type="warning"
                        >
                            <b> Su tiempo de compra finalizará a las: {{ tiempo_expira }} </b>
                        </v-alert>

                        <h3 class="text-center w-100">Resumen de compra</h3>
                        <div class="divider-resumen"/>
                        <div style="border-bottom: 1px solid #9797973a" class="pb-2 d-flex align-items-center justify-content-between w-100 px-4 px-lg-2" :key="index" v-for="(product, index) in $store.state.dataPayment.productos">
                            <div>
                                <p class="mb-0 pt-3">{{product.descripcion}}</p>
                                <small style="color: gray">Cantidad: {{product.cantidad}}</small> <br>
                                <small style="color: gray">Id: {{product.id}}</small>
                            </div>
                            <p class="mb-0 px-2"><b>{{product.precio}}</b></p>
                        </div>
                        <div class="d-flex justify-content-between ms-auto my-4 pe-3 w-100">
                            <p style="font-size: 18px">TOTAL: </p>
                            <p style="font-size: 18px"><b>{{$store.state.dataPayment.total}}</b></p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </v-app>



  






</template>
<script src="https://cdn.kushkipagos.com/kushki-checkout.js">       </script> 
<script>
import {configApi} from '../../../api/configApi';
import {headers} from '../../../api/headers';
import moment from "moment";
import axios from 'axios'; 
moment.locale("es");

export default {
    data() {
        return {
            checkoutkushki: {
                     tarjeta:"",
                     numerotarjeta:"",
                     mesanno:"",
                     diferidos:[],
                     mes: null, 
                     anno: null, 
                     otp: null, 
                     otp1: null, 
                     otp2: null, 
                     otp3: null, 
                     cvc: null,
                     nombres:"", 
                     divisa: 0,
                     idventa: 0,
                     token:'',
                     isDisabled:false, 
                     isDisabledotp:false,
                     isDisabledloadin:false, 
                     descuento: 0 ,
                     btnlOTP:false,
                     secureId:null,
                     token:null,
                     operacoinestado: null,
                     operacoinvalor: null,



            },
            rules: {
                nombres: [
                (v) => !!v || "El campo nombres es requerido",
                (v) =>
                    /\w{3}/.test(v) ||
                    "El campo nombre debe tener al menos 15 caracteres",
                ],
                tarjeta: [
                (v) => !!v || "El campo tarjeta es requerido",
                (v) =>
                    /\w{3}/.test(v) ||
                    "El campo tarjeta debe tener al 16 caracteres",
                    
                ],
                mes: [
                (v) => !!v || "El campo mes es requerido" 
                ],
                anno: [
                (v) => !!v || "El campo mes es requerido" 
                ],
                cvc: [
                (v) => !!v || "El campo mes es requerido" 
                ],
                diferidos: [
                (v) => !!v || "El campo mes es requerido" 
                ]
           
           },
           itemsmes:['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
           tiempo_expira: '' 
        }
    },
    created(){
        document.title = "Finalizar compra"
    },
    async mounted() {

        this.convertirFechaPago()

        if(window.localStorage.getItem('fecha_pago')){
            this.dataF = window.localStorage.getItem('fecha_pago')
            this.validateTime()
        }else{
            this.$router.replace({path: '/'})            
        }
        await this.$store.dispatch('getOrderPayment', {'order': this.$route.query.order})

    },
    
    beforeDestroy(){
        this.$store.state.dataPayment = null;
    },
    methods: {
        onKeyPressfecha(event) {
            this.checkoutkushki.mesanno= this.checkoutkushki.mesanno.replace(/\s/g, '')
            // Eliminar las letras
            this.checkoutkushki.mesanno=this.checkoutkushki.mesanno.replace(/\D/g, '')
            // Ponemos espacio cada cuatro numeros
            this.checkoutkushki.mesanno=this.checkoutkushki.mesanno.replace(/([0-9]{2})/g, '$1/')
            // Elimina el ultimo espaciado
            this.checkoutkushki.mesanno =  this.checkoutkushki.mesanno.slice(0, 5) + ' ';
            this.checkoutkushki.mesanno=this.checkoutkushki.mesanno.trim();
            if(this.checkoutkushki.mesanno.charAt(0)>1   ){
               this.checkoutkushki.mesanno=  '';
            }
          
            
        },
        onKeyPress(event) {
            this.checkoutkushki.numerotarjeta= this.checkoutkushki.numerotarjeta.replace(/\s/g, '')
            // Eliminar las letras
            this.checkoutkushki.numerotarjeta=this.checkoutkushki.numerotarjeta.replace(/\D/g, '')
            // Ponemos espacio cada cuatro numeros
            this.checkoutkushki.numerotarjeta=this.checkoutkushki.numerotarjeta.replace(/([0-9]{4})/g, '$1 ')
            // Elimina el ultimo espaciado
            this.checkoutkushki.numerotarjeta=this.checkoutkushki.numerotarjeta.trim();
                if( this.checkoutkushki.numerotarjeta.length==18){
                    
                }
            },

       onKeymayuscula(event){
        this.checkoutkushki.nombres=   this.checkoutkushki.nombres.toUpperCase();
       },

        onKeyPressotp1(event){
             this.$refs.myotp2.focus()
        },
        onKeyPressotp2(event){
            this.$refs.myotp3.focus()
        },
        async onKeyPressotp3(event){
            this.checkoutkushki.isDisabledotp=true;
            this.validarOtp();

        },
        async validateTime(){
            let fecha = new Date();
            this.dataP = fecha
            if(window.localStorage.getItem('fecha_pago') <= fecha){
                window.localStorage.removeItem('fecha_pago');
                this.$store.state.dataPayment = null;
                this.$router.replace({path: '/'});
            }else{
                await setTimeout(()=>{
                    this.validateTime()
                }, 1000)
            }
        },
        convertirFechaPago() {

            let timestamp = parseInt(window.localStorage.getItem('fecha_pago'))

            this.tiempo_expira = new Date(timestamp).toLocaleTimeString("co-CO")
            
        },

        async validarOtp() {
                  this.checkoutkushki.btnlOTP=true;
                  const  otp =  this.checkoutkushki.otp1+''+this.checkoutkushki.otp2+''+this.checkoutkushki.otp3;
                  console.log(otp);
                  const optionsotp = {
                                            method: 'POST',
                                            url: 'https://api-uat.kushkipagos.com/rules/v1/secureValidation',
                                            headers: {
                                                'Content-Type': '',
                                                'Public-Merchant-Id': '7c24b9c2ca0a4b27b5da7dc4426f11ea'
                                            },
                                            data: {
                                                     secureServiceId:   this.checkoutkushki.secureId , otpValue: otp
                                                   }
                                    };
                  const response =   await axios.request(optionsotp);
                  console.log(response.data.code);
                  if(response.data.code=='OTP000'){ 
                               const  idventa =  this.$store.state.dataPayment.venta_id ;
                               const article = { token: this.checkoutkushki.token, ventaid:idventa };
                               axios.post(`${configApi.apiPagosUrl}kushki/ecuador/getCargos`,article,{headers: headers})
                                .then(response => {
                                    console.log(response.data);
                                    this.checkoutkushki.operacoinestado= response.data.message.details.responseText;
                                    this.checkoutkushki.operacoinvalor=  response.data.message.details.requestAmount;

                                     this.checkoutkushki.isDisabled=false ;
                                     this.$store.state.resultadopago=true;
                                     this.$store.state.validarotp=false; 
                                     this.$store.state.formulariopago=false; 

                                    
                                })
                                .catch(error => {
                                    this.checkoutkushki.isDisabled=false ;

                                    swal("Error!", "Problemas de conexion con el servidor...!!!", "error");
                                });


                  }else{
                    swal("Error!", "CODIGO DE VERIFICACION ERRADO ...!!!", "error");
                    
                    this.checkoutkushki.isDisabledotp=false;
                    this.checkoutkushki.otp1='';
                    this.checkoutkushki.otp2='';
                    this.checkoutkushki.otp3='';
                    this.checkoutkushki.btnlOTP=false;

                  }



        },

        async sendCompra() {
           
             

                const  tarjeta  =  this.checkoutkushki.numerotarjeta.replace(/\s+/g, ''); 
                if( tarjeta.length!=16){
                    swal(
                            "Errores de validación",
                            "El numero de Tarjeta No es correcto",
                            "error"
                        );
                        this.$refs.mytarjera.focus()
                        return false;
                }
                const  nombres =  this.checkoutkushki.nombres.replace(/\s+/g, '');
                if( nombres.length<8){
                    swal(
                            "Errores de validación",
                            "Debes Ingresar Un nombre Valido",
                            "error"
                        );
                        this.$refs.mynombre.focus()
                        return false;
                }
                const  mesanno =  this.checkoutkushki.mesanno.replace(/\s+/g, '');
                if( mesanno.length<5){
                    swal(
                            "Errores de validación",
                            "Debes Ingresar Una fecha valida",
                            "error"
                        );
                        this.$refs.myfecha.focus()
                        return false;
                }

                const  cvc =  this.checkoutkushki.cvc.replace(/\s+/g, '');
                if( cvc.length<3){
                    swal(
                            "Errores de validación",
                            "Debes Ingresar Un CVC valido",
                            "error"
                        );
                        this.$refs.mycvc.focus()
                        return false;
                }


                this.checkoutkushki.isDisabled=true ;
                this.checkoutkushki.isDisabledloadin=true;
                this.checkoutkushki.loading='';
               
               
                const  mes =  this.checkoutkushki.mesanno.charAt(0)+''+this.checkoutkushki.mesanno.charAt(1);
                const  anno =   this.checkoutkushki.mesanno.charAt(3)+''+this.checkoutkushki.mesanno.charAt(4); 
                const  monto = this.$store.state.dataPayment.valor;
                const  divisa =  this.$store.state.dataPayment.moneda ;
                const  idventa =  this.$store.state.dataPayment.venta_id ;
                var   article ='';
               
                this.$store.state.validarotp=false;
                try {
                    const options = {
                        method: 'POST',
                        url: 'https://api-uat.kushkipagos.com/card/v1/tokens',
                        headers: {'Content-Type': '', 'Public-Merchant-Id': '7c24b9c2ca0a4b27b5da7dc4426f11ea'},
                        data: {
                            card: {
                                name: nombres,
                                number: tarjeta,
                                expiryMonth: mes,
                                expiryYear: anno,
                                cvv: cvc
                            },
                            totalAmount:monto,
                            currency: divisa
                          }
                        };      
                        

                       
                            const response =   await axios.request(options)  
                                        if(response.status==400){
                                            swal( "Error.",   "El cuerpo de la petición es inválido",  "error"   ); 
                                            this.checkoutkushki.isDisabled=false ;
                                            return false;
                                        }
                                        if(response.status==402){
                                            swal( "Error.",   "Tarjeta no habilitada por el emisor",  "error"   ); 
                                            this.checkoutkushki.isDisabled=false ;
                                            return false;
                                        }
                                        if(response.status==500){
                                            swal( "Error.",   "Ha ocurrido un error inesperado",  "error"   ); 
                                            this.checkoutkushki.isDisabled=false ;
                                            return false;
                                        }


                                        if(response.data.secureId){
                          
                                            this.checkoutkushki.isDisabledloadin=false;
                                            this.$store.state.validarotp=true; 
                                            this.$store.state.formulariopago=false; 
                                            this.checkoutkushki.token= response.data.token;
                                            this.checkoutkushki.secureId=response.data.secureId;
                                            
                                        }else{

                                                article = { token: response.data.token, ventaid:idventa };
                                                axios.post(`${configApi.apiPagosUrl}kushki/ecuador/getCargos`,article,{headers: headers})
                                                .then(response => {
                                                    console.log(response.data);
                                                    this.checkoutkushki.isDisabled=false ;
                                                    
                                                })
                                                .catch(error => {
                                                    this.checkoutkushki.isDisabled=false ;
                                                    swal("Error!", "Problemas de conexion con el servidor...!!!", "error");
                                                });
                                        }   
                      





                             } catch (error) {
                                console.log(error.code);
                                swal( "Error.",  'Ha ocurrido un error al validar el pago verifica e intenta de nuevo',  "error"   ); 
                                this.checkoutkushki.isDisabled=false ;
                                return false;
                                
                            }

 
                       
                              
                       




                       
                         
                       
                            


        }
    }
}
</script>
<style>
.tarjetacred {
border:1px #E4EBE2 solid;
border-radius: 10px;
margin:5px 5px 5px 5px;
padding:5px 5px 5px 5px; 
width:100%;
}
.tarjetacredinput { 
    border:1px #FFFFFF solid;
    font-size:18px;
    padding:5px 5px 5px 5px; 
    width:80%;
    font-family:Verdana;
}
.nombreinput { 
    border:1px #FFFFFF solid;
    font-size:18px;
    padding:5px 5px 5px 5px; 
    width:100%;
    font-family:Verdana;
}

.tarjetacredotp {
border:1px #FF3399 solid;
border-radius: 10px;
margin:5px 5px 5px 5px;
padding:5px 5px 5px 5px; 
width:100%;
}

.optinput { 
    border:1px #FFFFFF solid;
    font-size:24px;
    padding:5px 5px 5px 5px; 
    width:100%;
    font-family:Verdana;
    text-align:center
}
.fechacred {
border:1px #E4EBE2 solid;
border-radius: 10px;
margin:5px 5px 5px 5px;
padding:5px 5px 5px 5px; 
width:50%;
}
.cvccred {
border:1px #E4EBE2 solid;
border-radius: 10px;
margin:5px 5px 5px 5px;
padding:5px 5px 5px 5px; 
width:50%;
}

.container-payment{
    width: 100vw;
    min-height: 100vh;
    height: 100% !important;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.iframe-payment{
    max-width: 1100px;
    width: 100%;
    min-height: 800px;
    border: none;    
}
.card-resumen{
    display: flex;
    flex-wrap: wrap;
    max-width: 1600px;
    margin-right: auto;
    margin-left: auto;
    border: 1px solid #9797974b;
    margin-bottom: 2rem;
    padding: 2rem;
    box-shadow: 0 0 10px 1px #9797973a;
}
.divider-resumen{
    width: 100%;
    height: 1px;
    background-color: rgb(216, 216, 216);
    margin-bottom: 1rem;
}
@media (max-width: 768px) {
    .iframe-payment{
        padding-right: 0;
        padding-left: 0;
        min-height: 1000px;
    }
    .card-resumen{
        padding: .5rem;
    }
}
 
</style>