<template>
  <v-app>
    <v-container v-if="link != null">
      <v-layout row wrap>
        <v-flex lg12 md12 sm12 xs12>
          <div class="mb-3 mt-3">
            <h2>Ver evento</h2>
          </div>
          <div>
            <span
              ><router-link :to="{ path: '/' }" title="Inicio"
                ><u>Inicio</u></router-link
              ></span
            ><span> > </span><span>ver evento</span>
          </div>
          <div class="mt-3 mb-4"></div>
        </v-flex>
      </v-layout>
      <v-divider></v-divider>
      <h4>Para acceder al evento da click en el siguiente boton:</h4>
      <h6>
        Este acceso es responsabilidad del usuario comprador de esta entrada
        digital. Es de suma importancia que el usuario mantenga confidencialidad
        de esta informacion.
      </h6>
      <div class="text-center mt-3">
        <v-btn color="primary" @click="goEvent">ACCEDER AL EVENTO</v-btn>
      </div>
    </v-container>
  </v-app>
</template>
<script>
import moment from "moment";
moment.locale("es");
export default {
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      //vm.$store.dispatch('fetchAccessToken');
      if (
        (vm.$route.query.medium === undefined ||
          vm.$route.query.medium == "") &&
        (vm.$route.query.login === undefined || vm.$route.query.login == "") &&
        (vm.$route.query.reason === undefined || vm.$route.query.reason == "")
      ) {
        next("/");
      } else {
        vm.$store
          .dispatch("evento/eventoStream", {
            medium: vm.$route.query.medium,
            login: vm.$route.query.login,
            reason: vm.$route.query.reason,
          })
          .then((response) => {
            if (response != undefined && response != null && response != "") {
              vm.link = response;
            } else {
              next("/");
            }
          });
      }
    });
  },
  data() {
    return {
      link: null,
    };
  },
  async beforeCreate() {
    document.title = "Ver evento streaming - CentralTickets";
  },
  created() {},
  methods: {
    goEvent() {
      window.location.href = this.link;
    },
  },
  computed: {},
};
</script>
<style>
</style>