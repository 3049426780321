import { render, staticRenderFns } from "./RouterMisEventos.vue?vue&type=template&id=1fea8162&"
var script = {}
import style0 from "./RouterMisEventos.vue?vue&type=style&index=0&id=1fea8162&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports