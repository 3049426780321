<template>
  <v-app id="footer-ticket">
    <footer class="mt-0">
      <div class="footer row row-clearfix">
        <div class="col-md-3 col-lg-3 cont-mini-footer mb-5">
          <h5 class="title-cont-mini-footer"><b>Siguenos</b></h5>
          <br>
          <v-layout row wrap class="list-social-links">
            <v-flex lg3 md3 sm3 xs3>
              <a href="https://www.facebook.com/Central-Tickets-106677298612796" target="_blank">
                <font-awesome-icon :icon="['fab', 'facebook-square']" size="2x"/>
              </a>
            </v-flex>
            <!-- <v-flex lg3 md3 sm3 xs3>
              <a href="https://twitter.com/TicketShopCol" target="_blank">
                <font-awesome-icon :icon="['fab', 'twitter']" size="2x"/>
              </a>
            </v-flex> -->
            <v-flex lg3 md3 sm3 xs3>
              <a href=" https://www.instagram.com/centralticketsec/" target="_blank">
                <font-awesome-icon :icon="['fab', 'instagram']" size="2x"/>
              </a>
            </v-flex>
            <!-- <v-flex lg3 md3 sm3 xs3>
              <a href="https://www.youtube.com/channel/UCmYdWEN9mbjAR27TuP9gEwg" target="_blank">
                <font-awesome-icon :icon="['fab', 'youtube']" size="2x"/>
              </a>
            </v-flex> -->
          </v-layout>
        </div>
        <div class="col-md-3 col-lg-3 cont-mini-footer">
          <h5 class="title-cont-mini-footer"><b>Quiénes somos</b></h5>
          <br>
          <ul class="list-unstyled list-menu-links">
            <li>
              <router-link :to="{path: '/'}" title="Inicio">
                Inicio
              </router-link>
            </li>
            <li>
              <router-link :to="{path: '/mision'}" title="Mision">
                Misión y Visión
              </router-link>
            </li>

            <li>
              <router-link :to="{path: '/comunicados'}" title="Comunicados">
                Comunicados
              </router-link>
            </li>
            <li>
              <router-link :to="{path: '/contacto'}" title="Contactanos">
                Contáctanos
              </router-link>
            </li>
            <br>
            <!-- <li>
              <router-link :to="{path: '/puntosVenta'}" title="PUNTOS DE VENTA">
                PUNTOS DE VENTA
              </router-link>
            </li> -->
          </ul>
        </div>

        <div class="col-md-3 col-lg-3 cont-mini-footer">
          <h5 class="title-cont-mini-footer"><b>CentralTickets</b></h5>
          <br>
          <ul class="list-unstyled list-menu-links">
            <li>
              <router-link :to="{path: '/terminoscondiciones'}" title="Términos y Condiciones">
                Términos y Condiciones
              </router-link>
            </li>
            <li>
              <router-link :to="{path: '/pqr'}" title="Solicitud de Peticiones, Quejas y Reclamos">
                Solicitud de Peticiones, Quejas y Reclamos
              </router-link>
            </li>
            <li>
              <a href="https://centraltickets-ecuador.com/media/Ley-Organica-de-Datos-Personales.pdf" target="_blank"
                           title="Ley orgánica de tratamiento de datos personales">
                Ley orgánica de tratamiento de datos personales
              </a>
            </li>
            <li>
              <a href="https://www.sri.gob.ec/web/intersri/home">RSI</a>
            </li>
            <li>
              <router-link :to="{path: '/preguntas-frecuentes'}" title="Preguntas frecuentes">
                Preguntas frecuentes
              </router-link>
            </li>
          </ul>
        </div>
        <div class="col-md-3 col-lg-3 cont-mini-footer text-center">
          <h5 class="title-cont-mini-footer"><b>Si continua en esta página acepta los</b></h5>
          <router-link :to="{path: '/terminoscondiciones'}" title="Términos y Condiciones" class="title-cont-mini-footer">Términos y condiciones de uso</router-link>
        </div>
      </div>
      <!-- <section class="aliados slider">
        <div class="slide div-slide-alidos" title="" v-for="aliado in aliados">
          <img :src="aliado.src" class="img-slide-aliados">
        </div>
      </section> -->
      <br>
      <section>
        <div class="copyright">
          <router-link :to="{path: '/'}" class="navbar-brand" title="CentralTickets" tag="a">
            <img class="logo" src="../../../public/images/logo-CentralTickets.png" height="60px" width="80px">
          </router-link>
          <small>centraltickets-ecuador.com {{ new Date().getFullYear() }} Ecuador. Todos los derechos
          </small><br>

        </div>
      </section>

    </footer>
  </v-app>
</template>
<script>

export default {
  mounted() {
    $(document).ready(function () {
      $('.aliados').slick({
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,
        dots: false,
        pauseOnHover: false,
        responsive: [{
          breakpoint: 768,
          settings: {
            slidesToShow: 4
          }
        }, {
          breakpoint: 520,
          settings: {
            slidesToShow: 3
          }
        }],
        variableWidth: true,
      });
    });
  },
  data() {
    return {
      aliados: [
        {
          src: '/images/aliados/Acosta-Velasquez-Eventos.png'
        },
        {
          src: '/images/aliados/cenfer.png'
        },
        {
          src: '/images/aliados/cpeventoslogo.png'
        },
        {
          src: '/images/aliados/donpagologo.png'
        },
        {
          src: '/images/aliados/Herazo-Eventos.png'
        },
        {
          src: '/images/aliados/ideartes.png'
        },
        {
          src: '/images/aliados/Santa-Fe-escudo-2016.png'
        },
        {
          src: '/images/aliados/zona-f-logo.png'
        }
      ]
    }
  }
};
</script>
<style>
footer {
  background-color: #282828;
  margin-top: 40px;
}
#footer-ticket .v-application--wrap{
  min-height: auto;
}
.footer {
  padding: 35px 15px 0px 15px;
  color: white;
}

.list-social-links a {
  color: white;
}

.list-social-links a:hover {
  color: white;
  opacity: .8;
}

.title-cont-mini-footer {
  text-transform: uppercase;
  color: #ffc107;
}

.list-menu-links a {
  color: white;
}

.list-menu-links a:hover {
  color: white;
  opacity: .8;
}

.cont-mini-footer {
  margin-bottom: 15px;
}

.copyright {
  color: white;
  text-align: center;
  padding: 10px 10px;
}

div.div-slide-alidos {
  width: 145px !important;
  text-align: center !important;
}

div > img.img-slide-aliados {
  width: 120px !important;
  height: 90px !important;
}
.v-application a{
  color: white !important;
}
</style>