<template>
  <div class="whatsapp-icon">
    <img class="img-whatsapp-icon" @click="showChat = !showChat" src="@/assets/icons/whatsapp-icon.png">
    <div v-if="showChat" class="chat-whatsapp-icon">
      <div class="header-whatsapp-icon">
        <p class="text-header-whatsapp-icon">CentralTickets - Chat</p>
        <div class="close-whatsapp-icon"  @click="showChat = false">
          <img src="@/assets/icons/close-icon.svg" alt="">
        </div>
      </div>
      <div class="body-whatsapp-icon">
        <div class="text-chat-whatsapp"/>
        <a target="_blank" href="https://api.whatsapp.com/send?phone=57310 6773012&text=Hola,%20me%20interesa%20reservar%20boletas.%20Quiero%20el%20link%20de%20pago." class="link-whatsaap">
          <button class="btn-open-chat">
            <img src="@/assets/icons/chat-icon.svg" alt="">
            Abrir chat
          </button>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showChat: false,
    }
  },
}
</script>
<style scope>
  .whatsapp-icon{
    position: fixed;
    bottom: 6rem;
    right: 2rem;
    z-index: 100;
  }
  .img-whatsapp-icon{
    width: 60px;
    cursor: pointer;
  }
  .chat-whatsapp-icon{
    position: fixed;
    bottom: 9rem;
    right: 4rem;
    border-radius: 20px;
    z-index: -1;
    min-width: 300px;
    box-shadow: 0 0 5px black;
  }
  .header-whatsapp-icon{
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    background-color: rgb(71, 192, 91);
  }
  .text-header-whatsapp-icon{
    color: white;
    font-weight: bold;
    margin-bottom: 0;
    padding-right: 3rem;
  }
  .close-whatsapp-icon{
    cursor: pointer;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.432);
  }
  .body-whatsapp-icon{
    padding: 1rem;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: rgb(248, 248, 248);
  }
  .btn-open-chat{
    background-color: #5ad665;
    padding: .6rem 1rem;
    border-radius: 20px;
  }
  .btn-open-chat:focus{
    outline: none;
  }
  .link-whatsaap{
    color: white !important;
    font-size: 1.1rem;
  }
  .link-whatsaap:hover{
    color: white !important;
  }
  .text-chat-whatsapp{
    position: relative;
    height: 55px;
    width: 100%;
    margin-bottom: 1rem;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 2px;
    background-color: #fff;
    box-shadow: 0 0 5px rgb(211, 211, 211);
  }
  .text-chat-whatsapp::before{
    content: 'Necesitas ayuda para comprar tu boleta.';
    position: absolute;
    width: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .text-chat-whatsapp::after{
    content: '';
    position: absolute;
    top: 0;
    left: -10px;
    border-right: 12px solid white;
    border-top: 6px solid transparent;
    border-bottom: 3px solid transparent;
    border-top-right-radius: 3px;
    filter: drop-shadow(-3px 0 2px rgb(211, 211, 211));
  }
</style>