<template>
  <v-app>
    <v-container v-if="evento">
      <h2>solicitud de nuevo evento</h2>
      <v-layout row wrap>
        <v-flex lg6 md6 sm6 xs12 mb-4>
          <h3>Resumen de solicitud de evento</h3>
        </v-flex>
        <v-flex lg6 md6 sm6 xs12 mb-4 text-right>
          <div class="alert alert-primary">
            <h6 class="text-left">
              <i class="ti-eye"></i> Por favor confirme toda la informacion
              antes de cambiar de estado
            </h6>
          </div>
        </v-flex>
      </v-layout>
      <h3 class="text-center mb-4">Datos Generales</h3>
      <v-text-field readonly label="Evento" v-model="evento.nombre">
      </v-text-field>
      <v-text-field readonly label="Descripción" v-model="evento.descripcion">
      </v-text-field>
      <v-layout row wrap>
        <v-flex lg4 md4 sm4 xs12>
          <v-text-field
            v-model="evento.fecha_evento"
            label="Fecha de Inicio del Evento"
            prepend-icon="access_time"
            readonly
          ></v-text-field>
        </v-flex>
        <v-flex lg4 md4 sm4 xs12>
          <v-text-field
            v-model="evento.fecha_evento"
            label="Fecha Final del Evento"
            prepend-icon="access_time"
            readonly
          ></v-text-field>
        </v-flex>
        <v-flex lg4 md4 sm4 xs12 class="pr-4">
          <v-text-field
            v-if="evento.temporada"
            label="Temporada"
            v-model="evento.temporada.nombre"
            readonly
          ></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout>
        <v-flex lg6 md6 sm6 xs12 class="pr-4">
          <v-text-field
            label="Nombre del Lugar del Evento"
            v-model="evento.auditorio.nombre"
            required
            readonly
          ></v-text-field>
        </v-flex>
        <v-flex lg6 md6 sm6 xs12 class="pr-4">
          <v-text-field
            label="Dirección del Lugar del Evento"
            v-model="evento.auditorio.direccion"
            required
            readonly
          ></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex lg6 md6 sm6 xs12 class="pr-4">
          <v-text-field
            label="Departamento"
            readonly
            :value="getDepartamento(evento.auditorio.id_departamento)"
          ></v-text-field>
        </v-flex>
        <v-flex lg6 md6 sm6 xs12 class="pr-4">
          <v-text-field
            label="Ciudad"
            readonly
            :value="getCiudad(evento.auditorio.id_ciudad)"
          ></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex lg4 md4 sm4 xs12 class="pr-4">
          <v-text-field
            label="Longitud"
            v-model="evento.auditorio.longitud"
            readonly
            type="number"
          ></v-text-field>
        </v-flex>
        <v-flex lg4 md4 sm4 xs12 class="pr-4">
          <v-text-field
            label="Latitud"
            v-model="evento.auditorio.latitud"
            readonly
            type="number"
          ></v-text-field>
        </v-flex>
        <v-flex lg4 md4 sm4 xs12 class="pr-4">
          <v-text-field
            label="Aforo"
            v-model="evento.auditorio.aforo"
            readonly
            type="number"
          ></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex lg4 md4 sm4 xs12>
          <v-text-field
            v-model="evento.hora_inicio"
            label="Hora de inicio"
            prepend-icon="access_time"
            readonly
          ></v-text-field>
        </v-flex>
        <v-flex lg4 md4 sm4 xs12>
          <v-text-field
            v-model="evento.hora_apertura"
            label="Apertura de puertas"
            prepend-icon="access_time"
            readonly
          ></v-text-field>
        </v-flex>
        <v-flex lg4 md4 sm4 xs12>
          <v-text-field
            v-model="evento.hora_finalizacion"
            label="Hora de finalizacion"
            prepend-icon="access_time"
            readonly
          ></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex lg6 md6 sm6 xs12 class="pr-4">
          <v-autocomplete
            readonly
            label="Tipo de evento"
            :items="tiposEventos"
            v-model="evento.id_tipo_evento"
            item-text="nombre"
            item-value="id"
            no-data-text="No se encontraron resultados"
          ></v-autocomplete>
        </v-flex>
        <v-flex lg6 md6 sm6 xs12>
          <v-text-field
            readonly
            required
            placeholder="Codigo PULEP"
            v-model="evento.codigo_pulep"
          ></v-text-field>
        </v-flex>
      </v-layout>
      <v-spacer></v-spacer>
      <h3 class="text-center mb-4 mt-4">Responsable del evento</h3>
      <v-layout row wrap>
        <v-flex lg8 md8 sm8 xs12>
          <v-text-field
            label="Responsable"
            v-model="evento.cliente.nombrerazon"
            readonly
          >
          </v-text-field>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex lg7 md7 sm7 xs12 class="pr-3">
          <v-text-field
            label="Identificación"
            v-model="evento.cliente.Identificacion"
            readonly
          >
          </v-text-field>
        </v-flex>
        <v-flex lg2 md2 sm2 xs12 class="pr-3">
          <v-text-field
            required
            placeholder="TIPO"
            :value="
              evento.cliente.tipo_identificacion == 1
                ? 'CC'
                : evento.cliente.tipo_identificacion == 2
                ? 'CE'
                : evento.cliente.tipo_identificacion == 3
                ? 'PA'
                : ''
            "
            readonly
          ></v-text-field>
        </v-flex>
        <v-flex lg5 md5 sm5 xs12>
          <v-radio-group
            v-model="evento.cliente.tipo_identificacion"
            row
            readonly
          >
            <v-radio
              label="Entidad Juridica"
              :value="1"
              color="primary"
            ></v-radio>
            <v-radio
              label="Persona Natural"
              :value="0"
              color="primary"
            ></v-radio>
          </v-radio-group>
        </v-flex>
      </v-layout>
      <v-text-field
        label="Dirección"
        readonly
        v-model="evento.cliente.direccion"
      >
      </v-text-field>
      <v-layout row wrap>
        <v-flex lg6 md6 sm6 xs12 class="pr-4">
          <v-text-field
            readonly
            label="Departamento"
            :value="getDepartamento(evento.cliente.id_departamento)"
          ></v-text-field>
        </v-flex>
        <v-flex lg6 md6 sm6 xs12 class="pr-4">
          <v-text-field
            readonly
            label="Ciudad"
            :value="getCiudad(evento.cliente.id_ciudad)"
          ></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex lg6 md6 sm6 xs12 pa-2>
          <v-text-field label="email" v-model="evento.cliente.email" readonly>
          </v-text-field>
        </v-flex>
        <v-flex lg6 md6 sm6 xs12 pa-2>
          <v-text-field
            label="Telefono"
            v-model="evento.cliente.telefono"
            readonly
          >
          </v-text-field>
        </v-flex>
      </v-layout>
      <div class="p-5 text-center bg-white mb-4 mt-3">
        <h4>Resumen de auditorio mapeado del evento</h4>
        <center
          class="area_mapeadaAuditorio2"
          style="overflow-x: auto; overflow-y: auto; max-height: 500px"
        >
          <img :src="evento.auditorio_mapeado.imagen" usemap="#map" />
        </center>
        <br id="boletas" />
        <BoletasxLocalidad
          :pagos="false"
          :fisico="false"
          v-if="idLocalidad != ''"
          :idlocalidad="idLocalidad"
          :idEvento="evento.id"
        ></BoletasxLocalidad>
      </div>
      <div class="p-5 text-center bg-white mb-4 mt-3">
        <h4>Resumen de imagenes del evento</h4>
        <v-data-table
          v-if="imagenesdelEvento"
          :headers="[{ text: 'Imagen', value: 'url' }]"
          :items="imagenesdelEvento"
          item-key="index"
          class="elevation-1"
          rows-per-page-text="Filas por Pag."
          :rows-per-page-items="rowperpageitems"
        >
          <template v-slot:items="eventoImagen">
            <td>
              <v-img
                :src="eventoImagen.item.imagen.url"
                lazy-src="/img/logo-CentralTickets.ca82ecc6.png"
                max-height="150"
                class="text-right pa-2"
              >
              </v-img>
            </td>
          </template>
        </v-data-table>
      </div>
      <div class="p-5 text-center bg-white mb-4 mt-3">
        <h3 class="mt-4 mb-3">Condiciones de admision y restricciones</h3>
        <v-data-table
          v-if="condicionesEvento"
          :headers="headersCondicion"
          :items="condicionesEvento"
          item-key="id"
          class="elevation-1"
          rows-per-page-text="Filas por Pag."
          :rows-per-page-items="rowperpageitems"
        >
          <template v-slot:items="condicion">
            <td>{{ condicion.item.condicion.descripcion }}</td>
          </template>
        </v-data-table>
      </div>
      <div class="p-5 text-center bg-white mb-4 mt-3">
        <h3 class="mt-3 mb-3">Artistas principales</h3>
        <v-data-table
          v-if="artistasdelEvento"
          :headers="resumheadersArtista"
          :items="artistasdelEvento"
          item-key="id"
          class="elevation-1"
          rows-per-page-text="Filas por Pag."
          :rows-per-page-items="rowperpageitems"
        >
          <template v-slot:items="artista">
            <td>{{ artista.item.artist.nombre }}</td>
            <td>
              <v-img
                :src="
                  artista.item.artist.imagens[0] == null
                    ? ''
                    : artista.item.artist.imagens[0].url
                "
                lazy-src="/img/logo-CentralTickets.ca82ecc6.png"
                max-height="150"
                class="text-right pa-2"
              >
              </v-img>
            </td>
          </template>
        </v-data-table>
      </div>
      <div class="p-5 text-center bg-white mb-4 mt-3">
        <h4>Resumen de localidades y precios</h4>
        <v-data-table
          v-if="localidades"
          :headers="resumheadersLocalidad"
          :items="localidades"
          item-key="id"
          class="elevation-1"
          rows-per-page-text="Filas por Pag."
          :rows-per-page-items="rowperpageitems"
        >
          <template v-slot:items="localidad">
            <td>{{ localidad.item.id }}</td>
            <td>
              <!-- <v-switch 
                            readonly
                            v-model="localidad.item.palco"
                            :true-value="1"
                            :false-value="0"
                          ></v-switch> -->
              {{ (localidad.item.palco = 1 ? "Palco" : "Localidad") }}
            </td>
            <td>
              <!-- <v-switch 
                            readonly
                            v-model="localidad.item.silleteria"
                            :true-value="1"
                            :false-value="0"
                          ></v-switch> -->
              {{ (localidad.item.silleteria = 1 ? "SI" : "NO") }}
            </td>
            <td>{{ getnomtribuna(localidad.item.id_tribuna) }}</td>
            <td>{{ localidad.item.nombre }}</td>
            <td>{{ localidad.item.puerta_acceso }}</td>
            <td>{{ localidad.item.aforo }}</td>
            <td>
              <v-btn
                class="mx-2"
                fab
                dark
                small
                color="blue"
                @click="getPuestos2(localidad.item.id)"
              >
                Ir
              </v-btn>
            </td>
          </template>
        </v-data-table>
        <BoletasxLocalidad
          :pagos="false"
          :fisico="false"
          v-if="idLocalidad2 != ''"
          :idlocalidad="idLocalidad2"
          :idEvento="evento.id"
        ></BoletasxLocalidad>
      </div>
      <div class="p-5 text-center bg-white mb-4 mt-3">
        <h4>Resumen de preventas</h4>
        <v-data-table
          :headers="headersPreventa"
          :items="preventasEvento"
          item-key="id"
          class="elevation-1"
          rows-per-page-text="Filas por Pag."
          :rows-per-page-items="rowperpageitems"
        >
          <template v-slot:items="preventa">
            <td>{{ preventa.item.nombre }}</td>
            <td>{{ preventa.item.evento ? "SI" : "NO" }}</td>
            <td>{{ getnomtribuna(preventa.item.id_tribuna) }}</td>
            <td>{{ getnomlocalidad(preventa.item.id_localidad) }}</td>
            <td>{{ preventa.item.fecha_inicio }}</td>
            <td>{{ preventa.item.hora_inicio }}</td>
            <td>{{ preventa.item.fecha_fin }}</td>
            <td>{{ preventa.item.hora_fin }}</td>
            <td>{{ preventa.item.porcentaje_descuento_servicio + "%" }}</td>
            <td>
              {{
                getSimboloMoneda(evento.codigo_moneda) +
                formatoMoneda(preventa.item.descuento_fijo_servicio)
              }}
            </td>
            <td>{{ preventa.item.porcentaje_descuento_precio + "%" }}</td>
            <td>
              {{
                getSimboloMoneda(evento.codigo_moneda) +
                formatoMoneda(preventa.item.descuento_fijo_precio)
              }}
            </td>
          </template>
        </v-data-table>
      </div>
      <div class="p-5 text-center bg-white mb-4 mt-3">
        <h4>Resumen de puntos de venta</h4>
        <v-data-table
          v-if="puntoventasEvento"
          :headers="headersPuntoventa"
          :items="puntoventasEvento"
          item-key="id"
          class="elevation-1"
          rows-per-page-text="Filas por Pag."
          :rows-per-page-items="rowperpageitems"
        >
          <template v-slot:items="puntoventa">
            <td>{{ puntoventa.item.punto_ventum.nombre_razon }}</td>
            <td>{{ puntoventa.item.punto_ventum.zona }}</td>
            <td>{{ getCiudad(puntoventa.item.punto_ventum.id_ciudad) }}</td>
            <td>{{ puntoventa.item.punto_ventum.responsable }}</td>
          </template>
        </v-data-table>
        <v-text-field
          v-show="evento.fecha_inicio_venta_puntos"
          label="Fecha de inicio de venta en puntos"
          readonly
          v-model="evento.fecha_inicio_venta_puntos"
        >
        </v-text-field>
        <v-text-field
          v-show="evento.venta_linea"
          label="Fecha de inicio de ventas en internet"
          readonly
          v-model="evento.fecha_inicio_venta_internet"
        >
        </v-text-field>
        <v-text-field
          label="Boletas o Palcos máximo al día"
          readonly
          v-model="evento.cant_max_boletas"
        >
        </v-text-field>
      </div>
      <div class="p-5 text-center bg-white mb-4 mt-3">
        <h4>Resumen de costos asociados al evento</h4>
        <v-data-table
          v-if="costos"
          :headers="resumheadersCosto"
          :items="costos"
          item-key="id"
          class="elevation-1"
          rows-per-page-text="Filas por Pag."
          :rows-per-page-items="rowperpageitems"
        >
          <template v-slot:items="costo">
            <td>
              {{
                costo.item.id_tipo_costo != ""
                  ? getTipocosto(costo.item.id_tipo_costo)
                  : ""
              }}
            </td>
            <td>{{ costo.item.descripcion }}</td>
            <td>{{ costo.item.valor }}</td>
            <td>
              {{
                costo.item.codigo_moneda != ""
                  ? getMoneda(costo.item.codigo_moneda.codigo_moneda)
                  : ""
              }}
            </td>
          </template>
        </v-data-table>
        <v-text-field
          v-show="evento.monto_minimo != 0"
          label="Monto minimo"
          readonly
          v-model="evento.monto_minimo"
        >
        </v-text-field>
      </div>
      <div class="p-5 text-center bg-white mb-4 mt-3">
        <v-form ref="ruleForm1">
          <v-layout row wrap>
            <v-flex lg12 md12 sm12 xs12>
              <v-text-field
                label="Observaciones"
                v-model="observacion"
                :rules="[
                  (v) =>
                    !!v ||
                    'Observación del cambio de estado del Evento es Requerido',
                ]"
              >
              </v-text-field>
            </v-flex>
          </v-layout>
        </v-form>
      </div>
      <v-layout row wrap justify-end>
        <v-btn lg2 md2 sm2 xs12 color="error" @click="resolver(2)"
          >Rechazar</v-btn
        >
        <v-btn lg2 md2 sm2 xs12 color="warning" @click="resolver(6)"
          >Aprobar con Modificaciones</v-btn
        >
        <v-btn lg2 md2 sm2 xs12 color="info" @click="resolver(1)"
          >Aprobar</v-btn
        >
      </v-layout>
    </v-container>
    <v-dialog v-model="dialogresolver" max-width="300">
      <v-card>
        <v-card-title class="headline">
          {{ accion }}
        </v-card-title>

        <v-card-text> Esta seguro que desea continuar? </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" flat="flat" @click="aprobar(false)">
            Cancelar
          </v-btn>
          <v-btn color="green darken-1" flat="flat" @click="aprobar(true)">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-btn
      v-scroll="onScroll"
      v-show="fab"
      fab
      dark
      fixed
      bottom
      right
      color="#907b16"
      @click="toTop"
    >
      <v-icon>keyboard_arrow_up</v-icon>
    </v-btn>
  </v-app>
</template>
<script>
import moment from "moment";
import BoletasxLocalidad from "./utils/BoletasxLocalidad.vue";

moment.locale("es");

export default {
  async beforeCreate() {
    document.title = "Aprobacion de evento - CentralTickets";
    this.$store.dispatch("departamento/listDepartamentos");
    this.$store.dispatch("ciudad/listCiudades");
    this.$store.dispatch("tipoevento/listTipoEvento");
    this.$store.dispatch("tribuna/listTribunas");
    this.$store.dispatch("tipocosto/listTipocostos");
    this.$store.dispatch("moneda/listMonedas");
    await this.$store.dispatch("evento/getEvento", this.$route.params.id);
    this.$store.dispatch("evento/getCondicionesEvento", this.$route.params.id);
    this.$store.dispatch("evento/listPreventasEvento", this.$route.params.id);
    this.$store.dispatch("evento/getImagenesEvento", this.$route.params.id);
    this.$store.dispatch("evento/getArtistasEvento", this.$route.params.id);
    this.$store.dispatch("evento/getpuntoventasEvento", this.$route.params.id);
    this.$store.dispatch("evento/getCostosEvento", this.$route.params.id);
    // this.$store.dispatch('auditorio_mapeado/listLocalidadesAuditorio_mapeado',this.$store.getters['evento/evento'].id_auditorio_mapeado);
    this.$store.dispatch(
      "auditorio_mapeado/listLocalidadesEventoAuditorio_mapeado",
      this.$route.params.id
    );
    this.$store.dispatch(
      "auditorio_mapeado/listLocalidadesEventoAuditorioGeneral_mapeado",
      this.$route.params.id
    );
  },
  async mounted() {
    var self = this;
    await self.$store.dispatch("evento/getEvento", this.$route.params.id);
    $(".area_mapeadaAuditorio2").append(
      $("<textarea />")
        .html(
          this.$store.getters["evento/evento"].auditorio_mapeado.area_mapeada
        )
        .text()
        .replace("&nbsp;", "")
    );
    $(".maparea").click(function (e) {
      e.preventDefault();
      var texthref = e.currentTarget.href;
      var arrayhref = texthref.split("/");
      self.getPuestos(arrayhref[arrayhref.length - 1]);
      window.location.hash = "boletas";
      //$("html, body").animate({ scrollTop: $('#boletas').offset().top }, 1);
    });
  },
  components: {
    BoletasxLocalidad,
  },
  data() {
    return {
      tipodescuento: [
        { id: 1, descripcion: "Servicio" },
        { id: 2, descripcion: "Precio" },
      ],
      observacion: "",
      audicargado: true,
      estado: "",
      accion: "",
      headersCondicion: [
        {
          text: "Condición",
          align: "left",
          value: "descripcion",
        },
      ],
      headersPuntoventa: [
        {
          text: "Nombre Comercial",
          align: "left",
          value: "nombre_razon",
        },
        {
          text: "Zona/Region",
          value: "zona",
        },
        {
          text: "Ciudad",
          value: "ciudades.descripcion",
        },
        {
          text: "Responsable",
          value: "responsable",
        },
      ],
      resumheadersArtista: [
        {
          text: "Nombre",
          align: "left",
          value: "nombre",
        },
        {
          text: "Imagen",
          value: "imagens",
        },
      ],
      headersPreventa: [
        {
          text: "Nombre",
          align: "left",
          value: "nombre",
        },
        {
          text: "Evento",
          value: "evento",
        },
        {
          text: "Tribuna",
          value: "id_tribuna",
        },
        {
          text: "Localidad",
          value: "id_localidad",
        },
        {
          text: "Inicio",
          value: "fecha_inicio",
        },
        {
          text: "Inicio",
          value: "hora_inicio",
        },
        {
          text: "Final",
          value: "fecha_fin",
        },
        {
          text: "Final",
          value: "hora_fin",
        },
        {
          text: "Porcentaje en Servicio",
          value: "porcentaje_descuento_servicio",
        },
        {
          text: "Valor Fijo en Servicio",
          value: "descuento_fijo_servicio",
        },
        {
          text: "Porcentaje en Precio",
          value: "porcentaje_descuento_precio",
        },
        {
          text: "Valor Fijo en Precio",
          value: "descuento_fijo_precio",
        },
      ],
      resumheadersLocalidad: [
        {
          text: "Id",
        },
        {
          text: "Palcos",
        },
        {
          text: "Silleteria",
        },
        {
          text: "Tribuna",
          align: "left",
        },
        {
          text: "Localidad",
          align: "left",
        },
        {
          text: "Puerta de Acceso",
        },
        {
          text: "Aforo",
        },
      ],
      resumheadersCosto: [
        {
          text: "Tipo",
          align: "left",
          value: "id_tipo_costo",
        },
        {
          text: "Descripcion",
          value: "descripcion",
        },
        {
          text: "Monto",
          value: "valor",
        },
        {
          text: "Moneda",
          value: "codigo_moneda",
        },
      ],
      rowperpageitems: [5, 10, 25, { text: "Todos", value: -1 }],
      idLocalidad: "",
      idLocalidad2: "",
      fab: false,
      dialogresolver: false,
    };
  },
  watch: {
    evento: {
      handler: function (after, before) {
        if (this.evento && this.audicargado) {
          this.localidades = this.evento.id_auditorio_mapeado;
        }
        this.audicargado = false;
      },
    },
  },
  computed: {
    // localidades:{
    //   get() {return this.$store.getters['auditorio_mapeado/listLocalidadesAuditorio_mapeado'];},
    //   set(value) {this.$store.dispatch('auditorio_mapeado/listLocalidadesAuditorio_mapeado', value);}
    // },
    localidades: {
      get() {
        return this.$store.getters[
          "auditorio_mapeado/listLocalidadesEventoAuditorio_mapeado"
        ];
      },
      set(value) {
        this.$store.dispatch(
          "auditorio_mapeado/listLocalidadesEventoAuditorio_mapeado",
          value
        );
      },
    },
    costos() {
      return this.$store.getters["evento/costosEvento"];
    },
    tiposEventos() {
      return this.$store.getters["tipoevento/listTipoEvento"];
    },
    departamentos() {
      return this.$store.getters["departamento/listDepartamentos"];
    },
    ciudades() {
      return this.$store.getters["ciudad/listCiudades"];
    },
    tipocostos() {
      return this.$store.getters["tipocosto/listTipocostos"];
    },
    evento() {
      return this.$store.getters["evento/evento"];
    },
    monedas() {
      return this.$store.getters["moneda/listMonedas"];
    },
    tribunas() {
      return this.$store.getters["tribuna/listTribunas"];
    },
    imagenesdelEvento() {
      return this.$store.getters["evento/imagenesEvento"];
    },
    condicionesEvento() {
      return this.$store.getters["evento/condicionesEvento"];
    },
    artistasdelEvento() {
      return this.$store.getters["evento/artistasEvento"];
    },
    puntoventasEvento() {
      return this.$store.getters["evento/puntoventasEvento"];
    },
    preventasEvento() {
      return this.$store.getters["evento/listPreventasEvento"];
    },
    tribunaslocalidad() {
      var self = this;
      return this.tribunas.filter((tribuna) => {
        for (var i = 0; i < self.localidades.length; i++) {
          if (self.localidades[i].id_tribuna == tribuna.id) {
            return true;
          }
        }
        return false;
      });
    },
  },
  methods: {
    resolver(estado) {
      switch (estado) {
        case 1:
          this.accion = "Aprobar";
          break;
        case 2:
          this.accion = "Rechazar";
          break;
        case 6:
          this.accion = "Aprobar Con Modificaciones";
          break;
      }
      this.estado = estado;
      this.dialogresolver = true;
    },
    async aprobar(confirmacion) {
      this.dialogresolver = false;
      if (confirmacion) {
        if (this.estado != 1) {
          if (!this.$refs.ruleForm1.validate()) {
            this.estado = "";
            return "";
          }
        }
        this.$store.commit("changeLoader", true);
        await this.$store.dispatch("auditoriaevento/newAuditoriaevento", {
          id_evento: this.evento.id,
          observacion: this.observacion,
          status_1: this.evento.status,
          status_2: this.estado,
        });
        await this.$store.dispatch("evento/updateEvento", {
          id: this.evento.id,
          fecha_evento: this.formatFecha(this.evento.fecha_evento),
          fecha_finalizacion_evento: this.formatFecha(
            this.evento.fecha_finalizacion_evento
          ),
          nombre: this.evento.nombre,
          descripcion: this.evento.descripcion,
          hora_inicio: this.formatHora(this.evento.hora_inicio),
          hora_apertura: this.formatHora(this.evento.hora_apertura),
          hora_finalizacion: this.formatHora(this.evento.hora_finalizacion),
          codigo_pulep: this.evento.codigo_pulep,
          id_tipo_evento: this.evento.id_tipo_evento,
          domicilios: this.evento.domicilios,
          venta_linea: this.evento.venta_linea,
          id_auditorio: this.evento.id_auditorio,
          id_auditorio_mapeado: this.evento.id_auditorio_mapeado,
          id_cliente: this.evento.id_cliente,
          id_temporada: this.evento.id_temporada,
          status: this.estado,
          fecha_inicio_venta_internet: this.formatFecha(
            this.evento.fecha_inicio_venta_internet
          ),
          fecha_inicio_venta_puntos: this.formatFecha(
            this.evento.fecha_inicio_venta_puntos
          ),
          monto_minimo: this.evento.monto_minimo,
          cant_max_boletas: this.evento.cant_max_boletas,
          codigo_moneda: this.evento.codigo_moneda.codigo_moneda,
        });
        this.$store.commit("changeLoader", false);
        this.$router.push({ name: "eventosAprobacion" });
        this.estado = "";
      }
    },
    formatFecha(fecha) {
      if (fecha != null && fecha != "") {
        return moment(fecha).format("YYYY-MM-DD");
      } else {
        return null;
      }
    },
    formatHora(hora) {
      if (hora != null && hora != "") {
        return moment(hora, "HH:mm:ss").format("HH:mm");
      } else {
        return null;
      }
    },
    getnomtribuna(id) {
      let filter_tribuna = this.tribunas.filter((tribuna) => tribuna.id == id);
      if (filter_tribuna[0] == "" || filter_tribuna[0] == null) {
        return "";
      } else {
        return filter_tribuna[0].nombre;
      }
    },
    getnomlocalidad(id) {
      let filter_localidad = this.localidades.filter(
        (localidad) => localidad.id == id
      );
      if (filter_localidad[0] == "" || filter_localidad[0] == null) {
        return "";
      } else {
        return filter_localidad[0].nombre;
      }
    },
    getnomtipodescuento(id) {
      let filter_tipodescuento = this.tipodescuento.filter(
        (descuento) => descuento.id == id
      );
      if (filter_tipodescuento[0] == "" || filter_tipodescuento[0] == null) {
        return "";
      } else {
        return filter_tipodescuento[0] ? filter_tipodescuento[0].descripcion : [];
      }
    },
    getDepartamento(id) {
      let filter_departamento = this.departamentos.filter(
        (departamento) => departamento.id == id
      );
      if (filter_departamento[0] == "" || filter_departamento[0] == null) {
        return "";
      } else {
        return filter_departamento[0] ? filter_departamento[0].descripcion : [];
      }
    },
    getCiudad(id) {
      let filter_ciudad = this.ciudades.filter((ciudad) => ciudad.id == id);
      if (filter_ciudad[0] == "" || filter_ciudad[0] == null) {
        return "";
      } else {
        return filter_ciudad[0] ? filter_ciudad[0].descripcion : [];
      }
    },
    getTipocosto(id) {
      let filter_tipocosto = this.tipocostos.filter(
        (tipocosto) => tipocosto.id == id
      );
      return filter_tipocosto[0] ? filter_tipocosto[0].descripcion : [];
    },
    getMoneda(codigo_moneda) {
      let filter_moneda = this.monedas.filter(
        (moneda) => moneda.codigo_moneda == codigo_moneda
      );
      if (filter_moneda[0] == "" || filter_moneda[0] == null) {
        return "";
      } else {
        return filter_moneda[0] ? filter_moneda[0].descripcion : [];
      }
    },
    getSimboloMoneda(codigo_moneda) {
      let filter_moneda = this.monedas.filter(
        (moneda) => moneda.codigo_moneda == codigo_moneda
      );
      if (filter_moneda[0] == "" || filter_moneda[0] == null) {
        return "";
      } else {
        return filter_moneda[0].simbolo;
      }
    },
    formatoMoneda(valor) {
      if (valor != null && valor != "") {
        return new Intl.NumberFormat("es-CO").format(valor);
      } else {
        return null;
      }
    },
    getPuestos(id) {
      this.idLocalidad = id;
    },
    getPuestos2(id) {
      this.idLocalidad2 = id;
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
};
</script>