<template>
  <v-app>
    <v-container>
      <v-layout>
        <v-flex>
          <v-card>
            <v-card-title class="text-center">
              <h3 class="text-center mt-3 col-md-12">
                TÉRMINOS Y CONDICIONES DE EVENTOS
              </h3>
            </v-card-title>
            <v-card-text>
              <div class="row row-clearfix">
                <div class="col-md col-lg col-sm p-4">
                  <h4>
                    <span>CONDICIONES GENERALES DE COMPRA DE BOLETAS.</span>
                  </h4>
                  <ul class="mt-5">
                    <li>
                      <p>
                        <strong class="h5 font-weight-bold">Primero:</strong> El
                        CLIENTE declara conocer de la realización e información
                        del evento o espectáculo a través de nuestro sitio web
                        www.centraltickets-ecuador.com, de los vendedores de los puntos
                        de venta y de los agentes de call center. CENTRALSTICKETS
                        informa los datos generales del evento con el fin de que
                        antes de realizar la compra de la entrada, sea de forma
                        presencial o no presencial, verifique toda la
                        información. CENTRALSTICKETS no se responsabiliza por
                        horarios, condiciones de seguridad, ubicación,
                        realización, organización o contenido del evento; esta
                        responsabilidad es del empresario o realizador del
                        espectáculo. EL CLIENTE debe estar completamente seguro
                        de lo que va a adquirir, por tanto, después de realizada
                        y finalizada una transacción; bien sea por internet,
                        call center, puntos de venta o taquillas; CENTRALSTICKETS no
                        realizará cambio de boletería, cambio de localidades,
                        devoluciones de dinero ni cancelación de órdenes.
                      </p>
                    </li>

                    <li>
                      <p>
                        <strong class="h5 font-weight-bold">Segundo:</strong>
                        Las compras realizadas por la página de CENTRALSTICKETS,
                        están sujetas a la verificación y aceptación de la
                        tarjeta débito o crédito por parte de la entidad
                        financiera, por la que EL CLIENTE hace la compra, así
                        como a la comprobación de los datos.
                      </p>
                    </li>

                    <li>
                      <p>
                        <strong class="h5 font-weight-bold">Tercero:</strong>
                        Cuando el CLIENTE realiza una compra a través de
                        www.centraltickets-ecuador.com acepta que las boletas deberán ser
                        reclamadas por el titular de la tarjeta, quien deberá
                        exhibir la tarjeta de crédito o débito con la que
                        realizó la compra y su documento de identidad. No se
                        aceptan poderes, avisos de pérdida de documentos,
                        denuncias, noticias criminales o autorizaciones a
                        terceras personas.
                      </p>
                    </li>

                    <li>
                      <p>
                        <strong class="h5 font-weight-bold">Cuarto:</strong> Se
                        le informa a todos nuestros CLIENTES que realicen
                        compras en línea; que por disposición contenida en el
                        artículo 47 de la ley 1480 de 2011, podrán ejercer su
                        derecho de retracto dentro los cinco (5) días hábiles
                        contados a partir de la fecha de la compra y siempre que
                        no se haya llevado a cabo el evento. En caso de que el
                        evento se haya realizado a cabalidad y de acuerdo a lo
                        ofrecido no habrá reintegros de dinero.
                      </p>
                    </li>

                    <li>
                      <p>
                        <strong class="h5 font-weight-bold">Quinto:</strong> EL
                        CLIENTE autoriza sin ninguna contraprestación el uso de
                        su imagen en material publicitario que puede producirse
                        por el evento.
                      </p>
                    </li>

                    <li>
                      <p>
                        <strong class="h5 font-weight-bold">Sexto:</strong> EL
                        CLIENTE realiza el proceso de compra en página web a
                        través de la plataforma de pagos autorizada Pay-Agile
                        para realizar la compra, la misma y como tercero ofrece
                        el servicio para las tarjetas de crédito con las
                        siguientes franquicias admitidas: MasterCard, Visa,
                        American Express, Diners Club, Discover.
                      </p>
                    </li>

                    <li>
                      <p>
                        <strong class="h5 font-weight-bold">Séptimo:</strong> El
                        canje de la boletería estará disponible para ser
                        efectuado transcurridas 24 horas después de ser ACEPTADO
                        el pago luego del proceso de validación.
                      </p>
                    </li>

                    <li>
                      <p>
                        <strong class="h5 font-weight-bold">Octavo:</strong> Con
                        la adquisición de la(s) boleta(s) se entienden aceptadas
                        todas las condiciones.
                      </p>
                    </li>
                  </ul>

                  <h4 class="mb-3 mt-5">DAÑO O PÉRDIDA DE BOLETAS</h4>

                  <ul>
                    <li>
                      <p>
                        <strong class="h5 font-weight-bold">Primero:</strong>
                        Una vez que sean entregadas las boletas, EL CLIENTE
                        asume toda la responsabilidad del uso, tenencia y
                        cuidado que le dé a sus entradas. CENTRALSTICKETS no realiza
                        reposición de boletas si estas se han perdido, dañado o
                        han sido robadas. Las boletas son un título valor al
                        portador, por tanto, se permitirá el ingreso a aquella
                        persona que las presente a la entrada.
                      </p>
                    </li>

                    <li>
                      <p>
                        <strong class="h5 font-weight-bold">Segundo:</strong>
                        Para evitar cualquier problema para acceder a algún
                        evento le recomendamos guardar sus boletas en un lugar
                        seguro, lejos del calor, humedad, líquidos o luz solar.
                        Si por algún motivo previamente analizado y autorizado
                        por el EMPRESARIO y CENTRALSTICKETS se llegara a autorizar
                        la reimpresión de las boletas el costo de cada
                        reimpresión es de $10.000 pesos por boleta.
                      </p>
                    </li>
                  </ul>

                  <h4 class="mb-3 mt-5">
                    DEVOLUCIÓN DEL DINERO EN CASO DE APLAZAMIENTO O MODIFICACIÓN
                    DE UN EVENTO
                  </h4>

                  <p>
                    Teniendo en cuenta que un evento puede ser modificado debido
                    a factores externos a CENTRALSTICKETS como cambio de lugar,
                    fecha, incumplimiento de artistas, inconvenientes de
                    transporte de los artistas, condiciones ambientales, fuerza
                    mayor o caso fortuito, se publicará en la página web
                    www.centraltickets-ecuador.com los cambios generados y las posibles
                    soluciones para EL CLIENTE de conformidad con:
                  </p>

                  <ul>
                    <li>
                      <p>
                        <strong class="h5 font-weight-bold">Primero:</strong> Se
                        establece que no se hará devolución del dinero por
                        cambio de lugar de realización o cambio de fecha siempre
                        y cuando el evento se realice en un lapso de 5 días
                        hábiles posteriores a la fecha inicial que se haya
                        anunciado. La misma boleta adquirida será válida para
                        ingresar al evento en la nueva fecha o en el nuevo
                        escenario.
                      </p>
                    </li>

                    <li>
                      <p>
                        <strong class="h5 font-weight-bold">Segundo:</strong> En
                        caso de que el tiempo de aplazamiento sea superior a los
                        5 días EL CLIENTE podrá decidir si asiste al evento o si
                        requiere la devolución del dinero, para lo cual deberá
                        comunicarse a nuestro call center o enviar una solicitud
                        al correo electrónico
                        contacto@centraltickets-ecuador.com con la presentación
                        del caso y datos personales para confirmarle el paso a
                        seguir. Cuando CENTRALSTICKETS autorice el reembolso de una
                        boleta, este valor corresponderá al valor nominal de LA
                        BOLETA o si fue un boleto de descuento, entonces se
                        reembolsará el precio pagado por la entrada. Bajo
                        ninguna circunstancia se reembolsará el costo por
                        entrega, servicio situado, cargos por servicio de
                        tiquete, servicio de venta o costos adicionales por
                        compra en línea, si este fuere el caso.
                      </p>
                    </li>

                    <li>
                      <p>
                        <strong class="h5 font-weight-bold">Tercero:</strong> Se
                        aclara que no habrá reintegros de dinero o cambios de
                        boletería por motivos ajenos a la organización o a
                        CENTRALSTICKETS, es decir en aquellos casos en los que EL
                        CLIENTE simplemente no pueda asistir al evento, si el
                        evento es modificado en cuanto a fecha, lugar u otro
                        similar.
                      </p>
                    </li>
                  </ul>

                  <h4 class="mb-3 mt-5">
                    DEVOLUCIÓN DEL DINERO EN CASO DE CANCELACIÓN DEFINITIVA DE
                    UN EVENTO
                  </h4>

                  <p>
                    EL EMPRESARIO u organizador del evento podrá cancelar un
                    evento por factores externos a CENTRALSTICKETS tales como
                    incumplimiento de artistas, inconvenientes de transporte
                    aéreo o terrestre de los artistas, condiciones ambientales,
                    baja asistencia de público, fuerza mayor o caso fortuito; si
                    esta situación se presentara será publicada en la página web
                    www.centraltickets-ecuador.com, tan pronto como sea notificada por
                    escrito por parte de EL EMPRESARIO, allí se publicará el
                    procedimiento que se debe seguir para solicitar el reembolso
                    del dinero de la siguiente manera:
                  </p>

                  <ul>
                    <li>
                      <p>
                        <strong class="h5 font-weight-bold">Primero:</strong>
                        Después de ser anunciado un aplazamiento, modificación o
                        cancelación de un evento se habilitará un plazo de
                        quince (15) días hábiles para tomar la decisión de
                        solicitar reembolso del dinero, luego de transcurrido
                        este tiempo CENTRALSTICKETS no se hará responsable por ello.
                      </p>
                    </li>

                    <li>
                      <p>
                        <strong class="h5 font-weight-bold">Segundo:</strong>
                        Cuando CENTRALSTICKETS autorice el reembolso de una entrada
                        este valor corresponderá al valor nominal de LA BOLETA o
                        si fue un boleto de descuento, entonces se reembolsará
                        el precio pagado por dicha entrada. Bajo ninguna
                        circunstancia se reembolsará el costo por entrega,
                        servicio situado o cargos por servicio o costos
                        adicionales por compra en línea si fuere el caso.
                      </p>
                    </li>

                    <li>
                      <p>
                        <strong class="h5 font-weight-bold">Tercero:</strong> EL
                        CLIENTE debe dirigirse al punto de venta o lugares
                        autorizados que se publicarán en la página web para el
                        reembolso, CENTRALSTICKETS determinará la cantidad de dichos
                        expendios para este procedimiento.
                      </p>
                    </li>

                    <li>
                      <p>
                        <strong class="h5 font-weight-bold">Cuarto:</strong>
                        Recuerde que las boletas deben estar en perfecto estado,
                        no deberán tener tachaduras ni enmendaduras, deberán
                        estar completas, es decir el cuerpo de la boleta y los
                        desprendibles. En caso de que se presente una entrada
                        que no cumpla dichas condiciones no se hará el reintegro
                        del dinero.
                      </p>
                    </li>

                    <li>
                      <p>
                        <strong class="h5 font-weight-bold">Quinto:</strong> Si
                        se realizó la compra por internet o call center y aún no
                        ha reclamado las boletas, por favor contáctenos a los
                        números de atención o envíe un mail a
                        atencionalcliente@centraltickets-ecuador.com con la descripción
                        del caso, soportes que debieron llegarle al correo de la
                        compra exitosa y datos personales de EL CLIENTE para dar
                        inicio al proceso de devolución.
                      </p>
                    </li>

                    <li>
                      <p>
                        <strong class="h5 font-weight-bold">Sexto:</strong>
                        Dentro de las condiciones normales se establece que la
                        devolución del dinero de un evento cancelado inicia 8
                        días hábiles después de haberse anunciado la cancelación
                        del evento.
                      </p>
                    </li>

                    <li>
                      <p>
                        <strong class="h5 font-weight-bold">Séptimo:</strong> El
                        reembolso del dinero se hará únicamente a aquellas
                        personas que hayan adquirido las boletas en puntos de
                        venta oficiales de CENTRALSTICKETS y que se encuentran
                        anunciados en la página web www.centraltickets-ecuador.com o a
                        través del call center, por ningún motivo se harán
                        reembolsos en caso de que las entradas hayan sido
                        adquiridas en lugares diferentes a los anunciados,
                        directamente con el Empresario u otras personas
                        autorizadas por este u otros expendios no oficiales; así
                        CENTRALSTICKETS no se hará responsable de ese reembolso y
                        deberá remitirse directamente a ellos.
                      </p>
                    </li>

                    <li>
                      <p>
                        <strong class="h5 font-weight-bold">Octavo:</strong> Ni
                        CENTRALSTICKETS ni el EMPRESARIO serán responsables por los
                        intereses generados en las tarjetas de crédito, gastos
                        de emisión, gastos de viaje u otros gastos en relación
                        con un evento que haya sido cancelado, aplazado o
                        modificado. Estos costos estarán única y exclusivamente
                        a cargo del CLIENTE.
                      </p>
                    </li>
                  </ul>

                  <p class="mt-5 text-center">
                    Ante cualquier consulta, no dude en comunicarse con nuestro centro de ayuda. <a href="https://ayuda.centraltickets-ecuador.com/" style="color:gray !important; width: 140px; font-size: 14px; text-decoration-line: underline;">Centro de Ayuda</a>
                  </p>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: "terminoscondiciones",
  beforeCreate() {
    document.title = "Términos y condiciones - centraltickets";
  },
};
</script>