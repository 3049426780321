import Vue from 'vue';
import axios from 'axios';
import { configApi } from '../../api/configApi';
import { router } from '../../main.js';
import { headers } from '../../api/headers';
import { store } from '../index';


export default {
  namespaced: true,
  state: {
    eventos: [],
    evento: null,
    eventosDestacados: [],
    puestos: [],
    pagination: {
      descending: true,
      page: 1,
      rowsPerPage: 0,
      sortBy: '',
      totalItems: 0,
      rowsPerPageItems: [1, 2, 4, 8, 16],
    },
    pages: 0,
    loading: false,
    cantBoleta: [],
    cantBoletaTotal: 0,
    acumuladoBoleta: 0,
    condicionesEvento: null,
    imagenesEvento: null,
    artistasEvento: null,
    puntoventasEvento: null,
    costosEvento: null,
    costoEvento: null,
    preventasEvento: [],
    preventaEvento: null,
    eventoboletas: [],
    monedaSelectShop: null,
    eventosEstadosBanner: [],
    ventasEventoEmpresario: [],
    tablaEvento: null,
    listado_preventas_evento: null,
    dataBannersDestacados: null,
  },
  getters: {
    listEventos: (state, getters, rootState) => {
      return state.eventos;
    },
    listado_preventas_evento(state){
      return state.listado_preventas_evento;
    },
    listEventosDestacados: (state, getters, rootState) => {
      return state.eventosDestacados;
    },
    listPuestos: (state, getters, rootState) => {
      return state.puestos;
    },
    evento: (state, getters, rootState) => {
      return state.evento;
    },
    pagination(state, getters, rootState) {
      return state.pagination;
    },
    pages(state, getters, rootState) {
      return state.pages;
    },
    totalItemCount(state, getters, rootState) {
      return state.pagination.totalItems;
    },
    loading(state, getters, rootState) {
      return state.loading;
    },
    cantBoleta(state, getters, rootState) {
      return state.cantBoleta;
    },
    cantBoletaTotal(state, getters, rootState) {
      return state.cantBoletaTotal;
    },
    acumuladoBoleta(state, getters, rootState) {
      return state.acumuladoBoleta;
    },
    condicionesEvento(state, getters, rootState) {
      return state.condicionesEvento;
    },
    imagenesEvento(state, getters, rootState) {
      return state.imagenesEvento;
    },
    artistasEvento(state, getters, rootState) {
      return state.artistasEvento;
    },
    puntoventasEvento(state, getters, rootState) {
      return state.puntoventasEvento;
    },
    costosEvento(state, getters, rootState) {
      return state.costosEvento;
    },
    costoEvento(state, getters, rootState) {
      return state.costoEvento;
    },
    listPreventasEvento(state, getters, rootState) {
      return state.preventasEvento;
    },
    preventaEvento(state, getters, rootState) {
      return state.preventaEvento;
    },
    listEventosBoleta(state, getters, rootState) {
      return state.eventoboletas;
    },
    listMonedasShop(state, getters, rootState) {
      return state.monedasShop;
    },
    monedaSelectShop(state, getters, rootState) {
      return state.monedaSelectShop;
    },
    listEventosEstadoBanner(state, getters, rootState) {
      return state.eventosEstadosBanner;
    },
    ventasEventoEmpresario(state, getters, rootState) {
      return state.ventasEventoEmpresario;
    },
  },
  mutations: {
    listEventos: (state, eventos) => {
      if (eventos != null) {
        state.eventos = eventos.data.reverse();
        state.pagination.totalItems = eventos.total;
        state.pagination.page = eventos.current_page;
        state.pagination.rowsPerPage = eventos.per_page;
      } else {
        state.eventos = [];
        state.pagination.totalItems = null;
        state.pagination.page = null;
        state.pagination.rowsPerPage = null;
      }
    },
    listEventosSearch: (state, eventos) => {
      if (eventos != null) {
        state.eventos = eventos;
      } else {
        state.eventos = null;
      }
    },
    listEventosDestacados: (state, eventosDestacados) => {
      if (eventosDestacados != null) {
        state.eventosDestacados = eventosDestacados.data;
      } else {
        state.eventosDestacados = null;
      }
    },
    listPuestos: (state, puestos) => {
      state.puestos = puestos;
    },
    listEventosBoleta: (state, eventoboletas) => {
      state.eventoboletas = eventoboletas;
    },
    viewEvento(state, id) {
      let auxEvento = state.eventos.filter((item) => item.id == id);
      state.evento = auxEvento[0];
    },
    setEvento(state, evento) {
      state.evento = evento;
    },
    pages(state) {
      if (state.pagination.rowsPerPage == null ||
        state.pagination.totalItems == null) {
        state.pages = 0;
      }

      state.pages = Math.ceil(state.pagination.totalItems / state.pagination.rowsPerPage);
    },
    cantBoleta(state, carga) {
      switch (carga.payload.operacion) {

        case 'sumar': case 'reservar':
          if (state.cantBoleta[carga.payload.id_localidad] == undefined) {
            //state.cantBoleta[carga.payload.id_localidad]=1;  //no reactivo
            //Vue.set(state.cantBoleta, carga.payload.id_localidad, 1); //reactivo
            Vue.set(state.cantBoleta, carga.payload.id_localidad, []); //reactivo
            Vue.set(store.state.carrito.cantBoleta, carga.payload.id_localidad, []);
          } // else {
          //   //state.cantBoleta[carga.payload.id_localidad] = parseInt(state.cantBoleta[carga.payload.id_localidad]) + 1;  //no reactivo
          //   Vue.set(state.cantBoleta, carga.payload.id_localidad, parseInt(state.cantBoleta[carga.payload.id_localidad]) + 1); //reactivo
          // }
          state.cantBoleta[carga.payload.id_localidad].push(carga.boleta.id);
          store.state.carrito.cantBoleta[carga.payload.id_localidad].push(carga.boleta.id);
          state.cantBoletaTotal = parseInt(state.cantBoletaTotal) + 1;
          store.state.carrito.cantBoletaTotal = parseInt(store.state.carrito.cantBoletaTotal) + 1;
          state.acumuladoBoleta = parseInt(state.acumuladoBoleta) + parseInt(carga.payload.precio);
          break;

        case 'restar':
          if (state.cantBoleta[carga.payload.id_localidad] != [] && state.cantBoleta[carga.payload.id_localidad] != undefined) {
            //state.cantBoleta[carga.payload.id_localidad] = 0;       //no reactivo
            //Vue.set(state.cantBoleta, carga.payload.id_localidad, 0); //reactivo
            //     state.cantBoleta[carga.payload.id_localidad] = [];
            //     state.cantBoleta[carga.payload.id_localidad].push(carga.boleta.id);
            // }else{
            //state.cantBoleta[carga.payload.id_localidad] = parseInt(state.cantBoleta[carga.payload.id_localidad]) - 1;  //no reactivo
            //Vue.set(state.cantBoleta, carga.payload.id_localidad, parseInt(state.cantBoleta[carga.payload.id_localidad]) - 1); //reactivo
            state.cantBoleta[carga.payload.id_localidad].splice(0, 1);
            store.state.carrito.cantBoleta[carga.payload.id_localidad].splice(0, 1);
            state.cantBoletaTotal = parseInt(state.cantBoletaTotal) - 1;
            store.state.carrito.cantBoletaTotal = parseInt(store.state.carrito.cantBoletaTotal) - 1;
            state.acumuladoBoleta = parseInt(state.acumuladoBoleta) - parseInt(carga.payload.precio);
          }
          break;

        // default:
        //    //state.cantBoleta[carga.payload.id_localidad] = 1;  //no reactivo
        //    Vue.set(state.cantBoleta, carga.payload.id_localidad, 1); //reactivo
        // break;
      }
    },
    clearCantBoleta(state) {
      state.cantBoleta = [];
      store.state.carrito.cantBoleta = [];
      state.cantBoletaTotal = 0;
      store.state.carrito.cantBoletaTotal = 0;
      state.acumuladoBoleta = 0;
    },
    clearCantBoletaLocalidad(state, localidad) {
      if (state.cantBoleta[localidad.id] != undefined && state.cantBoleta[localidad.id] != null && state.cantBoleta[localidad.id] != 0) {
        state.acumuladoBoleta = parseInt(state.acumuladoBoleta) - (parseInt(state.cantBoleta[localidad.id]) * parseInt(localidad.precio));
        state.cantBoletaTotal = parseInt(state.cantBoletaTotal) - parseInt(state.cantBoleta[localidad.id]);
        store.state.carrito.cantBoletaTotal = parseInt(store.state.carrito.cantBoletaTotal) - parseInt(store.state.carrito.cantBoleta[localidad.id])
        state.cantBoleta.splice(localidad.id, 1);
        store.state.carrito.cantBoleta.splice(localidad.id, 1);
      }
    },
    setCondicionesEvento(state, condiciones) {
      state.condicionesEvento = condiciones;
    },
    setImagenesEvento(state, imagenes) {
      state.imagenesEvento = imagenes;
    },
    setArtistasEvento(state, artistas) {
      state.artistasEvento = artistas;
    },
    setpuntoventasEvento(state, puntoventas) {
      state.puntoventasEvento = puntoventas;
    },
    setCostosEvento(state, costos) {
      state.costosEvento = costos;
    },
    setCostoEvento(state, costo) {
      state.costoEvento = costo;
    },
    listPreventasEvento(state, preventasEvento) {
      state.preventasEvento = preventasEvento;
    },
    setPreventaEvento(state, preventa) {
      state.preventaEvento = preventa;
    },
    changeMonedaShopEvento(state, monedaSelectShop) {
      state.monedaSelectShop = monedaSelectShop;
    },
    listEventosEstadoBanner(state, eventosEstadosBanner) {
      state.eventosEstadosBanner = eventosEstadosBanner;
    },
    ventasEventoEmpresario(state, ventasEventoEmpresario) {
      state.ventasEventoEmpresario = ventasEventoEmpresario;
    }
  },
  actions: {
    async getvalidarcuponventa({state,getters,commit,rootState}, payload){
      store.commit('changeLoader', true);
      console.log(payload);
      await axios.post(`${configApi.apiUrl}app/v1/geteventocupon`,payload,null)
      .then(response => {
           
        store.state.carrito.descuentocupon = response.data.descuento  
        store.state.carrito.cuponestado = response.data.cuponestado 
        store.state.carrito.cupon  = response.data.cupon 
        store.state.carrito.validarcupon=true 
        store.state.carrito.cuponok=false
        if( response.data.cuponestado ==1){
          store.state.carrito.validarcupon=false 
          store.state.carrito.cuponok=true
       }
           // console.log(response.data.descuento);
            store.commit('changeLoader', false);
         })
      .catch(error => {
        swal("Error!", "Error al obtener los eventos!", "error");
      })
      .finally(()=>{
        store.commit('changeLoader', false);
      })
    },
    listEventos({ state, getters, commit, rootState }, page) {
      state.loading = true;
      store.commit('changeLoader', true);
      axios.get(`${configApi.apiUrl}evento?page=${page}`, null, { headers: headers })
        .then(response => {
          //console.log(response.data.data.data);
          commit('listEventos', response.data.data);
          commit('listEventosDestacados', response.data.data);
          commit('pages');
          state.loading = false;
          store.commit('changeLoader', false);
        })
        .catch(error => {
          state.loading = false;
          store.commit('changeLoader', false);
          swal("Error!", "Error al obtener los eventos!", "error");
          console.error(error);
        });
    },
    listEventosStatus({ state, getters, commit, rootState }, payload) {
      state.loading = true;
      store.commit('changeLoader', true);
      commit('listEventos',
        {
          data: [],
          total: state.pagination.totalItems,
          current_page: state.pagination.page,
          per_page: state.pagination.rowsPerPage,
        });
      axios.get(`${configApi.apiUrl}eventos_estado/${payload.status}?page=${payload.page}`, null, { headers: headers })
        .then(response => {
          //console.log(response.data.data.data);
          commit('listEventos', response.data.data);
          commit('listEventosDestacados', response.data.data);
          commit('pages');
          state.loading = false;
          store.commit('changeLoader', false);
        })
        .catch(error => {
          state.loading = false;
          store.commit('changeLoader', false);
          // if (error.message=="Request failed with status code 404") {
          //   swal("Alerta!", "No tiene Eventos por aprobar!", "success");
          // } else {
          //   swal("Error!", "Error al obtener los eventos!", "error");
          // }
          console.error(error);
        });
    },
    listMisEventos({ state, getters, commit, rootState }) {
      state.loading = true;
      store.commit('changeLoader', true);
      axios.get(`${configApi.apiUrl}eventos_usuario`, null, { headers: headers })
        .then(response => {
          //console.log(response.data.data.data);
          commit('listEventos', response.data);
          state.loading = false;
          store.commit('changeLoader', false);
        })
        .catch(error => {
          commit('listEventos', null);
          state.loading = false;
          store.commit('changeLoader', false);
          swal("Error!", "Error al obtener los eventos!", "error");
          console.error(error);
        });
    },
    listEventosBoleta({ state, getters, commit, rootState }, key) {
      return axios.get(`${configApi.apiUrl}listado_puestos_evento/${key}`, null, { headers: headers })
        .then(response => {
          commit('listEventosBoleta', response.data.data["puestos_evento"]);
        })
        .catch(error => {
          if (error.message != "Request failed with status code 404") {
            swal("Error!", "Error al obtener las boletas por evento!", "error");
            //console.log(error)
          }
        });
    },
    listPuestos({ state, getters, commit, rootState }) {
      return axios.get(`${configApi.apiUrl}puesto_all`, null, { headers: headers })
        .then(response => {
          commit('listPuestos', response.data.data);
        })
        .catch(error => {
          swal("Error!", "Error al obtener los puestos!", "error");
          console.error(error);
        });
    },
    searchEventos({ state, getters, commit, rootState }, key) {
      store.commit('changeLoader', true);
      axios.get(`${configApi.apiUrl}buscarEvento?nombre=${key}`, null, { headers: headers })
        .then(response => {
          commit('listEventosSearch', response.data.data);
          store.commit('changeLoader', false);
        })
        .catch(error => {
          store.commit('changeLoader', false);
          swal("Error!", "Error al obtener los eventos!", "error");
          console.error(error);
        });

    },
    filterEventos({ state, getters, commit, rootState }, payload) {
      // precio_inicio
      // precio_fin
      // artistas
      // tipos_evento
      // fecha_inicio
      // fecha_fin
      store.commit('changeLoader', true);
      axios.post(`${configApi.apiUrl}buscar_evento`, payload, { headers: headers })
        .then(response => {
          //console.log(response.data);
          commit('listEventos', response.data.data);
          store.commit('changeLoader', false);
          commit('pages');
          commit('listEventosDestacados', response.data.data);
          state.loading = false;
          store.commit('changeLoader', false);
        })
        .catch(error => {
          state.loading = false;
          store.commit('changeLoader', false);
          swal("Error!", "Error al obtener los eventos!", "error");
          console.error(error);
        });

    },
    newEvento({ state, getters, commit, rootState }, payload) {
      store.dispatch('validateToken');
      return axios.post(`${configApi.apiUrl}evento`, payload, { headers: headers })
        .then(response => {
          store.dispatch('evento/listEventos', state.pagination.page);
          commit('setEvento', response.data.data);
          /*swal("Exito!", "Se creo el evento correctamente!", "success");*/
        })
        .catch(error => {
          swal("Error!", "Error al crear el evento!", "error");
          console.error(error);
        });
    },
    newEventoArtista({ state, getters, commit, rootState }, payload) {
      // id_evento
      // id_artista
      store.dispatch('validateToken');
      return axios.post(`${configApi.apiUrl}artista_evento`, payload, { headers: headers })
        .then(response => {
          // swal("Exito!", "Se creo asociación del evento y del artista correctamente!", "success");
        })
        .catch(error => {
          swal("Error!", "Error al crear asociación del evento y del artista!", "error");
          console.error(error);
        });
    },
    newEventoImagen({ state, getters, commit, rootState }, payload) {
      // id_evento
      // id_imagen
      store.dispatch('validateToken');
      return axios.post(`${configApi.apiUrl}imagenevento`, payload, { headers: headers })
        .then(response => {
          // swal("Exito!", "Se creo asociación del evento y del artista correctamente!", "success");
        })
        .catch(error => {
          swal("Error!", "Error al crear asociación del evento y de la imagen!", "error");
          console.error(error);
        });
    },
    newEventoCondicion({ state, getters, commit, rootState }, payload) {
      // id_evento
      // id_condiciones
      store.dispatch('validateToken');
      return axios.post(`${configApi.apiUrl}condicionesevento`, payload, { headers: headers })
        .then(response => {
          // swal("Exito!", "Se creo asociación del evento y del artista correctamente!", "success");
        })
        .catch(error => {
          swal("Error!", "Error al crear asociación del evento y de la condición!", "error");
          console.error(error);
        });
    },
    newEventoPuntoVenta({ state, getters, commit, rootState }, payload) {
      // id_evento
      // id_puntoventa
      store.dispatch('validateToken');
      return axios.post(`${configApi.apiUrl}puntoventaevento`, payload, { headers: headers })
        .then(response => {
          // swal("Exito!", "Se creo asociación del evento y del artista correctamente!", "success");
        })
        .catch(error => {
          swal("Error!", "Error al crear asociación del evento y el Punto de Venta!", "error");
          console.error(error);
        });
    },
    newEventoPreventa({ state, getters, commit, rootState }, payload) {
      // nombre
      // id_evento_origen
      // id_evento
      // id_tribuna
      // id_localidad
      // fecha_inicio
      // hora_inicio
      // fecha_fin
      // hora_fin
      // porcentaje_descuento_servicio
      // descuento_fijo_servicio
      // porcentaje_descuento_precio
      // descuento_fijo_precio
      // activo
      store.dispatch('validateToken');
      return axios.post(`${configApi.apiUrl}preventum`, payload, { headers: headers })
        .then(response => {
          commit('setPreventaEvento', response.data.data);
          // swal("Exito!", "Se creo asociación del evento y la Preventa correctamente!", "success");
        })
        .catch(error => {
          swal("Error!", "Error al crear asociación del evento y la Preventa!", "error");
          console.error(error);
        });
    },
    newEventoCostos({ state, getters, commit, rootState }, payload) {
      // id_evento
      // id_tipo_costo
      // descripcion
      // valor
      // codigo_moneda
      store.dispatch('validateToken');
      return axios.post(`${configApi.apiUrl}costoevento`, payload, { headers: headers })
        .then(response => {
          commit('setCostoEvento', response.data.data);
          // swal("Exito!", "Se creo asociación del evento y del artista correctamente!", "success");
        })
        .catch(error => {
          swal("Error!", "Error al crear asociación del evento y el costo!", "error");
          console.error(error);
        });
    },
    newEventoBoleta({ state, getters, commit, rootState }, payload) {
      // id_evento
      // id_puesto
      // precio_venta
      // precio_servicio
      // impuesto
      // status
      // codigo_moneda
      return axios.post(`${configApi.apiUrl}boletaevento`, payload, { headers: headers })
        .then(response => {
          // swal("Exito!", "Se creo asociación del evento y del artista correctamente!", "success");
        })
        .catch(error => {
          swal("Error!", "Error al crear asociación del evento y la boleta!", "error");
          console.error(error);
        });

    },
    boletasxlocalidad({ state, getters, commit, rootState }, payload) {
      // id_localidad
      // id_evento
      // precio_venta
      // precio_servicio
      // impuesto
      // status
      // codigo_moneda
      // imagen
      return axios.post(`${configApi.apiUrl}boletasxlocalidad`, payload, { headers: headers })
        .then(response => {
          // swal("Exito!", "Se creo asociación del evento y del artista correctamente!", "success");
        })
        .catch(error => {
          swal("Error!", "Error al crear asociación del evento y la boleta!", "error");
          console.error(error);
        });

    },
    palcosxlocalidad({ state, getters, commit, rootState }, payload) {
      // id_localidad
      // id_evento
      // precio_venta
      // precio_servicio
      // impuesto
      // status
      // codigo_moneda
      // imagen
      return axios.post(`${configApi.apiUrl}palcosxlocalidad`, payload, { headers: headers })
        .then(response => {
          // swal("Exito!", "Se creo asociación del evento y del artista correctamente!", "success");
        })
        .catch(error => {
          swal("Error!", "Error al crear asociación del evento y el palco!", "error");
          console.error(error);
        });
    },
    getEvento({ state, getters, commit, rootState }, evento) {
      store.commit('changeLoader', true);
      commit('setEvento', null);
      return axios.get(`${configApi.apiUrl}evento/${evento}`, null, { headers: headers })
        .then(response => {
          commit('setEvento', response.data.data);
          store.dispatch('evento/listEventosPorTipo', response.data.data.id_tipo_evento);
          store.commit('changeLoader', false);
        })
        .catch(error => {
          store.commit('changeLoader', false);
          swal("Error!", "Error al obtener el evento!", "error");
          console.error(error);
        });
    },
    getCondicionesEvento({ state, getters, commit, rootState }, evento) {
      axios.get(`${configApi.apiUrl}condicionesevento/${evento}`, null, { headers: headers })
        .then(response => {
          commit('setCondicionesEvento', response.data.data);
        })
        .catch(error => {
          //console.log(error.response.data);
          if (error.response.data.success) {
            swal("Error!", "Error al obtener las condiciones del evento!", "error");
          }
        });
    },
    getImagenesEvento({ state, getters, commit, rootState }, evento) {
      return axios.get(`${configApi.apiUrl}imagenevento/${evento}`, null, { headers: headers })
        .then(response => {
          commit('setImagenesEvento', response.data.data);
        })
        .catch(error => {
          //console.log(error.response.data);
          if (error.response.data.success) {
            swal("Error!", "Error al obtener las imagenes del evento!", "error");
          }
        });
    },
    getArtistasEvento({ state, getters, commit, rootState }, evento) {
      return axios.get(`${configApi.apiUrl}artista_evento/${evento}`, null, { headers: headers })
        .then(response => {
          commit('setArtistasEvento', response.data.data);
        })
        .catch(error => {
          //console.log(error.response.data);
          if (error.response.data.success) {
            swal("Error!", "Error al obtener los artistas del evento!", "error");
          }
        });
    },
    getpuntoventasEvento({ state, getters, commit, rootState }, evento) {
      axios.get(`${configApi.apiUrl}puntoventaevento/${evento}`, null, { headers: headers })
        .then(response => {
          commit('setpuntoventasEvento', response.data.data);
        })
        .catch(error => {
          //console.log(error.response.data);
          if (error.response.data.success) {
            swal("Error!", "Error al obtener los puntos de venta del evento!", "error");
          }
        });
    },
    getCostosEvento({ state, getters, commit, rootState }, evento) {
      return axios.get(`${configApi.apiUrl}costos_evento/${evento}`, null, { headers: headers })
        .then(response => {
          commit('setCostosEvento', response.data.data);
        })
        .catch(error => {
          //console.log(error.response.data);
          if (error.response.data.success) {
            swal("Error!", "Error al obtener los costos del evento!", "error");
          }
        });
    },
    listEventosPorTipo({ state, getters, commit, rootState }, tipo) {
      axios.get(`${configApi.apiUrl}listeventipo/${tipo}`, null, { headers: headers })
        .then(response => {
          commit('listEventosDestacados', response.data);
        })
        .catch(error => {
          swal("Error!", "Error al obtener los eventos relacionados!", "error");
        });
    },
    listPreventasEvento({ state, getters, commit, rootState }, evento) {
      axios.get(`${configApi.apiUrl}listado_preventasEvento/${evento}`, null, { headers: headers })
        .then(response => {
          commit('listPreventasEvento', response.data.preventas);
          state.tablaEvento = response.data.data
        })
        .catch(error => {
          //swal("Error!", "Error al obtener las preventas del evento!", "error");
          commit('listPreventasEvento', []);
        });
    },
    getPreventas({ state, getters, commit, rootState }, evento) {
      axios.get(`${configApi.apiUrl}listado_preventas_evento/${evento}`, null, { headers: headers })
        .then(response => {
          commit('listPreventasEvento', response.data.preventas);
        })
        .catch(error => {
          commit('listPreventasEvento', []);
        });
    },
    setCantBoleta({ state, getters, commit, rootState }, payload) {
      store.dispatch('validateToken');
      store.commit('changeLoader', true);
      if (payload.operacion == 'sumar') {
        return axios.post(`${configApi.apiUrl}boletas_palcos_reservadas`, payload, { headers: headers })
          .then(response => {
            if (response.data.message != 'error') {
              commit('cantBoleta', {
                payload: payload,
                boleta: response.data.data
              });
            }
            store.commit('changeLoader', false);
            return response.data;

            /*swal("Exito!", "Se creo el evento correctamente!", "success");*/
          })
          .catch(error => {
            store.commit('changeLoader', false);
            swal("Error!", "Ya no hay mas boletas disponibles!", "error");
            console.error(error);
          });
      } else if (payload.operacion == 'restar' && state.cantBoleta[payload.id_localidad] != [] && state.cantBoleta[payload.id_localidad] != undefined) {
        if (payload.palco == 1) {
          if (state.cantBoleta.length > 0) {
            store.commit('changeLoader', true);
            return axios.put(`${configApi.apiUrl}palcoevento_status/${state.cantBoleta[payload.id_localidad][0]}`,
              {
                status: 1,
              }
              , { headers: headers })
              .then(response => {
                commit('cantBoleta', {
                  payload: payload,
                  boleta: response.data.data
                });
                store.commit('changeLoader', false);
                //swal("Exito!", "Se reservo la boleta correctamente!", "success");
              })
              .catch(error => {
                store.commit('changeLoader', false);
                swal("Error!", "Error al reservar la boleta!", "error");
                console.error(error);
              });
          }
        } else if (payload.palco != 1) {
          store.commit('changeLoader', true);
          return axios.put(`${configApi.apiUrl}boletaevento_status/${state.cantBoleta[payload.id_localidad][0]}`,
            {
              status: 1,
            }
            , { headers: headers })
            .then(response => {
              commit('cantBoleta', {
                payload: payload,
                boleta: response.data.data
              });
              store.commit('changeLoader', false);
              //swal("Exito!", "Se reservo la boleta correctamente!", "success");
            })
            .catch(error => {
              store.commit('changeLoader', false);
              swal("Error!", "Error al reservar la boleta!", "error");
              console.error(error);
            });
        }
      } else if (payload.operacion == 'reservar') {
        if (payload.palco == 1) {
          store.commit('changeLoader', true);
          return axios.put(`${configApi.apiUrl}palcoevento_status/${payload.id_boletaOpalco}`,
            {
              status: 2,
            }
            , { headers: headers })
            .then(response => {
              if (response.data.message != 'error') {
                commit('cantBoleta', {
                  payload: payload,
                  boleta: response.data.data
                });
              }
              store.commit('changeLoader', false);
              return response.data;
              //swal("Exito!", "Se reservo la boleta correctamente!", "success");
            })
            .catch(error => {
              store.commit('changeLoader', false);
              swal("Error!", "Error al reservar la boleta!", "error");
              console.error(error);
            });
        } else if (payload.palco != 1) {
          store.commit('changeLoader', true);
          return axios.put(`${configApi.apiUrl}boletaevento_status/${payload.id_boletaOpalco}`,
            {
              status: 2,
            }
            , { headers: headers })
            .then(response => {
              if (response.data.message != 'error') {
                commit('cantBoleta', {
                  payload: payload,
                  boleta: response.data.data
                });
              }
              store.commit('changeLoader', false);
              return response.data;
              //swal("Exito!", "Se reservo la boleta correctamente!", "success");
            })
            .catch(error => {
              store.commit('changeLoader', false);
              swal("Error!", "Error al reservar la boleta!", "error");
              console.error(error);
            });
        }
      } else if (payload.operacion == 'sumarIndividual') {
        return axios.post(`${configApi.apiUrl}reservar_puesto_palco_individual`, payload, { headers: headers })
          .then(response => {
            if (response.data.message != 'error') {
              console.log(response.data.data)
              // commit('cantBoleta', {
              //   payload: payload,
              //   boleta: response.data.data
              // });
            }
            store.commit('changeLoader', false);
            return response.data;

            /*swal("Exito!", "Se creo el evento correctamente!", "success");*/
          })
          .catch(error => {
            store.commit('changeLoader', false);
            swal("Error!", "Ya no hay mas boletas disponibles!", "error");
            console.error(error);
          });
      } else if (payload.operacion == 'restarIndividual') {
        return axios.post(`${configApi.apiUrl}reservar_puesto_palco_individual_restar`, payload, { headers: headers })
          .then(response => {
            if (response.data.message != 'error') {
              console.log(response.data.data)
              // commit('cantBoleta', {
              //   payload: payload,
              //   boleta: response.data.data
              // });
            }
            store.commit('changeLoader', false);
            return response.data;

            /*swal("Exito!", "Se creo el evento correctamente!", "success");*/
          })
          .catch(error => {
            store.commit('changeLoader', false);
            swal("Error!", "Ya no hay mas boletas disponibles!", "error");
            console.error(error);
          });
      }
    },
    clearCantBoleta({ state, getters, commit, rootState }) {
      commit('clearCantBoleta');
    },
    clearCantBoletaLocalidad({ state, getters, commit, rootState }, localidad) {
      commit('clearCantBoletaLocalidad', localidad);
    },
    updateEvento({ state, getters, commit, rootState }, payload) {
      store.dispatch('validateToken');
      return axios.put(`${configApi.apiUrl}evento/${payload.id}`, payload,
        { headers: headers })
        .then(response => {
          swal("Exito!", "Se actualizo el evento correctamente!", "success");
          commit('setEvento', response.data.data);
        })
        .catch(error => {
          swal("Error!", "Error al actualizar el evento!", "error");
          console.error(error);
        });
    },
    deleteEventoImagen({ state, getters, commit, rootState }, eventoimagen) {
      store.dispatch('validateToken');
      return axios.delete(`${configApi.apiUrl}deleteimagenevento/${eventoimagen.id_evento}/${eventoimagen.id_imagen}`, null, { headers: headers })
        .then(response => {
          /*swal("Exito!", "Se elimino la imagen correctamente!", "success");*/
        })
        .catch(error => {
          swal("Error!", "Error al eliminar la imagen!", "error");
          //console.log(error);
          return error;
        });
    },
    deleteEventoCondiciones({ state, getters, commit, rootState }, evento) {
      store.dispatch('validateToken');
      return axios.delete(`${configApi.apiUrl}condicionesevento/${evento}`, null, { headers: headers })
        .then(response => {
          /*swal("Exito!", "Se elimino las condiciones correctamente!", "success");*/
        })
        .catch(error => {
          swal("Error!", "Error al eliminar las condiciones!", "error");
          //console.log(error);
          return error;
        });
    },
    deleteEventoArtistas({ state, getters, commit, rootState }, evento) {
      store.dispatch('validateToken');
      return axios.delete(`${configApi.apiUrl}artista_evento/${evento}`, null, { headers: headers })
        .then(response => {
          /*swal("Exito!", "Se elimino los artistas correctamente!", "success");*/
        })
        .catch(error => {
          swal("Error!", "Error al eliminar los artistas!", "error");
          //console.log(error);
          return error;
        });
    },
    deleteEventoPuntoVentas({ state, getters, commit, rootState }, evento) {
      store.dispatch('validateToken');
      return axios.delete(`${configApi.apiUrl}puntoventaevento/${evento}`, null, { headers: headers })
        .then(response => {
          /*swal("Exito!", "Se elimino los puntos de ventas correctamente!", "success");*/
        })
        .catch(error => {
          swal("Error!", "Error al eliminar los puntos de ventas!", "error");
          //console.log(error);
          return error;
        });
    },
    deleteEventoPreventa({ state, getters, commit, rootState }, preventa) {
      store.dispatch('validateToken');
      return axios.delete(`${configApi.apiUrl}preventum/${preventa}`, null, { headers: headers })
        .then(response => {
          /*swal("Exito!", "Se elimino la preventa correctamente!", "success");*/
        })
        .catch(error => {
          swal("Error!", "Error al eliminar la preventa!", "error");
          //console.log(error);
          return error;
        });
    },
    deleteEventoCostos({ state, getters, commit, rootState }, evento) {
      store.dispatch('validateToken');
      return axios.delete(`${configApi.apiUrl}costosevento/${evento}`, null, { headers: headers })
        .then(response => {
          /*swal("Exito!", "Se elimino los costos correctamente!", "success");*/
        })
        .catch(error => {
          swal("Error!", "Error al eliminar los costos!", "error");
          //console.log(error);
          return error;
        });
    },
    deleteEventoBoletasPalcos({ state, getters, commit, rootState }, evento) {
      store.dispatch('validateToken');
      return axios.delete(`${configApi.apiUrl}deleteboletaspalcos/${evento}`, null, { headers: headers })
        .then(response => {
          /*swal("Exito!", "Se elimino las boletas y palcos correctamente!", "success");*/
        })
        .catch(error => {
          swal("Error!", "Error al eliminar las boletas y palcos!", "error");
          //console.log(error);
          return error;
        });
    },
    changeMonedaShopEvento({ state, getters, commit, rootState }, payload) {
      //store.commit('changeLoader', true);
      //Obtener precios del evento en la moneda seleccionada
      //axios.get(`${configApi.apiUrl}precios_evento_moneda/${payload.evento_id}/${payload.codigo_moneda}`,null,{headers: headers})
      //.then(response => {
      commit('changeMonedaShopEvento', payload.codigo_moneda);
      //store.commit('changeLoader', false);
      //console.log(response.data);
      //})
      //.catch(error => {
      //swal("Error!", "Error al obtener precios por la moneda seleccionada!", "error");
      //store.commit('changeLoader', false);
      //});

    },
    eventoStream({ state, getters, commit, rootState }, payload) {
      store.commit('changeLoader', true);
      return axios.get(`${configApi.apiUrl}evento/stream/${payload.reason}/${payload.medium}/${payload.login}`, null, { headers: headers })
        .then(response => {
          store.commit('changeLoader', false);
          return response.data;
        })
        .catch(error => {
          store.commit('changeLoader', false);
          swal("Error!", "Error al obtener el acceso al evento!", "error");
          console.error(error);
        });

    },
    listEventosEstadoBanner({ state, getters, commit, rootState }) {
      axios.get(`${configApi.apiUrl}eventos_estado_banner`, null, { headers: headers })
        .then(response => {
          commit('listEventosEstadoBanner', response.data.eventos);
          state.eventos_destacados = response.data.eventos_destacados
        })
        .catch(error => {
          swal("Error!", "Error al obtener el acceso al evento!", "error");
          console.error(error);
        });
    },
    ventasEventoEmpresario({ state, getters, commit, rootState }, id_evento) {
      store.commit('changeLoader', true);
      axios.get(`${configApi.apiUrl}ventas_evento_empresario/${id_evento}`, null, { headers: headers })
        .then(response => {
          commit('ventasEventoEmpresario', response.data.data);
          store.commit('changeLoader', false);
        })
        .catch(error => {
          swal("Error!", "Error al obtener el informe de ventas!", "error");
          console.error(error);
          commit('ventasEventoEmpresario', []);
          store.commit('changeLoader', false);
        });
    },
    generarCortesia({ state, getters, commit, rootState }, payload) {
      store.commit('changeLoader', true);
      axios.post(`${configApi.apiUrl}generar_cortesia`, { payload }, { headers: headers })
        .then(response => {
          //console.log(response.data);
          store.commit('carrito/setNullPreciosBoletasCarrito');
          store.commit('carrito/dateTimeSaleStart', null);
          store.commit('carrito/dateTimeSaleEnd', null);
          store.dispatch('evento/clearCantBoleta');
          swal("Exito!", "La cortesia se registro satisfactoriamente!", "success");
          store.commit('changeLoader', false);
        })
        .catch(error => {
          store.commit('changeLoader', false);
          if (error.response.status == 402) {
            swal("Error!", error.response.data.message, "error");
            store.dispatch("carrito/dateTimeSaleStart", null);
            store.dispatch("carrito/dateTimeSaleEnd", null);
            store.dispatch("carrito/setNullPreciosBoletasCarrito");
            store.dispatch("evento/clearCantBoleta");
            store.commit('changeLoader', false);
          } else {
            var htmlErrors = ``;
            var obj = error.response.data.errors;
            for (const prop in obj) {
              htmlErrors += `${obj[prop][0]}\n`;
            }

            swal("Error!", htmlErrors, "error");
            //console.log(error.response.data.errors);
          }
        });

    },
    bannersDestacados({ state, getters, commit, rootState }, id_evento) {
      store.commit('changeLoader', true);
      axios.get(`${configApi.apiUrl}home/get_banners_destacados`, null, { headers: headers })
        .then(response => {
          state.dataBannersDestacados = response.data
        })
        .catch(error => {
          swal("Error!", "Error al obtener los banners destacados", "error");
          console.error(error);
          commit('ventasEventoEmpresario', []);
          store.commit('changeLoader', false);
        });
    },
  },
}