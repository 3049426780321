var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[(_vm.eventos.length > 0)?_c('div',[_c('div',{staticClass:"row row-clearfix",attrs:{"id":"listEventos"}},_vm._l((_vm.eventos),function(evento,index){return _c('div',{key:index,staticClass:"col-md-4 col-lg-4 col-sm-6 col-xs-12",staticStyle:{"padding-right":"3px","padding-left":"3px"}},[_c('router-link',{attrs:{"to":{ name: 'evento', params: { evento: evento.slug } }}},[_c('div',{staticClass:"cont-evento"},[_vm._l((evento.imagens),function(imagen,index){return (imagen.nombre.includes('vertical'))?_c('v-img',{key:index,staticClass:"text-right pa-2",attrs:{"src":imagen.url,"lazy-src":"/img/logo-CentralTickets.ca82ecc6.png","height":"100%","witdh":"100%","position":"center center","contain":""}},[_c('div',{staticClass:"cont-fecha"},[_c('span',{class:evento.status === 3
                      ? 'cont-fecha-aplazado'
                      : 'cont-fecha-activo'},[_vm._v("\n                  "+_vm._s(evento.status === 3
                      ? "Aplazado"
                      : _vm.formatFecha(evento.fecha_evento))+"\n                ")])])]):_vm._e()}),(evento.imagens.length <= 0)?_c('v-img',{staticClass:"text-right pa-2",attrs:{"src":"/img/logo-CentralTickets.ca82ecc6.png","lazy-src":"/img/logo-CentralTickets.ca82ecc6.png","height":"100%","witdh":"100%"}},[_c('div',{staticClass:"cont-fecha"},[_c('span',{class:evento.status == 3
                      ? 'cont-fecha-aplazado'
                      : evento.status == 2
                      ? 'cont-fecha-cancelado'
                      : 'cont-fecha-activo'},[_vm._v("\n                  "+_vm._s(evento.status == 3
                      ? "Aplazado"
                      : evento.status == 2
                      ? "Cancelado"
                      : _vm.formatFecha(evento.fecha_evento))+"\n                ")])])]):_vm._e(),_c('div',{staticClass:"cont-fecha"})],2)])],1)}),0),_c('div',{staticClass:"text-xs-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pagesEventos},model:{value:(_vm.paginationEventos.page),callback:function ($$v) {_vm.$set(_vm.paginationEventos, "page", $$v)},expression:"paginationEventos.page"}})],1)]):_c('div',{staticClass:"text-center animated fadeInUp"},[_c('h1',{staticStyle:{"color":"#8c8c8c"}},[_c('i',{staticClass:"material-icons",staticStyle:{"font-size":"100px"}},[_vm._v("hourglass_empty")]),_c('br'),_vm._v("\n      Upss. No se encontraron eventos...\n    ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }